import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';

const ForecastingBackButton = (props) => {
  const { onSaveConfirm, shouldEnableModel } = props;
  const [showModal, setShowModal] = useState(false);

  const handleKeyDownCancel = (e) => {
    if (isEnterButtonPressed(e)) {
      handleCloseModal(e);
    }
  };

  const handleKeyDownSave = (e, shouldSave) => {
    if (isEnterButtonPressed(e)) {
      onSaveButtonClick(e, shouldSave);
    }
  };

  const onSaveButtonClick = (event, shouldSave) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onSaveConfirm(shouldSave);
    setShowModal(false);
  }

  const handleCloseModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setShowModal(false);
  }

  const onBackButtonClick = () => {
    if (!shouldEnableModel) {
      onSaveConfirm(false);
    } else {
      setShowModal(true);
    }
  }

  const renderModel = () => {
    if (!showModal) {
      return;
    }
    return (
      <>
        <ForgeDialog
          aria-labelledby="contained-modal-title-vcenter"
          onDismiss={(event) => handleCloseModal(event)}
          open={true}
        >
          <header className="forge-dialog__header" forge-dialog-move-target="">
            <h2 className='forge-dialog__title'> Do you want to save your changes? </h2>
          </header>
          <footer className="forge-dialog__footer pl-7">
            <ForgeButton style={{ marginRight: '16px' }} type="flat">
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(event) => handleCloseModal(event)}
                onKeyDown={(event) => handleKeyDownCancel(event)}
                id='savechanges_cancel'
              >
                Cancel
              </button>
            </ForgeButton>

            <ForgeButton style={{ marginRight: '16px' }} type="outlined">
              <button
                aria-label="Exit Without Saving"
                tabIndex={0}
                onClick={(event) => onSaveButtonClick(event, false)}
                onKeyDown={(event) => handleKeyDownSave(event, false)}
                id='savechanges_exitwithoutsaving'
              >
                Exit without saving
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                aria-label="Save and Exit"
                tabIndex={0}
                onClick={(event) => onSaveButtonClick(event, true)}
                onKeyDown={(event) => handleKeyDownSave(event, true)}
                id='savechanges_saveandexit'
              >
                Save and exit
              </button>
            </ForgeButton>
          </footer>
        </ForgeDialog>
      </>
    );
  }
  return (
    <>
      <button
        aria-label="Back To Overview"
        className="btn back-arrow-icon back-btn-light"
        onClick={() => onBackButtonClick(true)}>
        <i className="icons-arrow-left2" />
      </button>
      {renderModel()}
    </>
  )
};

ForecastingBackButton.propTypes = {
  onSaveConfirm: PropTypes.func,
  shouldEnableModel: PropTypes.bool
}

export default ForecastingBackButton;