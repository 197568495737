import React, { lazy, Suspense, useState } from 'react'
import PropTypes from 'prop-types'
import { getCardId } from '../collectionHelper';
import CardDrag from '../../CardList/CardDrag';
import { ForgeCard } from '@tylertech/forge-react';
import MetricIcon from './MetricIcon';
import { getUniqueKey } from './metricLibraryHelper';
import { LIBRARY_TYPE } from 'appConstants';
import EmptyBookmarkText from '../../CardList/EmptyBookmarkText';
const MetricResultNoData = lazy(() => import('./MetricResultNoData'));

function MetricLibraryCards({metricLibraryOption, libraryType, metricCardEntries}) {
  const { userCards, bookmarkEntries } = metricLibraryOption;

  const isBookmarkCard = libraryType === LIBRARY_TYPE.MY_SAVED_VIEWS;
  const [selectedCard, setSelectedCard] = useState('');

  const handleMouseDown = (e, uniqueKey) => {
    setSelectedCard(uniqueKey);
  }

  const renderMetricCard = (cardEntry, uniqueKey) => {
    const { templateEntry, viewEntry, bookmarkOptions } = cardEntry;
    const viewName = isBookmarkCard ? _.get(cardEntry, 'name', '') : _.get(viewEntry, 'name', '');
    const templateName = _.get(templateEntry, 'name', '');
    const cardDescription = isBookmarkCard ?
      _.get(bookmarkOptions, 'description', '') : _.get(viewEntry, 'view_description', '');

    const cardId = isBookmarkCard ? _.toString(_.get(cardEntry, 'id', '')) :
      getCardId(templateEntry, viewEntry);
    const isCardExist = _.some(userCards, { card_id: cardId });
    let cardBgClass = selectedCard == uniqueKey ? 'metric-card-active-color' : '';
    cardBgClass = isCardExist ? 'metric-card-color' : cardBgClass;

    const cardProps = {
      userCardEntry:  _.merge(cardEntry, { isBookmarkCard }),
      viewEntry,
      templateEntry,
      isMetricLibrary: true,
      customClass: 'card-drag'
    };

    return (
      <CardDrag {...cardProps} key={`${uniqueKey}-drag`} >
        <div className='cursor-pointer' onMouseDown={(e) => handleMouseDown(e, uniqueKey)}>
          <ForgeCard key={uniqueKey} className={cardBgClass} onClick={() => setSelectedCard(uniqueKey)}>
            <div className="d-flex align-items-center gap-10">
              <MetricIcon templateEntry={templateEntry} viewEntry={viewEntry} isCardExist={isCardExist} />
              <div className='d-flex flex-column gap-5 overflow-hidden'>
                <div className='metric-card-title'>{viewName}</div>
                { !isBookmarkCard && <div className='metric-card-subtitle'>{templateName}</div>}
                <div className='metric-card-description'>{cardDescription}</div>
              </div>
            </div>
          </ForgeCard>
        </div>
      </CardDrag>
    )
  }

  const renderNoDataSearchMetric = () => {
    return (
      <Suspense>
        <MetricResultNoData />
      </Suspense>
    )
  }

  const renderNoDataForBookmarks = () => {
    return (
      <EmptyBookmarkText overrideClass='library-no-data' />
    )
  }

  const renderMetricCards = () => {
    if (isBookmarkCard && _.isEmpty(bookmarkEntries)) {
      return renderNoDataForBookmarks();
    } else if (_.isEmpty(metricCardEntries)) {
      return renderNoDataSearchMetric();
    }

    return _.map(metricCardEntries, (cardEntry, index) => {
      return renderMetricCard(cardEntry, getUniqueKey(cardEntry, index));
    });
  }

  return (
    <div className='metric-card-list mt-2 pb-1'>
      {renderMetricCards()}
    </div>
  )
}

MetricLibraryCards.propTypes = {
  metricLibraryOption: PropTypes.object,
  libraryType: PropTypes.string,
  metricCardEntries: PropTypes.array
}

export default MetricLibraryCards
