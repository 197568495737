import abortableFetch from 'common/abortableFetch';
import { buildQueryString } from 'helpers/HttpHelper';

export const getBookMarks = (options, isSettingPage = false , bookmarkType = '') => {
  const queryParams = {
    isSettingPage: isSettingPage,
    bookmarkType: bookmarkType,
    ...options
  };
  return abortableFetch(
    `/api/bookmarks/domain_wise.json?${buildQueryString(queryParams)}`, options);
};

export const getViewBookMarks = (options) => {
  return abortableFetch("/api/bookmarks/domain_wise_view_bookmarks.json", options);
};

export const getSubScriptionBookMarks = (options) => {
  return abortableFetch("/api/bookmarks/domain_wise_subscription_bookmarks.json", options);
};

export const createBookmark = (bookmarkParams) => {
  const bookmarkOptions =  {
      method: 'POST',
      body: JSON.stringify(bookmarkParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/bookmarks.json"
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const updateBookmark = (bookmarkId,bookmarkParams) => {
  const bookmarkOptions =  {
      method: 'PUT',
      body: JSON.stringify(bookmarkParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/bookmarks/${bookmarkId}.json`
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const deleteManagedBookmark = (bookmarkId) => {
  const apiUrl = `/api/bookmarks/manage_bookmark_delete.json?id=${bookmarkId}`
  return abortableFetch(apiUrl);
};

export const deleteBookmark = (bookmarkId) => {
  const bookmarkOptions =  {
      method: 'DELETE',
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = `/api/bookmarks/${bookmarkId}.json`
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const acceptBookmark = (bookmarkId) => {
  const bookmarkOptions =  {
      method: 'POST',
      headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = `/api/bookmarks/${bookmarkId}/accept_shared_bookmark.json`
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const acceptAllBookmark = () => {
  const bookmarkOptions =  {
      method: 'POST',
      headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = "/api/bookmarks/accept_all_shared_bookmark"
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const bulkUpdateBookmarks = (bookmarks) => {
  const params = {
    method: 'POST',
    body: JSON.stringify({bookmarks}),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/bookmarks/bulk_update_bookmark.json';
  return abortableFetch(apiUrl, params);
}

export const updateBookmarkOpenedAt = (bookmarkId) => {
  const params =  {
    method: 'POST',
    body: JSON.stringify({bookmark_id: bookmarkId}),
    headers:{ 'content-type': 'application/json'}
  };
  const apiUrl = `/api/bookmarks/update_bookmark_opened_at.json`;
  return abortableFetch(apiUrl, params);
}

export const updateBookmarksOrder = (bookmarks) => {
  const params =  {
    method: 'POST',
    body: JSON.stringify({bookmarks}),
    headers:{ 'content-type': 'application/json'}
  };
  const apiUrl = `/api/bookmarks/update_sort_order.json`;
  return abortableFetch(apiUrl, params);
}

export const getBookmark = (bookmarkId, queryParams) => {
  const apiUrl = `/api/bookmarks/${bookmarkId}.json`;

  return abortableFetch(`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getEmbedBookmark = (bookmarkId) => {
  return abortableFetch(`/api/bookmarks/${bookmarkId}/show_embed_bookmark.json`);
};


export const createEmbedBookmark = (bookmarkParams) => {
  const bookmarkOptions =  {
      method: 'POST',
      body: JSON.stringify(bookmarkParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/bookmarks/create_embed_bookmark.json"
  return abortableFetch(apiUrl, bookmarkOptions);
};

export const getBookmarkWatchUsers = (queryParams) => {
  const apiUrl = `/api/bookmarks/get_watch_users.json`;
  return abortableFetch(`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const getLinkBookmarkCollections = (queryParams) => {
  const apiUrl = `/api/bookmarks/link_bookmark_collections.json`;
  return abortableFetch(`${apiUrl}?${buildQueryString(queryParams)}`);
}