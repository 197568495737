import React from 'react';
import PropTypes from 'prop-types';
import { ForgeSelect, ForgeOption, ForgeIcon } from '@tylertech/forge-react';

const ForgeDropdowns = (props) => {

  const {
    isMultiple,
    label,
    hasHelperText,
    helperText,
    hasLeadingIcon,
    leadingIconName,
    isDisabled,
    isRequired,
    options,
    handleOnSelect,
    placeholderLabel,
    value,
    showInvalid,
    wrapOptionText,
    hasAdditionalOption,
    additionalOption,
    className
  } = props;

  const handleSelect = (option) => {
    const selectedValue = _.get(option, 'detail');
    const selectedOption = _.find(options, { value: selectedValue}) || selectedValue;
    handleOnSelect(selectedOption)
  }

  const renderForgeOptions = (option) => {
    const value = _.get(option, 'value', option);
    const name = _.get(option, 'name', option);
    return (
      <ForgeOption key={name} className="drop-down-options" value={value}>{name}</ForgeOption>
    )
  }

  return (
      <ForgeSelect
        label={label}
        multiple={isMultiple}
        className={className}
        density="default"
        floatLabelType="always"
        disabled={isDisabled}
        onChange={handleSelect}
        invalid={showInvalid}
        required={isRequired}
        placeholder={placeholderLabel}
        value={value}
        wrapOptionText={wrapOptionText}>
        {hasLeadingIcon && <ForgeIcon slot="leading" name={leadingIconName} />}
        {hasAdditionalOption && additionalOption}
        {_.map(options, renderForgeOptions)}
        {hasHelperText && <span slot="helper-text">{helperText}</span>}
      </ForgeSelect>

  );
}

ForgeDropdowns.propTypes = {
  isMultiple: PropTypes.bool,
  hasLeadingIcon: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  leadingIconName: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  handleOnSelect: PropTypes.func,
  placeholderLabel: PropTypes.string,
  hasHelperText: PropTypes.bool,
  value: PropTypes.any,
  showInvalid: PropTypes.bool,
  wrapOptionText: PropTypes.bool,
  hasAdditionalOption: PropTypes.bool,
  additionalOption: PropTypes.any,
  className: PropTypes.string
}

ForgeDropdowns.defaultProps = {
  isDisabled: false,
  isMultiple: false,
  isRequired: false,
  placeholderLabel: '',
  hasHelperText: true,
  options: [],
  handleOnSelect: _.noop,
  wrapOptionText: true,
  hasAdditionalOption: false,
  className: ''
}

export default (ForgeDropdowns);
