import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ForgeIcon, ForgeIconButton, ForgeMenu, ForgeBadge } from '@tylertech/forge-react';

import TemplateCardVisualization from './TemplateCardVisualization';
import TargetValueSelector from 'common/components/TargetValueSelector';
import ComparisonSelector from 'pages/ComparisonSelector';
import { getCardId, getMetricUrl, getLink } from 'pages/embed/helper';
import FilterIcon from './FilterIcon';
import DeleteConfirmModal from '../DeleteConfirmModal';
import {
  getCurrentTargetEntry,
} from 'helpers/templateHelper';
import {
  enableComparisonMode,
  enableCollectionLevelDateFilters,
  shouldShowEmbedLinkInKabob
} from 'common/config/customerConfiguration';
import { getNonGeoLocationColumn } from 'common/config/templateConfiguration';
import {
  disableMetricTotal,
} from 'common/config/viewConfiguration';
import {
  getDefaultVisualizationChartView,
  getAvailableVisualizationType,
  getDefaultDimensionField
} from 'helpers/visualizationHelper';

import {
  getDefaultVisualizationView,
  showAnnotationValueInBar
} from 'common/config/visualizationConfiguration';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  VISUALIZATION_TYPES,
  COLLECTION_CARD_DELETE_MESSAGE
} from 'appConstants';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import {
  getQuickFilterEntriesFromTemplate,
  getViewEntryQuickFilters
} from 'pages/drilldown/components/QuickFilterBar/helper';
import { isEnterButtonPressed, isEllipsisActive } from 'helpers/mouseEventsHelper';
import { getDateFilterParams } from 'helpers/dateHelper';
import { getTargetEntry } from 'common/api/targetEntryApi';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { embedCopyMessage, embedCopyErrorMessage } from 'helpers/toastMessages';

import AttributionDescription from 'common/components/AttributionDescription';
import EmbedTabs from 'pages/embed/EmbedTabs';
import { isClickInfoSection } from 'helpers/DomPageHelper';
import { isNewMetricLabelCard } from 'helpers/metricNewLabelHelper';
import { isCollectionViewAccess, isRestrictedCollection } from '../ManageCollection/collaboratorHelper';
import { removeCardFromCollection } from 'actions/userCollectionsActions';

class TemplateCard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      currentTargetEntry: null,
      userTargetEntry: {},
      chartTotalData: null,
      secondaryTotal: null,
      isLoading: true,
      isTotalLoading: true,
      isChartDataLoading: false,
      isEmptyData: false,
      isEllipsisActive: false,
      isDeleteClicked: false,
    };
    this.abortFetchController = new AbortController();
    this._cardBodyRef = React.createRef();
  }

  updateCurrentDimensionTotal = (
    currentDimensionTotal,
    isTotalLoading,
    secondaryTotal,
    chartTotalData
  ) => {
    const { viewEntry, cardEntry } = this.props;
    const defaultView = getDefaultVisualizationView(viewEntry);
    const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);
    const { currentDrilldownViewEntry } = drilldownEntry;
    const availableVizType = getAvailableVisualizationType(
      _.get(cardEntry, 'template_id'),
      defaultView,
      currentDrilldownViewEntry
    );
    const targetEntryOptions = {
      visualizationType: availableVizType,
      chartTotalData: _.get(chartTotalData, 'currentPeriodMetricTotals', {}),
      quickFilters: getViewEntryQuickFilters(cardEntry.name, viewEntry),
      isSmallView: true,
      userTargetEntry: this.state.userTargetEntry
    };
    const currentTargetEntry = getCurrentTargetEntry(
      currentDimensionTotal,
      viewEntry,
      targetEntryOptions
    );
    this.setState({
      isTotalLoading,
      currentTargetEntry,
      secondaryTotal,
      currentDimensionTotal
    });
  };

  componentDidMount() {
    this.fetchTargetEntry();

    if (_.isEmpty(window.allCardsLoadingStatus)) {
      window.allCardsLoadingStatus = {};
    }
    const cardImageId = this.getCardImageId();
    window.allCardsLoadingStatus[cardImageId] = true;
    this.setState({ isEllipsisActive: isEllipsisActive(this.metricTitleDiv) });
  }

  fetchTargetEntry = () => {
    const { cardEntry, viewEntry, currentUser } = this.props;
    if (_.isEmpty(currentUser)) {
      return;
    }
    const {
      currentDrilldownTemplateId, currentDrilldownViewEntry
    } = getDrilldownEntryParams(cardEntry, viewEntry);
    const params = {
      template_id: currentDrilldownTemplateId,
      view_id: _.get(currentDrilldownViewEntry, 'view_id')
    };

    this.setState({ isLoading: true });
    this.abortFetchController.abort();

    fetchApiData(getTargetEntry(params)).
      then((response) => {
        this.setState({ isLoading: false, userTargetEntry: _.get(response, '0', {}) });
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);   // eslint-disable-line no-console
      });
  }

  handleKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleCardClick(e);
    }
  };

  handleCardClick = (e) => {
    if (isClickInfoSection(e)) {
      return;
    }
    const { cardEntry, viewEntry, onCardClick, commonFilters } = this.props;
    const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);

    onCardClick(cardEntry, viewEntry, drilldownEntry, commonFilters, e, this.isNewCard());
  }

  getCardImageId = () => {
    const { cardEntry, index } = this.props;
    return `${cardEntry['name']}-${index}`;
  }

  onDataLoading = (isLoading, isEmptyData = false) => {
    const comparisonModeEnabled =
      enableComparisonMode() && this.props.comparisonModeOn;
    if (comparisonModeEnabled && !_.isNil(isLoading)) {
      this.setState({ isChartDataLoading: isLoading });
    }
    this.setState({ isEmptyData, isChartDataLoading: false });
  };

  handleCardDelete = () => {
    const { userCardEntry, onCardDelete, dispatchRemoveCardFromCollection } = this.props;
    onCardDelete(userCardEntry);
    const cardId = _.get(userCardEntry, 'card_id', null);
    const collectionId = _.get(userCardEntry, 'collection_id', null)
    dispatchRemoveCardFromCollection(collectionId, cardId);
  };

  handleClickMenuItems = () => {
    document.body.click();
  }

  getVisualizationType = () => {
    const { cardEntry, viewEntry } = this.props;
    const defaultView = getDefaultVisualizationView(viewEntry);
    const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);
    const { currentDrilldownViewEntry } = drilldownEntry;
    const templateId = _.get(cardEntry, 'template_id');
    if (defaultView == 'none') {
      return defaultView;
    }
    return getAvailableVisualizationType(templateId, defaultView, currentDrilldownViewEntry);
  }

  isNewCard = () => {
    const { cardEntry, viewEntry, newLabelCards } = this.props;
    const cardId = getCardId(cardEntry, viewEntry);
    const matchedCard = _.find(newLabelCards, { template_card_id: cardId });

    return isNewMetricLabelCard(viewEntry) && !_.isEmpty(matchedCard);
  }

  handleEmbedClick = () => {
    const { cardEntry, viewEntry } = this.props;
    const cardId = getCardId(cardEntry, viewEntry);
    const metricUrl = getMetricUrl(cardId);
    const link = getLink(cardId, metricUrl);

    // https://stackoverflow.com/questions/22581345/click-button-copy-to-clipboard-using-jquery
    try {
      const $inputElement = $("<input>");
      $("body").append($inputElement);
      $inputElement.val(link).select();
      document.execCommand('copy');
      $inputElement.remove();
      toast.success(embedCopyMessage);
    } catch (err) {
      toast.error(embedCopyErrorMessage);
    }
  }

  getFilterIconAttributes = () => {
    const {
      cardEntry, globalFilters, viewEntry, commonFilters, isUserCard, collectionDateFilters, isEmbed
    } = this.props;
    const {
      quickFilters,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    } = getDrilldownEntryParams(cardEntry, viewEntry);
    const quickFilterEntries = getQuickFilterEntriesFromTemplate(
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    );
    const dateFilters = isUserCard ? collectionDateFilters : getDateFilterParams(commonFilters);
    const cardEntries = [{ templateEntry: cardEntry, viewEntry: currentDrilldownViewEntry }];
    return {
      currentDrilldownViewEntry,
      cardEntries,
      dateFilters,
      isEmbed,
      templateEntry: cardEntry,
      hideCommonFilters: !enableCollectionLevelDateFilters(),
      quickFilters,
      quickFilterEntries,
      globalFilters,
    };
  }

  renderMetricDescription(availableVizType) {
    const { viewEntry } = this.props;
    const { templateTotal } = this.state;
    const description = _.get(viewEntry, 'view_description', '');
    if (
      _.isEmpty(description) ||
      (_.isEqual(templateTotal, '') &&
        !_.isEqual(availableVizType, VISUALIZATION_TYPES.MAP.type))
    ) {
      return null;
    }

    return (
      <div className="metric-footer forge-typography--caption">
        <div className="text-truncate line-clamp">{description}</div>
      </div>
    );
  }

  handleMenuSelect = ({ detail }) => {
    const { value } = detail;

    if (_.isEqual(value, 'delete')) {
      this.setState({ isDeleteClicked: true });
    } else {
      this.handleEmbedClick();
    }
  }

  renderMenus() {
    const { index, isUserCard, isEmbed, collectionEntry } = this.props;
    const showEmbedLinkInKabob = shouldShowEmbedLinkInKabob();
    if (isEmbed) {
      return null;
    }

    let options = [];
    if (isUserCard && !isCollectionViewAccess(collectionEntry) &&
        !isRestrictedCollection(collectionEntry)) {
      options.push({ value: 'delete', label: 'Remove tile' });
    }

    if (showEmbedLinkInKabob) {
      options.push({ value: 'embed', label: 'Embed' });
    }

    return ((isUserCard || showEmbedLinkInKabob) && !_.isEmpty(options)) && (
      <ForgeMenu
        placement='bottom-start'
        key={'collection-card-custom' + index}
        options={options}
        on-forge-menu-select={this.handleMenuSelect}>
        <ForgeIconButton type="button">
          <button type="button" aria-label="Card menu">
            <ForgeIcon name='more_vert' className="more-icon" /></button>
        </ForgeIconButton>
      </ForgeMenu>
    );
  }


  renderTemplateCardVisualization() {
    const { cardEntry, viewEntry, isEmbed, commonFilters,
      showLegendTotalLine } = this.props;
    const { currentDimensionTotal, isChartContentLoading, isTotalLoading, isDeleteClicked } = this.state;

    const defaultView = getDefaultVisualizationView(viewEntry);
    if (defaultView == 'none') {
      return;
    }
    const defaultChartView = getDefaultVisualizationChartView(
      viewEntry,
      defaultView
    );
    const isPieChartView = (_.isEqual(defaultChartView, SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type));
    const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);
    const defaultMapView = getDefaultVisualizationChartView(
      viewEntry,
      VISUALIZATION_TYPES.MAP.type
    );
    const mapOptions = {
      currentMapView: _.get(getMapViews(), defaultMapView),
    };
    const showChartValues = showAnnotationValueInBar(viewEntry);
    const isPieChartTotalLoading = (isTotalLoading && isPieChartView);
    const cardId = `chart-${getCardId(cardEntry, viewEntry)}`;

    return (
      <div
        id={cardId}
        className="metric-card-visualization js-plotly-plot mt-2">
        <TemplateCardVisualization
          isChartAndTotalLoading={isChartContentLoading || isPieChartTotalLoading}
          onDataLoading={this.onDataLoading}
          commonFilters={commonFilters}
          cardEntry={cardEntry}
          mapOptions={mapOptions}
          visualizationType={this.getVisualizationType()}
          drilldownEntry={drilldownEntry}
          currentDimensionTotal={currentDimensionTotal}
          isEmbed={isEmbed}
          viewEntry={viewEntry}
          showChartValues={showChartValues}
          cardImageId={this.getCardImageId()}
          showLegendTotalLine={showLegendTotalLine}
        />
        {isDeleteClicked &&
          <DeleteConfirmModal
            name='Remove'
            title="Remove tile from collection?"
            showNormalButton={true}
            showButtons={false}
            confirmButtonName="Remove"
            onCancelConfirm={() => this.setState({ isDeleteClicked: false })}
            message={COLLECTION_CARD_DELETE_MESSAGE}
            showMenuItems={this.handleClickMenuItems}
            onDeleteConfirm={this.handleCardDelete}
          />
        }
      </div>
    );
  }

  renderCardContent() {
    const { cardEntry, viewEntry, commonFilters, currentUser } = this.props;
    const { currentTargetEntry, secondaryTotal, isEmptyData } = this.state;

    const defaultView = getDefaultVisualizationView(viewEntry);
    const hideVizChart = (defaultView == 'none');
    const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);
    const { currentDrilldownViewEntry } = drilldownEntry;
    const templateId = _.get(cardEntry, 'template_id');
    const availableVizType = getAvailableVisualizationType(
      templateId,
      defaultView,
      currentDrilldownViewEntry
    );
    const disabledTotal = disableMetricTotal(viewEntry);
    const renderComparisonSelector = () => {
      return (
        <ComparisonSelector
          cardEntry={cardEntry}
          viewEntry={viewEntry}
          commonFilters={commonFilters}
          updateCurrentDimensionTotal={this.updateCurrentDimensionTotal}
          visualizationType={availableVizType}
          isEmptyData={isEmptyData}
        />
      )
    }

    return (
      <>
        <div className="mb-auto">
          {!disabledTotal ?
            (
              <div className="status d-flex flex-wrap">
                <TargetValueSelector
                  templateId={templateId}
                  currentUser={currentUser}
                  viewEntry={currentDrilldownViewEntry}
                  currentTargetEntry={currentTargetEntry}
                  secondaryTotal={secondaryTotal}
                  isSmallView
                />
                {hideVizChart ? null : renderComparisonSelector()}
              </div>
            ) : hideVizChart ? null : renderComparisonSelector()}
          {hideVizChart ?
            <div
              className="total_viz metric-card-visualization js-plotly-plot mt-2">
              {renderComparisonSelector()}
            </div> : null}
          {this.renderTemplateCardVisualization()}
        </div>
        {this.renderMetricDescription(availableVizType)}
        <AttributionDescription templateId={_.get(cardEntry, 'template_id')} />
      </>
    );
  }

  renderNewLabel = () => {
    return this.isNewCard() && (
      <ForgeBadge
        className="new-label-chip"
        strong="false">
        New
      </ForgeBadge>
    );
  }

  renderBodyContent() {
    const { cardEntry, viewEntry } = this.props;
    const { isEllipsisActive } = this.state;
    const viewName = _.get(viewEntry, 'name', '');
    const cardDescription = _.get(cardEntry, 'description', '');

    return (
      <>
        <div className="metric-head-wrapper">
          <div className="metric-head">
            <div className="d-inline-flex gap-5">
              <div
                className="metric-title forge-typography--subtitle1-secondary text-truncate line-clamp"
                ref={ref => (this.metricTitleDiv = ref)}>
                {viewName}
              </div>
              {isEllipsisActive && <forge-tooltip
                position="top"
                target=".metric-title"
                text={viewName}
                delay={500} />}
              {this.renderNewLabel()}
            </div>
            <div className="metric-category forge-typography--caption text-truncate">
              {cardDescription}
            </div>
            {<FilterIcon {...this.getFilterIconAttributes()} />}
          </div>
          <div className="info-sections align-self-start ml-1">
            {this.renderMenus()}
          </div>
        </div>
        {this.renderCardContent()}
      </>
    );
  }

  renderCardBody() {
    const { isEmbed, viewMetricLink } = this.props;

    return (
      <div className="card-body d-flex flex-column h-100 forge-popup-host" ref={this._cardBodyRef}>
        <EmbedTabs
          isEmbed={isEmbed} vizType={this.getVisualizationType()}
          element={this._cardBodyRef} viewMetricLink={viewMetricLink}>
          {this.renderBodyContent()}
        </EmbedTabs>
      </div>
    );
  }

  renderEmbedCard() {
    return (
      <div className="embed-card">
        {this.renderCardBody()}
      </div>
    );
  }

  renderCard() {
    const { viewEntry } = this.props;

    return (
      <div
        role="listitem"
        className="card metric-card"
        tabIndex={0}
        aria-label={viewEntry['name']}
        onClick={(e) => this.handleCardClick(e)}
        onKeyDown={(e) => this.handleKeyDown(e)}>
        <div className="dragging-card-shadow-overlay"></div>
        {this.renderCardBody()}
      </div>
    );
  }

  render() {
    const { isEmbed } = this.props;

    if (isEmbed) {
      return this.renderEmbedCard();
    } else {
      return this.renderCard();
    }
  }
}

export const getDrilldownEntryParams = (cardEntry, viewEntry) => {
  const dimensionEntries = getNonGeoLocationColumn(
    _.get(cardEntry, 'dimension_entries', [])
  );


  const drilldownDimensionField = getDefaultDimensionField(
    dimensionEntries,
    viewEntry
  );

  return {
    currentDrilldownTemplateId: cardEntry.template_id,
    currentDrilldownViewEntry: viewEntry,
    currentDrilldownDimensionField: drilldownDimensionField,
    currentDrilldownGroupByEntry: _.first(
      _.get(cardEntry, 'group_by_entries', [])
    ),
    currentViewEntryField: _.get(viewEntry, 'field'),
    quickFilters: getViewEntryQuickFilters(cardEntry.name, viewEntry),
  };
};

export const getContainerClassNames = ({ cardEntry, viewEntry }) => {
  const defaultView = getDefaultVisualizationView(viewEntry);
  const drilldownEntry = getDrilldownEntryParams(cardEntry, viewEntry);
  const { currentDrilldownViewEntry } = drilldownEntry;
  const availableVizType = getAvailableVisualizationType(
    _.get(cardEntry, 'template_id'),
    defaultView,
    currentDrilldownViewEntry
  );

  return classNames({
    'metric-tile':
      availableVizType !== VISUALIZATION_TYPES.TABLE.type,
    'metric-data-table':
      availableVizType === VISUALIZATION_TYPES.TABLE.type,
  });
};

TemplateCard.propTypes = {
  cardEntry: PropTypes.object,
  userCardEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  onCardClick: PropTypes.func,
  onCardDelete: PropTypes.func,
  isEmbed: PropTypes.bool,
  currentUser: PropTypes.object,
  isUserCard: PropTypes.bool,
  commonFilters: PropTypes.object,
  comparisonModeOn: PropTypes.bool,
  viewMetricLink: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  globalFilters: PropTypes.array,
  collectionDateFilters: PropTypes.object,
  newLabelCards: PropTypes.array,
  collectionEntry: PropTypes.object,
  dispatchRemoveCardFromCollection: PropTypes.func,
  showLegendTotalLine: PropTypes.bool
};

TemplateCard.defaultProps = {
  isEmbed: false,
  globalFilters: [],
  collectionDateFilters: {},
  collectionEntry: {}
};

const mapDispatchToProps = {
  dispatchRemoveCardFromCollection: removeCardFromCollection
};

function mapStateToProps(state) {
  return {
    showLegendTotalLine: _.get(state, 'visualization.overtime.showLegendTotalLine', true)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCard);
