import abortableFetch from 'common/abortableFetch';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { buildQueryString, decryptData } from 'helpers/HttpHelper';

export const updateConfiguration = (configParams) => {
  const configOptions =  {
      method: 'POST',
      body: JSON.stringify(configParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/config_update.json"
  return abortableFetch(apiUrl, configOptions);
};

export const bulkUpdateConfigurations = (configParams) => {
  const configOptions =  {
      method: 'POST',
      body: JSON.stringify(configParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/config_bulk_update.json"
  return abortableFetch(apiUrl, configOptions);
};

export const updateUserConfigurations = (configParams) => {
  const configOptions =  {
      method: 'POST',
      body: JSON.stringify(configParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/user_config_update.json"
  return abortableFetch(apiUrl, configOptions);
};

export const getAppConfigurations  = (queryParams) => {
  const apiUrl = "/api/app_config.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getPermissionBasedUserList  = (queryParams) => {
  const apiUrl = "/api/get_users_with_current_user_permissions.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getPermissionDatasetData = (only_current_user) => {
  const url = `/api/permission_data.json?only_current_user=${only_current_user}`
  return abortableFetch(url).then((res) => {
    return res.text();
  }).then((res) => {
    return decryptData(res);
  });
}

export const getPermissionFieldValues = (params, abortFetch) => {
  const url = `/api/permission_column_values.json?${buildQueryString(params)}`
  return fetchApiData(url, abortFetch);
}

export const getPermissionSearchValues = (params, abortFetch) => {
  const url = `/api/permission_column_search_values.json?${buildQueryString(params)}`
  return fetchApiData(url, abortFetch);
}

export const importUsers = (formData)=> {
  const configOptions =  {
    method: 'POST',
    body: formData
  };
  const apiUrl = "/api/import_users_data.json"
  return abortableFetch(apiUrl, configOptions);
}