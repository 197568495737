export const UPDATE_CURRENT_DATA_RANGE_OPTIONS = 'UPDATE_CURRENT_DATA_RANGE_OPTIONS';
export const UPDATE_PREVIOUS_DATA_RANGE_OPTIONS = 'UPDATE_PREVIOUS_DATA_RANGE_OPTIONS';
export const UPDATE_SELECTED_SHAPE_DATASET_ENTRY = 'UPDATE_SELECTED_SHAPE_DATASET_ENTRY';
export const UPDATE_SELECTED_SHAPE_IDS = 'UPDATE_SELECTED_SHAPE_IDS';
export const UPDATE_SELECTED_TEMPLATE_IDS = 'UPDATE_SELECTED_TEMPLATE_IDS';
export const UPDATE_DEFAULT_MAP_CANVAS_OPTIONS = 'UPDATE_DEFAULT_MAP_CANVAS_OPTIONS';

export const updateCurrentDateRangeOptions = (dateRangeOptions) => ({
  type: UPDATE_CURRENT_DATA_RANGE_OPTIONS,
  dateRangeOptions
});

export const updateDefaultMapCanvasOptions = (dateRangeOptions) => ({
  type: UPDATE_DEFAULT_MAP_CANVAS_OPTIONS,
  dateRangeOptions
});

export const updatePreviousDateRangeOptions = (dateRangeOptions) => ({
  type: UPDATE_PREVIOUS_DATA_RANGE_OPTIONS,
  dateRangeOptions
});

export const updateSelectedShapeDatasetEntry = (selectedShapeEntry) => ({
  type: UPDATE_SELECTED_SHAPE_DATASET_ENTRY,
  selectedShapeEntry
});

export const updateSelectedShapeIds = (shapeIds) => ({
  type: UPDATE_SELECTED_SHAPE_IDS,
  shapeIds
});

export const updateSelectedTemplateIds = (templateIds) => ({
  type: UPDATE_SELECTED_TEMPLATE_IDS,
  templateIds
});
