import _ from 'lodash';
import { getDistributionBucketEntry } from 'common/config/visualizationConfiguration';
import { getCurrentTemplateEntry } from 'common/config/templateConfiguration';
import {
  isAbsoluteTargetType,
  getTargetMetricEntry,
  getTargetBenchMarkEntry } from 'common/config/viewConfiguration';
import {
  getAboveOrBelowEntry,
  getTargetEntryField,
  getBenchmarkFilter
} from 'helpers/visualizationHelper';
import { getDistributionBenchmarkEntries, isDistributionType } from 'helpers/distributionHelper';
import { VISUALIZATION_TYPES, COLLECTION_TYPES } from 'appConstants';
import { getExplorationCardEntries  } from 'common/config/customerConfiguration';
import { dateRangeExpireCheck } from 'helpers/dateHelper';

export const getDimensionEntry = (templateId, dimensionField) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const dimensionsEntries = _.get(templateEntry,'dimension_entries', []);
  return (_.find(dimensionsEntries, { 'field': dimensionField}) || {});
}

export const shouldTargetApplyAlways = (viewEntry) => {
  return (_.get(viewEntry, 'always_apply_target', 'false') === 'true');
}

export const getCurrentTargetEntry = (total, viewEntry, targetEntryOptions) => {
  const { visualizationType, chartTotalData, isSmallView, userTargetEntry } = targetEntryOptions;
  if (_.isNil(total)) {
    return {};
  }

  if (isAbsoluteTargetType(viewEntry)) {
    return getAbsoluteTargetEntry(total, viewEntry, userTargetEntry);
  } else {
    const secondaryTotal = _.get(chartTotalData, 'secondaryTotal');

    if (isDistributionType(visualizationType)) {
      return isSmallView ? {} : getDistributionTargetEntry(viewEntry, targetEntryOptions);
    } else {
      const benchmarkValue = getBenchmarkValue(viewEntry, chartTotalData, visualizationType);
      if(_.isEmpty(getTargetMetricEntry(viewEntry))) {
        return {};
      }
      const { targetEntryField, isPrimaryMetric } = getTargetEntryField(viewEntry);
      const targetEntryValue = isPrimaryMetric ?
        total :
        _.get(chartTotalData, `secondary_${targetEntryField}`, secondaryTotal);
      return getAboveOrBelowEntry(viewEntry, Number(targetEntryValue), benchmarkValue);
    }
  }
}

const getDistributionTargetEntry = (viewEntry, targetEntryOptions) => {
  const { chartTotalData, quickFilters, benchMarkNames, isDiscrete, isCumulative } = targetEntryOptions;
  const targetBenchMarkEnry = getTargetBenchMarkEntry(viewEntry);
  const benchmarkName = _.get(targetBenchMarkEnry, 'name');
  const bucketAggregateType = _.get(targetBenchMarkEnry, 'bucket_aggregate_type');
  const isDiscreteAggregateType = _.includes(['discrete_percentage', 'discrete_value'], bucketAggregateType);
  const isCumulativeAggregateType = _.includes(
    ['cumulative_percentage', 'cumulative_value'], bucketAggregateType);

  if (!_.includes(benchMarkNames, benchmarkName) ||
    isDiscreteAggregateType && !isDiscrete ||
    isCumulativeAggregateType && !isCumulative
  ) {
    return {};
  }
  const bucketValue = getDistributionBucketValue(viewEntry, targetBenchMarkEnry, chartTotalData);
  const matchedFilter = getBenchmarkFilter(targetBenchMarkEnry, quickFilters);
  const filterColumn = _.get(targetBenchMarkEnry, 'filter_column');
  const filterValue = _.get(targetBenchMarkEnry, 'filter_value');
  const onlyForMetricDefaults = JSON.parse(_.get(targetBenchMarkEnry, 'only_for_metric_defaults', 'false'));
  const benchmarkHideCondition = filterColumn && filterValue ?
    _.isEmpty(matchedFilter) :
    onlyForMetricDefaults && !_.isEmpty(quickFilters);

  if (_.isEmpty(targetBenchMarkEnry) || benchmarkHideCondition) {
    return {};
  }

  const benchmarkValue = getBenchmarkValue(viewEntry, chartTotalData);
  return getAboveOrBelowEntry(viewEntry, Number(bucketValue), benchmarkValue);
}

const getBenchmarkValue = (viewEntry, chartTotalData, visualizationType) => {
  const { column, source, value } = getTargetBenchMarkEntry(viewEntry);

  if (source === "column") {
    let matchedBenchmarkField;
    if(visualizationType === VISUALIZATION_TYPES.DISTRIBUTION.type) {
      const distributionBenchmarkEntries = getDistributionBenchmarkEntries(viewEntry);
      matchedBenchmarkField = _.chain(distributionBenchmarkEntries).find({ column }).get('field').value();
    } else {
      matchedBenchmarkField = 'target_benchmark_entry';
    }

    return _.get(chartTotalData, matchedBenchmarkField);
  } else {
    return value;
  }
}

export const getAllMetricEntries = (templateEntries, searchByMetricName = '', metricLogs = []) => {
  if(_.isEmpty(templateEntries)) {
    return;
  }

  return _.chain(templateEntries).
    map((templateEntry) => {
      const viewAllEntries = _.get(templateEntry, 'view_entries', []);
      const viewEntries = _.isEmpty(searchByMetricName) ? viewAllEntries :
        getMetricByNameFilter(viewAllEntries, searchByMetricName);

      return _.map(viewEntries, (viewEntry) => {
        const openedAt = _.find(metricLogs, (metricLog) =>
          String(metricLog.view_id) === String(viewEntry.view_id) &&
          String(metricLog.template_id) === String(templateEntry.template_id)
        )?.opened_at || '';

        return {
          templateEntry,
          viewEntry: {
            ...viewEntry,
            opened_at: openedAt,
          }
        };
      });
    }).
    flatten().value();
}

export const getBookmarkMetricEntries = (bookmarkEntries, searchByMetricName = '') => {
  if(_.isEmpty(bookmarkEntries)) {
    return;
  }

  return _.isEmpty(searchByMetricName) ? bookmarkEntries :
    getMetricByNameFilter(bookmarkEntries, searchByMetricName);
}

const getMetricByNameFilter = (entries, searchByMetricName) => {
  return _.filter(entries, (entry) => {
    const name = _.toLower(entry.name);
    const search = _.toLower(searchByMetricName);
    return name.indexOf(search) > -1;
  })
}

export const getCustomTagMetricEntries = (templateEntries, metricTagId) => {
  if(_.isEmpty(templateEntries)) {
    return;
  }

  let customTags =  _.chain(templateEntries).map( (templateEntry) => {
    const sortOrderMaxNumber = 999999;
    const viewEntries = _.get(templateEntry, 'view_entries', []);
    const currentTagViewEntries =  _.filter(viewEntries, function(viewEntry){
      const tags = _.map(viewEntry.tags, 'tag_id');
      return _.includes(tags, metricTagId);
    });
    return _.map(currentTagViewEntries, (viewEntry) => {
      let sort_order = _.chain(viewEntry.tags)
                       .find((tag) => {return tag['tag_id'] == metricTagId})
                       .get('default_sort_order', sortOrderMaxNumber)
                       .value();
      sort_order = Number(sort_order) <= 0  ?  sortOrderMaxNumber : Number(sort_order)
      return { templateEntry, viewEntry, sort_order }
    });
  }).
  flatten().value();

  const explorationCardEntries = getExplorationCardEntries();
  _.each(explorationCardEntries, (entry, index) => {
    if(entry[metricTagId]){
      const cardId = `${index}${entry['exploration_id']}`;
      const sort_order = entry[`default_sort_order_${metricTagId}`]
      const cardEntry = {
        ...entry,
        sort_order: Number(sort_order),
        userCardEntry: {...entry, isExplorationCard: true, card_id: cardId},
        isExplorationCard: true,
        explorationCardEntry: entry,
        hideDelete: false
      }
      customTags.push(cardEntry);
    }
  });

  return _.orderBy(customTags, ['sort_order'], ['asc']);
}

export const getTemplateIdsForTheCollection = (templateEntries, currentCollection, cardEntries = []) => {
  let customTags = cardEntries
  if (currentCollection['type'] != COLLECTION_TYPES.USER) {
    const metricTagId = _.get(currentCollection, 'bellerophon_tag_id', currentCollection['tag_id']);
    customTags = getCustomTagMetricEntries(templateEntries, metricTagId);
  }

  return _.uniq(_.map(customTags, (customTag) => {
    return _.get(customTag, 'templateEntry.template_id');
  }));
}


export const isShowDistributionChart = (viewEntry) => {
  return !_.isEmpty(getDistributionBucketEntry(viewEntry));
}

export const getDrilldownEntryWithoutViewEntry = (drilldownEntry, visualizationType = 'distribution') => {
  let drilldownWithoutViewEntry = _.omit(drilldownEntry, 'currentDrilldownViewEntry');
  let currentViewEntryField = _.get(drilldownEntry, 'currentDrilldownViewEntry.field', '');
  drilldownWithoutViewEntry['currentViewEntryField'] = _.isEmpty(currentViewEntryField) ?
    _.get(drilldownEntry, 'currentViewEntryField', '') : currentViewEntryField;
  drilldownWithoutViewEntry['currentVisualizationType'] = visualizationType;
  return drilldownWithoutViewEntry;
}

export const getSelectedDimensionEntry = (currentDrilldownTemplateId, dimensionField) => {
  return getSelectedDimensionEntryBy(currentDrilldownTemplateId, 'field', dimensionField);
}
export const getSelectedDimensionEntryByColumn = (currentDrilldownTemplateId, dimensionColumn) => {
  return getSelectedDimensionEntryBy(currentDrilldownTemplateId, 'column', dimensionColumn);
}
const getSelectedDimensionEntryBy = (currentDrilldownTemplateId, key, value) => {
  let templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);
  let dimensionsEntries = _.get(templateEntry,'dimension_entries', []);
  return _.find(dimensionsEntries, (entry) =>{
    return (entry[key] == value);
  });
}

const getAbsoluteTargetEntry = (total, viewEntry, userTargetEntry) => {
  const userTargetEntries = _.get(userTargetEntry, 'configuration.targetEntries');
  const targetEntries = userTargetEntries || _.get(viewEntry, 'target_entries', []);

  if(_.isEmpty(targetEntries)) {
    return null;
  }

  let currentTargetEntry = _.find(targetEntries, (entry) => {
    let isTargetComplete = false;
    const operator = entry.operator;

    if(_.isEmpty(operator)) {
      return true;
    }

    if(operator == 'between') {
      isTargetComplete = (Number(total) > Number(entry.value) && Number(total) < Number(entry.to));
    } else if(operator == 'not between') {
      isTargetComplete = (Number(total) < Number(entry.value) && Number(total) > Number(entry.to));
    } else {
      isTargetComplete = (eval(`${Number(total)} ${operator} ${Number(entry.value)}`));
    }

    return isTargetComplete;
  });

  if (_.isEmpty(currentTargetEntry)) {
    const defaultTargetEntry = _.get(userTargetEntry, 'configuration.defaultTargetEntry');
    currentTargetEntry = defaultTargetEntry || _.get(viewEntry, 'default_target_entry', {});
  }

  return currentTargetEntry;
}

const getDistributionBucketValue = (viewEntry, targetBenchMarkEnry, chartTotalData) => {
  const distributionEntryBuckets = _.get(viewEntry,
    'visualization.distribution.distribution_entry.buckets', []);
  const bucketsWithValue = getDistributionBucketsWithValue(distributionEntryBuckets, chartTotalData)
  const bucketLabel = _.get(targetBenchMarkEnry, 'bucket_label');
  const bucketAggregateType = _.get(targetBenchMarkEnry, 'bucket_aggregate_type');
  const isDiscrete = _.includes(["discrete_value", "discrete_percentage"], bucketAggregateType);
  let totalValue = _.chain(chartTotalData).
    filter((value, key) => _.startsWith(key, 'bucket_')).
    values().
    map(_.toNumber).
    sum().
    value();
  const otherBucketValue = Number(_.get(chartTotalData, 'OTHER_BUCKET_ID'));
  totalValue = totalValue + otherBucketValue;
  let newBucketValue;
  const valueType = isDiscrete ? 'discreteValue' : 'cumulativeValue';

  if(_.isEmpty(bucketLabel)) {
     newBucketValue = totalValue;
  } else if (bucketLabel === 'other_bucket') {

    newBucketValue = isDiscrete ?
      otherBucketValue :
      _.get(_.last(bucketsWithValue), 'cumulativeValue') + otherBucketValue;
  } else {
    const { label } = JSON.parse(_.get(targetBenchMarkEnry, 'bucket_label') || "{}");
    const matchedBucket = _.find(bucketsWithValue, { label });

    newBucketValue = _.get(matchedBucket, valueType);
  }

  if (_.includes(["cumulative_percentage", "discrete_percentage"], bucketAggregateType)) {
    newBucketValue = Number(newBucketValue)/Number(totalValue)*100.0;
  }

  return _.isEmpty(bucketLabel) ? totalValue : newBucketValue;
}

const getDistributionBucketsWithValue = (distributionEntryBuckets, chartTotalData) => {
  let cumulativeValue = 0;
  return _.map(distributionEntryBuckets, (bucket) => {
    const { bucket_id } = bucket;
    const bucketValue =  Number(_.get(chartTotalData, bucket_id, 0));
    cumulativeValue = cumulativeValue + bucketValue;

    return _.merge({}, bucket, {
      discreteValue: bucketValue,
      cumulativeValue
    });
  });
}

export const showTemplateWarningBanner = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  const expiryStartDate = _.get(templateEntry, 'expiryStartDate'),
    expiryEndDate = _.get(templateEntry, 'expiryEndDate'),
    showBanner = _.get(templateEntry, 'showWarningBanner');

  return showBanner == 'true' && dateRangeExpireCheck(expiryStartDate, expiryEndDate)

}

export const getTemplateExpirationMessage = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'expiration_message', '');
}
