import React from 'react';
import PropTypes from 'prop-types';
import { ForgeDialog, ForgeButton } from '@tylertech/forge-react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ReRunDialogModal = ({ showModal, setShowModal, onModalClick, onModalCancel }) => {

  const handleKeyDownConfirm = (e) => {
    if (isEnterButtonPressed(e)) {
      onModalClick();
    }
  };

  const handleKeyDownRemoveCard = (e) => {
    if (isEnterButtonPressed(e)) {
      setShowModal(false);
      onModalCancel();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    onModalCancel();
  };

  const renderDeleteModal = () => {
    return (
      <ForgeDialog
        onDismiss={() => handleModalClose()}
        open={showModal}
      >
        <header className="forge-dialog__header" forge-dialog-move-target="">
          <span className='forge-dialog__title'> Re-run forecasting models? </span>
        </header>

        <section className="forge-dialog__body ml-1" style={{ width: '600px' }}>
          This kind of change requires forecasting models to update.
          Because there is new data available since the last time models were run,
          this may change your forecast.
        </section>

        <footer className="forge-dialog__footer">
          <ForgeButton type="flat" style={{ marginRight: '16px' }}>
            <button
              aria-label="Cancel"
              tabIndex={0}
              onClick={() => handleModalClose()}
              onKeyDown={(event) =>
                handleKeyDownRemoveCard(event)
              }
              id='rerun_modal_cancel'
            >
              Cancel
            </button>
          </ForgeButton>

          <ForgeButton type="raised">
            <button
              aria-label="Confirm"
              tabIndex={0}
              onClick={(event) => onModalClick(event)}
              onKeyDown={(event) =>
                handleKeyDownConfirm(event)
              }
              id='rerun_modal_confirm'
            >
              Re-run models
            </button>
          </ForgeButton>
        </footer>
      </ForgeDialog>
    );
  }

  return (
    <div>
      {renderDeleteModal()}
    </div>
  );
}


ReRunDialogModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onModalClick: PropTypes.func,
  onModalCancel: PropTypes.func,
};

ReRunDialogModal.defaultProps = {
  onModalCancel: _.noop,
  onModalClick: _.noop,
  showModal: false,
  setShowModal: _.noop,
}

export default ReRunDialogModal;
