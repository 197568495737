import React from 'react'
import PropTypes from 'prop-types'
import { ForgeIcon, ForgeInlineMessage} from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { SINGLE_VALUE } from 'appConstants';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';

function ObservationsBanner(props) {
  const { nullDataCount, isLineChart } = props;
  const metricEntry = useSelector((state) =>
    _.get(state, 'drilldown.currentDrilldownViewEntry', {})
  );

  const linkVerb = nullDataCount === SINGLE_VALUE ? 'is' : 'are';

  const formattedRecordsText = getFormattedNumberValue(nullDataCount, metricEntry)
  let bannerText = '';
  if(isLineChart){
    bannerText = `There ${linkVerb} ${formattedRecordsText} that are included in the total,
    but are not visible in the Over Time chart below.`;
  } else {
    bannerText = `There ${linkVerb} ${formattedRecordsText} that are included in the total,
    but are not visible on the map below because they are outside the selected shape boundaries
    or don't have an associated location.`;
  }

  return (
    <div className='observations-banner'>
      <ForgeInlineMessage>
        <ForgeIcon slot="icon" name="info"></ForgeIcon>
        { bannerText }
      </ForgeInlineMessage>
    </div>
  );
}

ObservationsBanner.propTypes = {
  nullDataCount: PropTypes.number,
  isLineChart: PropTypes.bool
}

export default ObservationsBanner
