import { fetchApiData } from 'helpers/apiResponseHelper';
import { buildQueryString } from 'helpers/HttpHelper';
import abortableFetch from 'common/abortableFetch';

export const getVisualizationData = (queryParams) => {
  const apiUrl = "/api/visualization/bar_chart.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getVisualizationTotalData = (queryParams) => {
  const apiUrl = "/api/visualization/chart_total.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getMetricDataQuery = (queryParams) => {
  const apiUrl = "/api/metric_query.json";
  return abortableFetch(`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const getForecastAccuracy = (queryParams) => {
  const apiUrl = "/api/visualization/forecast_accuracy.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getMetricRange = (queryParams) => {
  const apiUrl = "/api/visualization/get_metric_range.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getStackViewData = (queryParams) => {
  const apiUrl = "/api/visualization/stack_bar_chart.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getInspectViewData = (queryParams) => {
  const apiUrl = "/api/visualization/inspect_table.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getAggregateRelatedData = (queryParams) => {
  const apiUrl = "/api/visualization/aggregated_row_details.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSummaryTableCsvData = (queryParams, isCsv) => {
  const apiUrl = `/api/visualization/summary_table.${isCsv ? 'csv' : 'json'}`;
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const getLeafPageData = (queryParams) => {
  const apiUrl = "/api/visualization/leaf_page_data.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSearchFieldValues = (queryParams, abortController) => {
  const apiUrl = "/api/search/field_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
};

export const getFlyoutDetails = (queryParams) => {
  const apiUrl = "/api/visualization/map/flyout_details.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const fetchShapeFlyoutEntries = (queryParams) => {
  const apiUrl = "/api/shapes/shape_flyout_details.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getColumnTopValues = (queryParams) => {
  const apiUrl = "/api/visualization/column_top_values.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getLineChartData = (queryParams) => {
  const apiUrl = "/api/visualization/line_chart.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const getForecastingData = (queryParams) => {
  const apiUrl = "/api/visualization/forecasting.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
}

export const getShapeWiseData = (queryParams) => {
  const apiUrl = `${window.location.protocol}//${window.location.host}`+
    "/api/visualization/map/shape_wise.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getDistributionChartData = (queryParams) => {
  const apiUrl = "/api/visualization/distribution_chart.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getVisualizationBenchMarkTotals = (queryParams) => {
  const apiUrl = "/api/visualization/benchmark_totals.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getVarianceData = (queryParams) => {
  const apiUrl = "/api/variances.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getVarianceExclusionAnalysisData = (queryParams) => {
  const apiUrl = "/api/variance_exclusion_analysis.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getVarianceOutliersData = (queryParams) => {
  const apiUrl = "/api/clean_outliers.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSLAWatchData = (queryParams) => {
  const apiUrl = "/api/sla_watch_by_dimension.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSLAWatchTotals = (queryParams) => {
  const apiUrl = "/api/sla_watch_total.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getAnovaData = (queryParams) => {
  const apiUrl = "/api/anova.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getDimenisonWiseData = (queryParams) => {
  const apiUrl = "/api/visualization/dimension_wise_total.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getGroupSummaryTotals = (queryParams, abortController) => {
  const apiUrl = "/api/summary_table/snapshot_totals.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
};

export const getDistinctValue  = (queryParams) => {
  const apiUrl = "/api/visualization/distinct_values.json"
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const getSummaryShapeWiseData = (queryParams, abortController) => {
  const apiUrl = "/api/visualization/map/summary_shape_wise.json";
  return fetchApiData(`${apiUrl}?${buildQueryString(queryParams)}`, abortController);
};

export const getNullShapeDataUrl = (queryParams) => {
  const apiUrl = "/api/visualization/map/null_shape_records.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const updateCurrentTemplateCardId = (cardId) => {
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({cardId: cardId}),
    headers:{ 'content-type': 'application/json' }
  };
  const apiUrl = '/api/user_card_preferences/update_user_card_id.json';
  return window.fetch(apiUrl, fetchOptions);
}

export const getShortenUrl = (queryParams) => {
  const apiUrl = "/links/create_short_link.json";
  return (`${apiUrl}?${buildQueryString(queryParams)}`);
};

export const createShortenUrl = (urlParams) => {
  const options =  {
      method: 'POST',
      body: JSON.stringify(urlParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/links/create_short_link.json"
  return abortableFetch(apiUrl, options);
};

export const createUserAccountRequest = (urlParams) => {
  const options =  {
      method: 'POST',
      body: JSON.stringify(urlParams),
      headers:{ 'content-type': 'application/json' },
  };
  const apiUrl = "/api/user_account_request.json"
  return abortableFetch(apiUrl, options);
};
