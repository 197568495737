import React from 'react';
import PropTypes from 'prop-types';
import { getRadarPageDescription } from 'common/config/customerConfiguration';
import BookmarkCreatedText from '../BookmarkCards/BookmarkCreatedText';
const CardFooter = ({ isRadarBookmark, cardEntry }) => {
  const description = isRadarBookmark
    ? _.get(cardEntry, 'bookmarkOptions.description')
    : null;
  const { user_email } = cardEntry;
  if (isRadarBookmark) {
    return (
      <div className="attribution-footer align-items-center">
        <div className="metric-footer forge-typography--caption">
          <div className="text-truncate line-clamp">{description}</div>
          { !_.isEmpty(user_email) ? <BookmarkCreatedText bookmark={cardEntry} /> : null }
        </div>
      </div>
    );
  } else {
    return (
      <div className="attribution-footer d-flex align-items-center">
        <div className="data-provider-info text-truncate line-clamp">
          {getRadarPageDescription()}
        </div>
      </div>
    );
  }
};

CardFooter.propTypes = {
  isRadarBookmark: PropTypes.bool,
  cardEntry: PropTypes.object,
};
export default CardFooter;
