import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EmailInputField from 'common/components/EmailInputField';
import LoadingSpinner from 'common/components/LoadingSpinner';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { isPrivate  } from 'common/config/customerConfiguration';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getAppConfigurations, getPermissionBasedUserList } from 'common/api/adminApi';
import { getAdminConfigurations } from 'pages/Admin/adminHelper';
import { allEmailsAreValid } from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { validateShareEmails } from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { SHARE_EMAIL_SPLITTER, USER_NOT_FOUND_ERROR_TEXT } from 'appConstants';
import { splitUserEmails } from 'modules/Administration/permissionHelper';
import ShortenUrl from 'common/components/SecondaryHeader/ShortenUrl';
import { validateEmail } from 'helpers/HttpHelper';
import { updateUserMenu } from 'actions/managePageActions';
class ShareSection extends Component {
  constructor(props, context) {
    super(props, context);
    const { recipientsEmails } = props;
    this.state = {
      isUserEmailLoading: false,
      userEmails: [],
      allUserEmails: [],
      emailInputs: recipientsEmails.join(SHARE_EMAIL_SPLITTER),
      showEmailError: false,
      openOnFocus: true
    };
  }

  static contextType = UserFromBellerophonContext;

  componentDidMount() {
    const { showCopyLink, isAddCurrentUser, editMode, currentUser, isPermissionEnabled } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    if(isPermissionEnabled){
      this.fetchPermissionUsersList();
    }else if (isPrivate()) {
      this.fetchUserDetails();
    } else if(isAddCurrentUser && !editMode) {
      this.setState({ emailInputs: currentUser['email'] });
    }
    if(showCopyLink){
      this.updateOverwriteCollectionFlag(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { recipientsEmails } = this.props;
    const isRecipientsEmailsChanged = !_.isEqual(prevProps.recipientsEmails, recipientsEmails);
    if (isRecipientsEmailsChanged && !_.isEmpty(recipientsEmails)){
      this.setState({ emailInputs: recipientsEmails.join(SHARE_EMAIL_SPLITTER) });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.ShareSectionRef && !this.ShareSectionRef.contains(e.target)) {
      this.handleCancelClick();
      this.setState({ openOnFocus: false });
    }
  }

  fetchPermissionUsersList = () => {
    const { currentUser, templateIds, isAddCurrentUser, editMode } = this.props;

    const queryParams = { templateIds: JSON.stringify(templateIds) };
    const appConfigUrl = getPermissionBasedUserList(queryParams);
    this.setState({ isUserEmailLoading: true });

    fetchApiData(appConfigUrl).then((response) => {
      if(isAddCurrentUser && !editMode){
        this.setState({ emailInputs: currentUser['email'] });
      }

      this.setState({
        isUserEmailLoading: false,
        userEmails: response,
        allUserEmails: response
      });
    }).catch((error) => {
      this.setState({ isUserEmailLoading: false });
      console.log("Error on fetching user config ", error)
    });
  }

  fetchUserDetails = () => {
    const { currentUser, allowedUserRoles, hiddenUsers, isAddCurrentUser, editMode } = this.props;

    const appId = _.get(this.context, "userFromBellerophon.application.application_id", 1);
    const queryParams = { app_id: appId, app_config_key: 'executive_insights_app_users' };
    const appConfigUrl = getAppConfigurations(queryParams);
    this.setState({ isUserEmailLoading: true });

    fetchApiData(appConfigUrl).then((response) => {
      const appUsers = _.get(getAdminConfigurations(response), 'appConfigUsers');
      const splittedUserEmails = splitUserEmails(appUsers,
        _.get(currentUser, 'user', currentUser), !isAddCurrentUser);
      let filteredEmails = []
      if(_.isEmpty(allowedUserRoles)){
        filteredEmails = splittedUserEmails['normalUser'];
      }else{
        filteredEmails = _.filter(splittedUserEmails['normalUser'], function(user){
          return _.includes(allowedUserRoles, user['role']);
        });
      }
      const userEmails = _.compact(_.map(filteredEmails, (filterEmailItem) => {
        return !_.includes(hiddenUsers, filterEmailItem['email']) ? filterEmailItem['email'] : null;
      }));

      if(isAddCurrentUser && !editMode){
        this.setState({ emailInputs: currentUser['email'] });
      }

      this.setState({
        isUserEmailLoading: false,
        userEmails,
        allUserEmails: _.map(splittedUserEmails['normalUser'], 'email')
      });
    }).catch((error) => {
      this.setState({ isUserEmailLoading: false });
      console.log("Error on fetching user config ", error)
    });
  }

  validateShareEmailInputs = () => {
    const { onEmailUpdate } = this.props;
    const { userEmails, emailInputs } = this.state;
    const shareEmails = _.map(
      _.compact(emailInputs.split(SHARE_EMAIL_SPLITTER)),
      _.trim
    );
    const isValidEmails = validateShareEmails(shareEmails, userEmails);
    if(isValidEmails){
      this.setState({ showEmailError: false });
    }else{
      this.setState({ showEmailError: true, openOnFocus: false });
    }
    onEmailUpdate(shareEmails, !isValidEmails)
  }

  handleEmailInputsChanges = (e) => {
    this.setState({ emailInputs :  e.target.value}, () => {
      this.validateShareEmailInputs();
    });
  }

  handleSendClick = () =>{
    const { emailInputs } = this.state;
    const shareEmails = _.map(
      _.compact(emailInputs.split(SHARE_EMAIL_SPLITTER)),
      _.trim
    );
    this.props.onSendClick({shareEmails});
    this.updateOverwriteCollectionFlag(false);
  }

  handleCancelClick = () =>{
    this.props.onCancelShare();
    this.updateOverwriteCollectionFlag(false);
  }

  updateOverwriteCollectionFlag = (status) => {
    const { updateOverwriteCollectionFilter } = this.props;
    if(!_.isUndefined(updateOverwriteCollectionFilter)){
      updateOverwriteCollectionFilter(status);
    }
  }

  renderCopyLinkButton() {
    const { showCopyLink, trackEventName } = this.props
    if(!showCopyLink){
      return null;
    }

    // const tooltip = (
    //   <Tooltip id="copy-link"> Copy a shortened link of this page </Tooltip>
    // );

    return (

      //<OverlayTrigger key="copy-link" placement="top" overlay={tooltip}>
        <div className='copy-link-button'>
          <ShortenUrl
            buttonText='Copy link'
            trackEventName={trackEventName}
          />
        </div>
      //</OverlayTrigger>
    );
  }

  renderErrorHyperLinkText(){
    const userFromBellerophon = _.get(this.context, "userFromBellerophon", {});
    const userDetails = _.get(userFromBellerophon, 'bellerophon_user_detail', {});
    let errorMessage = ''
    if(_.get(userDetails, 'isManageUser', 'false') === 'true'){
      errorMessage = (
        <>
          {' '}Add them to the user list
          <a href="/#!/admin?menu=userRoles" target="_blank"> here.</a>
        </>
      )
    } else {
      errorMessage = (
        <>
          Please contact your
          <a href="/#!/admin" target="_blank"> local administrator </a>
          to grant access.
        </>
      )
    }
    return errorMessage;
  }

  renderEmailError() {
    const { emailInputs, userEmails, showEmailError, allUserEmails } = this.state;
    const { shareType } = this.props;

    if(!showEmailError) {
      return;
    }
    const shareEmails = _.compact(emailInputs.split(SHARE_EMAIL_SPLITTER));
    let invalidMessage = '', showHyperText = false;

    if(!allEmailsAreValid(shareEmails, userEmails)) {
      _.each(shareEmails, (email)=>{
        if(!validateEmail(email)){
          invalidMessage = 'Invalid email has been entered.';
        }else if(_.includes(allUserEmails, email) && !_.includes(userEmails, email)){
          invalidMessage = `You can only share this ${shareType} with users whose `+
        `permissions match your own. Please contact your administrator with any questions.`;
        }else if(!_.includes(allUserEmails, email)){
          invalidMessage = USER_NOT_FOUND_ERROR_TEXT;
          showHyperText = true;
        }
      });
    }

    return(
      <div className="invalid-feedback ml-2">
        {invalidMessage}
        {showHyperText  && this.renderErrorHyperLinkText()}
      </div>
    );
  }

  render() {
    const { emailInputs, userEmails, isUserEmailLoading, showEmailError, openOnFocus } = this.state;
    const { emailInputPlaceholder, hiddenUsers, showButtons } = this.props;
    return(
      <div className="share-options" ref={(ref) => this.ShareSectionRef = ref}>
        <LoadingSpinner isLoading={isUserEmailLoading} />
        <div className="share-options-wrapper">
          <div className="share-input">
            <EmailInputField
              openOnFocus={openOnFocus}
              userEmails={userEmails}
              emails={emailInputs}
              eventName='emailInputs'
              placeholder={emailInputPlaceholder}
              onInputChange={this.handleEmailInputsChanges}
              autoFocus={showButtons}
              />
            {this.renderEmailError()}
          </div>
          {showButtons && <div className='d-flex gap-10'>
            <forge-button type="raised" class="align-self-baseline">
              <button
                tabIndex="0"
                disabled={_.isEmpty(emailInputs) || showEmailError}
                onClick={this.handleSendClick}>
                {_.isEmpty(hiddenUsers) ? 'Send' : 'Add'}
              </button>
            </forge-button>

            {this.renderCopyLinkButton()}

            <forge-button class="align-self-baseline">
              <button
                tabIndex="0"
                onClick={this.handleCancelClick}
                >
                Cancel
              </button>
            </forge-button>
          </div>}
        </div>
      </div>
    );
  }
}

ShareSection.propTypes = {
  isShareEnabled: PropTypes.bool,
  showEmailError: PropTypes.bool,
  showCopyLink: PropTypes.bool,
  onSendClick: PropTypes.func,
  onCancelShare: PropTypes.func,
  emailInputPlaceholder: PropTypes.string,
  shareType: PropTypes.string,
  currentUser: PropTypes.object,
  allowedUserRoles: PropTypes.array,
  trackEventName: PropTypes.string,
  updateOverwriteCollectionFilter: PropTypes.func,
  dispatchUpdateUserMenu: PropTypes.func,
  hiddenUsers: PropTypes.array,
  showButtons: PropTypes.bool,
  onEmailUpdate: PropTypes.func,
  isAddCurrentUser: PropTypes.bool,
  editMode: PropTypes.bool,
  recipientsEmails: PropTypes.array,
  isPermissionEnabled: PropTypes.bool,
  templateIds: PropTypes.array
};

ShareSection.defaultProps = {
  trackEventName: '',
  emailInputPlaceholder: '',
  showCopyLink: false,
  shareType: 'collection',
  updateOverwriteCollectionFilter: _.noop,
  hiddenUsers: [],
  showButtons: true,
  onCancelShare: _.noop,
  isAddCurrentUser: false,
  onEmailUpdate: _.noop,
  editMode: false,
  recipientsEmails: []
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateUserMenu: (menu) => {
      dispatch(updateUserMenu(menu));
    }
  };
}

export default withRouter(
  connect(null, mapDispatchToProps)(ShareSection)
);
