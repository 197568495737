import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getTimeStampForDate } from '../ManageCollection/collaboratorHelper';
const BookmarkCreatedText = ({bookmark , isShowPeriod}) => {
  const { user_email, created_at, is_shared_accepted } = bookmark;
  const currentUser = useSelector(state => _.get(state.currentUser, 'user', {}));
  const userEmail = _.get(currentUser, 'email', '');
  const email = _.isEqual(userEmail, user_email) ? 'you' : user_email;
  const displayText =
      `Created by ${email} on ${getTimeStampForDate(created_at)}`;
  if (!is_shared_accepted) {
    return null;
  }
  return (
    <div className='display-text'>
      {displayText}{isShowPeriod && '.'}
    </div>
  );
}

BookmarkCreatedText.propTypes = {
  bookmark: PropTypes.object,
  isShowPeriod: PropTypes.bool
};

export default BookmarkCreatedText;
