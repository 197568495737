import moment from 'moment';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import {
  getCompareRangeYears
} from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import { getBarAndScatterSummaryGroupTotal } from './scatterChartSummaryFormatter';
import { formatValueToCurrency } from 'helpers/numberHelper';

export const getBarChartSummaryFormatter = (formattedData,
  summaryTableOptions, summaryTotalData) => {
  const summaryTotals = _.get(summaryTotalData, 'group_totals', []);
  const tableHeaders = getBarChartHeaders(summaryTableOptions, formattedData);
  const tableData = getBarSummaryDataFormatter(
    formattedData,
    summaryTableOptions,
    summaryTotals
  );

  return {
    tableHeaders,
    tableData
  }
}

const getBarChartHeaders = (summaryTableOptions, formattedData) => {
  const {
    templateId,
    primaryMetricName,
    secondaryMericName,
    dimensionName,
    isGroupByNone,
    isGroupChart,
    isComparisonEnabled
  } = summaryTableOptions;
  const title = (!isGroupByNone && isGroupChart) ? 'Category' : dimensionName;

  let tableHeaders = [];

  if (!_.isEmpty(secondaryMericName) && !isComparisonEnabled) {
    const primaryMetricColor = getConfiguredMetricColor(templateId, 'primary');
    const secondaryMetricColor = getConfiguredMetricColor(templateId, 'secondary');

    tableHeaders = [
      { name: title, columnField: "dimension" },
      { name: secondaryMericName, columnField: "secondaryCount", color: primaryMetricColor },
      { name: primaryMetricName, columnField: "dimensionValue", color: secondaryMetricColor }];
  } else if (isComparisonEnabled) {
    tableHeaders = [{ name: title, columnField: "dimension" }];
    const compareRanges = getCompareRangeYears(formattedData, summaryTableOptions);
    _.forEach(compareRanges, (range) => {
      if (!_.isEmpty(range['period'])) {
        let year = moment(range['period']).format('YYYY');
        if (year !== "Invalid date") {
          const yearLabel = _.get(range, 'yearLabel');
          tableHeaders.push({ name: yearLabel, columnField: range['period'], color: range['color'] })
        }
      }
    })
  } else {
    tableHeaders = [
      { name: title, columnField: "dimension" },
      { name: primaryMetricName, columnField: "dimensionValue" }];
  }

  return tableHeaders;
}

const getBarSummaryDataFormatter = (formattedData, summaryTableOptions, summaryTotalData) => {
  const {
    secondaryMetricEntry,
    currentDrilldownViewEntry,
    templateId,
    isCurrencyDimensionField,
    isCurrencyGroupByField,
    isComparisonEnabled
  } = summaryTableOptions;

  if (isComparisonEnabled) {
    return getCompareBarSummaryDataFormatter(formattedData, summaryTableOptions, summaryTotalData);
  }

  let formattedReverseData = _.cloneDeep(formattedData);
  formattedReverseData = _.filter(formattedReverseData, function (datum) {
    return _.get(datum, 'isSeeMoreDimension', false) === false;
  });

  const nullValueLabel = getNullValueLabel(templateId);
  const summaryData = _.map(formattedReverseData, (datum) => {
    const isGroup = _.get(datum, 'isGroup', false) === true;
    let dimensionColumnName = isGroup ? _.get(datum, 'value') : _.get(datum, 'ticktext');
    const primaryCount = getFormattedNumberValue(_.get(datum, 'actualLabel'), currentDrilldownViewEntry);
    let secondaryCount = _.get(datum, 'actualSecondaryLabel');
    secondaryCount = getFormattedNumberValue(secondaryCount, secondaryMetricEntry);

    let groupPrimaryTotal;
    let groupSecondaryTotal;
    let isCurrencyType = isCurrencyDimensionField;
    if (isGroup) {
      const dimensionGroupLabel = nullValueLabel === dimensionColumnName ? '' : dimensionColumnName;
      const groupTotal = getBarAndScatterSummaryGroupTotal(
        summaryTotalData, dimensionGroupLabel, isCurrencyGroupByField
      );
      groupPrimaryTotal = getFormattedNumberValue(_.get(groupTotal, 'count'), currentDrilldownViewEntry);
      groupSecondaryTotal = getFormattedNumberValue(_.get(groupTotal, 'secondary_count'),
        secondaryMetricEntry);
      isCurrencyType = isCurrencyGroupByField;
    }
    dimensionColumnName = formatValueToCurrency(dimensionColumnName, isCurrencyType);

    return {
      isGroup,
      dimension: dimensionColumnName,
      maxValue: '',
      minValue: '',
      secondaryCount: isGroup ? groupSecondaryTotal : secondaryCount,
      dimensionValue: isGroup ? groupPrimaryTotal : primaryCount
    }
  });

  return summaryData;
}

const getCompareBarSummaryDataFormatter = (formattedData, summaryTableOptions, summaryTotalData) => {
  const {
    currentDrilldownViewEntry,
    templateId,
    isCurrencyDimensionField,
    isCurrencyGroupByField
  } = summaryTableOptions;

  let formattedReverseData = _.cloneDeep(formattedData);
  formattedReverseData = _.filter(formattedReverseData, function (datum) {
    return _.get(datum, 'isSeeMoreDimension', false) === false;
  });

  const uniqFormattedData = _.uniqBy(formattedReverseData, 'value');

  const nullValueLabel = getNullValueLabel(templateId);
  const summaryData = [];
  const compareRanges = getCompareRangeYears(formattedData, summaryTableOptions);

  _.forEach(uniqFormattedData, (datum) => {

    const filterFormatted = _.filter(formattedReverseData, (filterDatum) => {
      return filterDatum.value == datum.value;
    });

    _.forEach(compareRanges, (range) => {
      if (!_.isEmpty(range['period'])) {
        let findFormattedData = _.find(filterFormatted, { period: range['period'] });

        if(_.size(filterFormatted) == 1){
          const firstData = _.first(filterFormatted);
          findFormattedData = _.get(firstData, 'isGroup', false) ? firstData : findFormattedData;
        } else {
          findFormattedData = _.find(filterFormatted, { period: range['period'] });
        }

        if (!_.isEmpty(findFormattedData)) {
          const isGroup = _.get(findFormattedData, 'isGroup', false) === true;
          let dimensionColumnName = isGroup ?
            _.get(findFormattedData, 'value') :
            _.get(findFormattedData, 'ticktext');
          const primaryCount = getFormattedNumberValue(
            _.get(findFormattedData, 'actualLabel'), currentDrilldownViewEntry
          );

          let groupPrimaryTotal;
          let isCurrencyType = isCurrencyDimensionField;
          if (isGroup) {
            const dimensionGroupLabel = nullValueLabel === dimensionColumnName ? '' : dimensionColumnName;
            const filteredSummaryTotal = _.filter(summaryTotalData, { period: range['period'] });
            const groupTotal = getBarAndScatterSummaryGroupTotal(
              filteredSummaryTotal, dimensionGroupLabel, isCurrencyGroupByField
            );
            groupPrimaryTotal = getFormattedNumberValue(
              _.get(groupTotal, 'count'), currentDrilldownViewEntry
            );
            isCurrencyType = isCurrencyGroupByField;
          }
          dimensionColumnName = formatValueToCurrency(dimensionColumnName, isCurrencyType);

          const findIndex = _.findIndex(summaryData, { dimensionMapping: _.get(findFormattedData, 'value') });

          if (findIndex == -1) {
            var rowData = {
              isGroup,
              dimension: dimensionColumnName,
              dimensionMapping: _.get(findFormattedData, 'value'),
              dimensionValue: isGroup ? groupPrimaryTotal : primaryCount,
            }
            rowData[range['period']] = isGroup ? groupPrimaryTotal : primaryCount
            summaryData.push(rowData);
          } else {
            summaryData[findIndex][range['period']] = isGroup ? groupPrimaryTotal : primaryCount
          }
        }
      }
    })
  });

  return summaryData;
}
