import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';

export default function UploadUserContent({ onClose, onTemplateDownload, onUpload }) {
  const [file, setFile] = useState([]);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    setFile(file);
  }

  const renderFileImportSection = () => {
    return (
      <>
        <div>Upload a .csv file created from this&nbsp;
          <a className="template-href" onClick={() => onTemplateDownload(true)}>template</a>
          &nbsp;in order to add multiple users at the same time.
        </div>
        <input className='form-control user-upload' type="file" id="fileLoader" name="files"
          title="Load File" onChange={changeHandler} />
      </>
    );
  }

  const renderExportFooter = () => {
    return (
      <>
        <ForgeButton style={{ marginRight: '16px' }}>
          <button
            aria-label="Cancel"
            tabIndex={0}
            onClick={onClose}
          >
            Cancel
          </button>
        </ForgeButton>
        <ForgeButton type="raised">
          <button
            aria-label="Confirm"
            tabIndex={0}
            onClick={() => onUpload(file)}
          >
            Upload
          </button>
        </ForgeButton>
      </>
    );
  }


  return (
    <>
      <ForgeDialog
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        onDismiss={(e) => onClose(e)}
        open={true}
      >
        <header className="forge-dialog__header" forge-dialog-move-target="">
          <h2 className='forge-dialog__title'> Upload users </h2>
        </header>
        <section className="forge-dialog__body ml-1" style={{ width: '800px' }}>
          {renderFileImportSection()}
        </section>
        <footer className="forge-dialog__footer">
          {renderExportFooter()}
        </footer>
      </ForgeDialog>
    </>
  )
}

UploadUserContent.propTypes = {
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  onTemplateDownload: PropTypes.func
}