import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ForgeAutocomplete } from '@tylertech/forge-react';
import { withCardClickHandler } from 'pages/dashboard/components/CardList/CardClickHandler';
import { setCurrentCardId, setCurrentCollectionId } from 'actions/dashboardActions';
import { CARD_TYPES } from 'appConstants';
import { modifyCollectionWiseMetricList } from './AppHeaderHelper';
import { getCollectionWiseMetricList } from 'common/api/collectionApi';
import { updateMetricOpenedAt } from 'common/api/metricTotal';

function HeaderSearchList(props) {
	const {
		updatedSelectedMetric, updateMetricList, updatedSearchtext,
		onCardClick, commonFilters, globalFilters
	} = props;

	const [state, setState] = useState({
		allCards: [],
		isLoaded: false
	})
	const autoCompleteRef = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		}
	})

	const handleClickOutside = (e) => {
		if (autoCompleteRef && autoCompleteRef.current
			&& !autoCompleteRef.current.contains(e.target)) {
			setState({ ...state, isLoaded: false })
		}
	}

	const optionBuilder = (option) => {
		return `<span slot="title" class="list-title">${option.label}</span>
            <span slot="subtitle" class="list-subtitle">${option.collectionNames.join(' | ')}</span>	`;
	};

	const onSelectMetric = ({ detail }) => {
		const selectedMetric = _.find(state.allCards, (card) => {
			return detail == card.value
		});

		if (!_.isEmpty(detail) && !_.isEmpty(selectedMetric)) {
			dispatch(setCurrentCollectionId(selectedMetric.currentCollectionId));
			dispatch(setCurrentCardId(selectedMetric.cardId));
			updatedSelectedMetric(selectedMetric);
			setState({ ...state, isLoaded: false });
			const { templateEntry, viewEntry } = selectedMetric;
			const params = {
				template_id: templateEntry.template_id,
				view_id: viewEntry.view_id
			}
			updateMetricOpenedAt(params);
			selectedMetric.isBookmarkCard ? onCardClick(CARD_TYPES.BOOKMARK, selectedMetric, commonFilters, true)
				: onCardClick(CARD_TYPES.TEMPLATE, selectedMetric, {}, true);
		}
	}

	const fetchFilteredData = async (searchText) => {
		if (!state.isLoaded) {
			const collectionsWithMetricList = await getCollectionWiseMetricList()
				.then((res) => res.json()).then(result => result);
			updateMetricList(collectionsWithMetricList);
			try {
				const allMetricCards = modifyCollectionWiseMetricList(
					collectionsWithMetricList, commonFilters, globalFilters);
				setState({
					allCards: allMetricCards,
					isLoaded: true
				})
				return allMetricCards.filter(option => _.lowerCase(option.label)
					.includes(_.lowerCase(searchText)))
			} catch (error) {
				setState({ ...state, isLoaded: true });
				return [];
			}
		} else {
			return state.allCards.filter(option => _.lowerCase(option.label)
				.includes(_.lowerCase(searchText)))
		}
	}

	return (
		<ForgeAutocomplete
			ref={autoCompleteRef}
			className="w-100"
			filter={fetchFilteredData}
			optionLimit={8}
			value={updatedSearchtext}
			popupClasses='header-search-popup'
			optionBuilder={optionBuilder}
			on-forge-autocomplete-change={onSelectMetric}
			allowUnmatched
		>
			<input
				className='update-search-text'
				type="text"
				placeholder="Search"
			/>
		</ForgeAutocomplete>
	)
}

HeaderSearchList.propTypes = {
	updatedSelectedMetric: PropTypes.func,
	updatedSearchtext: PropTypes.string,
	onCardClick: PropTypes.func,
	commonFilters: PropTypes.object,
	globalFilters: PropTypes.object,
	updateMetricList: PropTypes.func
}

export default withCardClickHandler(HeaderSearchList);
