// Vendor Imports
import _ from 'lodash';

// Project Imports
import { getApiParams, getDrilldownEntryForSnapshot } from 'helpers/apiParamsHelper';
import { getDefaultDateMode } from 'helpers/dateHelper';
import {
  getSecondaryMetricEntry,
  getBenchMarkMetricEntries,
  isCurrencyType,
  getAvailableVisualizationType
} from 'helpers/visualizationHelper';
import {
  getPrimaryMetricName,
  getDimensionName,
  getSecondaryMetricNameForOvertime
} from 'helpers/displayNameHelper';
import { getDimensionsList } from 'modules/visualization/LineChart/legendHelper';
import {
  OVERTIME_VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS,
  VISUALIZATION_TYPES,
  OVERTIME_DIMENSION_ORDER,
  DATE_COMPARISON_TYPE
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { canShowProjectionMetric } from 'modules/visualization/LineChart/Helpers/projectionHelper';
import { filterCompareYearRanges } from 'common/contentFormatter/helper';
import {
  showOvertimeChartDimensions,
  defaultNumberOfdimensions
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
 import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { getAxisGranularity } from 'pages/Forecasting/ForecastHelper';
import moment from 'moment';

const CHART_LIMIT = 10;

export const getLineChartAttributes = (props, state, disableShowProjectionOption) => {
  const {
    currentPage,
    isComboChart,
    currentForecastOption,
    currentForecastPercentage,
    prophetForecastingData
  } = state;
  const {
    axisGranularity,
    comparisonType,
    dimensionSortOrder,
    mapOptions,
    drilldown,
    isEmbed,
    commonFilters,
    compareYearRanges,
    currentDrilldownViewEntry,
    isShowProjection,
    currentSelectedTimeFrame,
    currentSecondaryMetricField,
    currentDrilldownTemplateId,
    currentBenchMarkNames,
    currentChartView,
    currentDrilldownDimensionField,
    currentVisualizationType,
    sliderRange,
    dimensionConfigsByRenderType,
    isComparisonEnabled,
    isForecasting,
    forecastDateRange
  } = props;
  const dimension_limit = showOvertimeChartDimensions(currentDrilldownViewEntry, currentChartView) ?
                      defaultNumberOfdimensions(currentDrilldownViewEntry, currentChartView) : CHART_LIMIT;

  const apiParams = getApiParams({ commonFilters, mapOptions, drilldown }, {});
  const dateRange = isForecasting? forecastDateRange : _.get(commonFilters, 'dateRange');
  let dateRangeMode = _.get(commonFilters, 'dateType', getDefaultDateMode());

  if(_.isEmpty(dateRangeMode)) {
    dateRangeMode = getDefaultDateMode();
  }
  const currentTimeFrameOption = (currentChartView === OVERTIME_VISUALIZATION_TYPES.AREA.type) ?
    OVERTIME_TIME_FRAME_OPTIONS.ROLLING:
    currentSelectedTimeFrame;

  const isDimensionHighToLow = showOvertimeChartDimensions(currentDrilldownViewEntry, currentChartView) &&
  dimensionSortOrder == OVERTIME_DIMENSION_ORDER.HIGH_TO_LOW.type;
  const showComparison = isComparisonEnabled && (
    _.get(commonFilters, 'comparisonType') == DATE_COMPARISON_TYPE.SAME_PERIOD)
  const compareToRanges = !showComparison ||
    _.isEmpty(compareYearRanges) ? [] : compareYearRanges;

  const completeApiParams = _.merge({}, apiParams, {
    page: currentPage,
    limit: dimension_limit,
    secondaryMetricField: currentSecondaryMetricField,
    compareYearRanges: JSON.stringify(compareToRanges),
    currentChartType: currentChartView,
    drilldownEntry: getDrilldownEntryForSnapshot(apiParams.drilldownEntry),
    axisGranularity,
    currentSelectedTimeFrame: currentTimeFrameOption,
    sortOrderDimensionHighToLow: isDimensionHighToLow
  });

  const currentSecondaryMetricEntry = getSecondaryMetricEntry(
    currentDrilldownViewEntry, currentVisualizationType, currentChartView, currentSecondaryMetricField);
  const benchMarkNames = _.isArray(currentBenchMarkNames) ? currentBenchMarkNames : [currentBenchMarkNames];
  const currentBenchMarkEntries = getBenchMarkMetricEntries(
    currentDrilldownViewEntry,
    currentVisualizationType,
    currentChartView,
    benchMarkNames
  );
  const showProjectionButton = (
    !_.isEqual(OVERTIME_VISUALIZATION_TYPES.AREA.type, currentChartView) &&
    canShowProjectionMetric(currentDrilldownViewEntry, currentChartView) &&
    !disableShowProjectionOption &&
    isShowProjection
  );
  const newDimensionConfigsByRenderType = _.get(
    dimensionConfigsByRenderType,
    currentChartView,
    dimensionConfigsByRenderType || {}
  );
  const isCurrencyDimensionField = isCurrencyType({
    currentDrilldownTemplateId,
    currentDrilldownDimensionField
  });

  const availableVizType = getAvailableVisualizationType(
    currentDrilldownTemplateId,
    currentVisualizationType,
    currentDrilldownViewEntry);
  const isOvertimeChart = VISUALIZATION_TYPES.OVERTIME.type === availableVizType;
  const selectedDimensions = getSelectedDimensions(
    newDimensionConfigsByRenderType, currentDrilldownTemplateId);
  return {
    apiParams: completeApiParams,
    axisGranularity,
    viewEntry: currentDrilldownViewEntry,
    viewMode: VIEW_MODE.LARGE,
    dateRangeMode: dateRangeMode,
    isEmbed,
    benchMarkEntries: currentBenchMarkEntries,
    templateId: currentDrilldownTemplateId,
    isComboChart: isComboChart,
    isCurrencyDimensionField,
    secondaryMetricEntry: currentSecondaryMetricEntry,
    renderType: currentChartView,
    renderTimeFrame: currentTimeFrameOption,
    dateRange: dateRange,
    compareToRanges: compareToRanges,
    compareYearRanges: showComparison ? filterCompareYearRanges(compareYearRanges, dateRange) : [],
    projectionEnabled: showProjectionButton,
    projectionType: currentForecastOption,
    projectionPercent: currentForecastPercentage,
    projectionOptions: prophetForecastingData,
    currentSelectedTimeFrame,
    isComparisonEnabled,
    comparisonType,
    sliderRange,
    dimensionConfigsByRenderType: newDimensionConfigsByRenderType,
    selectedDimensions,
    benchMarkNames,
    isOvertimeChart,
    isDimensionHighToLow
  };
}

const getSelectedDimensions = (dimensionConfigs, templateId) => {
  const nullValueLabel = getNullValueLabel(templateId);
  return _.chain(dimensionConfigs).
    compact().
    filter((config) => { return !_.get(config, 'isTotalLine'); }).
    map((entry) => {
      const {dimension, visible} = entry;
      return dimension === nullValueLabel || !visible? null : dimension
    }).compact().sort().uniq().value();
}

export const getOvertimeVisualizationTitle = (dimensionConfigs, props, isComboChart) => {
  const {
    currentDrilldownViewEntry,
    currentDrilldownDimensionField,
    currentDrilldownTemplateId,
    currentSecondaryMetricField,
    currentChartView,
    currentVisualizationType
  } = props;
  const dimensionsList = getDimensionsList(dimensionConfigs, currentDrilldownViewEntry, isComboChart);
  const primaryMetricName = getPrimaryMetricName(currentDrilldownViewEntry);
  const dimensionName = getDimensionName(currentDrilldownTemplateId, currentDrilldownDimensionField);
  const currentSecondaryMetricEntry = getSecondaryMetricEntry(
    currentDrilldownViewEntry, currentVisualizationType, currentChartView, currentSecondaryMetricField);
  const secondaryMetricName = getSecondaryMetricNameForOvertime(
    isComboChart, dimensionsList, currentSecondaryMetricEntry);

  return `${primaryMetricName}${secondaryMetricName} by ${dimensionName}`;
}

export const isChangePropsOrStates = (propsOptions, stateOptions, prevProps, prevState) => {
  const snapShotKeys = [
    'currentDrilldownDimensionField',
    'currentDrilldownViewEntry',
    'currentSnapshotView',
    'showScatterPlotRange',
    'currentDrilldownTemplateId',
    'currentGroupByViewType',
    'currentVisualizationType',
    'currentSecondaryMetricField',
    'currentGroupByChartApproach',
    'currentDimensionSortType',
    'currentDrilldownGroupByEntry',
    'showSnapshotChartValues',
    'compareYearRanges',
    'quickFilters' ];

  const snapShotStateKeys = ['totalRowCount', 'currentPage'];

  const isChanged = (
    !_.isEqual(_.pick(propsOptions, snapShotKeys), _.pick(prevProps, snapShotKeys)) ||
    !_.isEqual(_.pick(stateOptions, snapShotStateKeys), _.pick(prevState, snapShotStateKeys))
  );

  return isChanged;
}

export const getSliderRangeValues = (sliderRangeOption, modifiedDate) => {
  const { sliderRange, dateRange, selectedPeriod } = sliderRangeOption;
  let  sliderStartDate = _.first(sliderRange);
  let  sliderEndDate = _.last(sliderRange);

  if(modifiedDate == 'start_date'){
    sliderEndDate = _.get(dateRange, 'endDate')
  } else if (modifiedDate == 'end_date') {
    sliderStartDate = _.get(dateRange, 'startDate')
  }

  const rangeOptions = {
    startDate : sliderStartDate,
    endDate: sliderEndDate
  }

  const axisGranularity = selectedPeriod == 'default' ? getAxisGranularity(rangeOptions) : selectedPeriod;
  const initialStartDate = moment(sliderStartDate).startOf(axisGranularity).format("YYYY-MM-DD");
  // const initialEndDate = moment(sliderEndDate).endOf(axisGranularity).format("YYYY-MM-DD");
  const initialEndDate = moment(sliderEndDate).add(1, 'd').format("YYYY-MM-DD");
  const startDate = moment(initialStartDate).subtract(1, 'd').format('YYYY-MM-DD');
  return {
    sliderRangeDate : [startDate, initialEndDate],
    axisGranularity
  }
}
