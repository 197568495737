import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';
import { removeBodyAttribute } from 'helpers/DomPageHelper';

class DeleteConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: !props.showButtons || false,
    };
  }

  handleClickRemoveCard = (event) => {
    const { onDeleteConfirm } = this.props;
    this.setState({ showModal: false });
    event.preventDefault();
    event.stopPropagation();
    removeBodyAttribute("forge-dialog-open");
    onDeleteConfirm();
  };

  handleKeyDownConfirm = (e, isShow) => {
    if (isEnterButtonPressed(e)) {
      this.onShowConfirm(e, isShow);
    }
  };

  handleKeyDownRemoveCard = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleClickRemoveCard(e);
    }
  };

  handleKeyDownDelete = (event) => {
    if (isEnterButtonPressed(event)) {
      this.onShowConfirm(event, true)
    }
  }

  onShowConfirm = (event, isShow) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (isShow) {
      this.props.onClickDeleteButton(isShow);
    } else {
      this.props.onCancelConfirm(isShow);
      removeBodyAttribute("forge-dialog-open");
    }
    this.setState({ showModal: isShow });
  }

  onConfirmationModalClose = () => {
    this.setState({ showModal: false });
    this.props.onCancelConfirm();
    removeBodyAttribute("forge-dialog-open");
  }

  renderDeleteModal() {
    const { showModal } = this.state;
    const { title, message, confirmButtonName } = this.props;

    if (!showModal) {
      return;
    }

    return (
      <ForgeDialog
        onHide={(event) => this.onShowConfirm(event, false)}
        aria-labelledby="contained-modal-title-vcenter"
        onDismiss={() => this.onConfirmationModalClose()}
        open={showModal}
      >
        <header className="forge-dialog__header" forge-dialog-move-target="">
          <h2 className='forge-dialog__title'> {title} </h2>
        </header>

        <section className="forge-dialog__body ml-1" style={{ width: '500px' }}>
          {message}
        </section>

        <footer className="forge-dialog__footer">
          <ForgeButton style={{ marginRight: '16px' }}>
            <button
              aria-label="Cancel"
              tabIndex={0}
              onClick={(event) => this.onShowConfirm(event, false)}
              onKeyDown={(event) =>
                this.handleKeyDownConfirm(event, false)
              }
            >
              Cancel
            </button>
          </ForgeButton>

          <ForgeButton type="raised">
            <button
              aria-label="Confirm"
              tabIndex={0}
              onClick={(event) => this.handleClickRemoveCard(event)}
              onKeyDown={this.handleKeyDownRemoveCard}
            >
              {confirmButtonName}
            </button>
          </ForgeButton>
        </footer>
      </ForgeDialog>
    );
  }

  renderDeleteIcon() {
    const { showDeleteIcon, showMenuItems, name } = this.props;

    return (
      <>
        {showDeleteIcon ? (
          <span tabIndex="-1" aria-label="Remove" role="button" className="remove-icon" >
            <i className="icons-trash m-0"></i>
          </span >
        ) : (<span onClick={() => showMenuItems()}>{name}</span>)
        } </>
    )
  }

  renderForgeButton() {
    const { classNames } = this.props;

    return (
      <ForgeButton type="outlined" className='delete-confirm-modal'>
        <button
          className={_.isEmpty(classNames) ? '' : classNames}
          tabIndex={0}
          onClick={(event) => this.onShowConfirm(event, true)}
          onKeyDown={(event) => this.handleKeyDownDelete(event)}
        >
          {this.renderDeleteIcon()}
        </button>
      </ForgeButton>
    )
  }

  renderNormalButton() {
    const { classNames } = this.props;

    return (
      <div
        className={_.isEmpty(classNames) ? '' : classNames}
        tabIndex={0}
        onClick={(event) => this.onShowConfirm(event, true)}
        onKeyDown={(event) => this.handleKeyDownDelete(event)} >

        {this.renderDeleteIcon()}
      </div>
    )
  }

  render() {
    const { showNormalButton, showButtons } = this.props;
    return (
      <>
        {this.renderDeleteModal()}
        {!showNormalButton && showButtons && this.renderForgeButton()}
        {showNormalButton && showButtons && this.renderNormalButton()}
      </>
    );
  }
}

DeleteConfirmModal.propTypes = {
  classNames: PropTypes.string,
  onDeleteConfirm: PropTypes.func,
  showMenuItems: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  confirmButtonName: PropTypes.string,
  message: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
  onClickDeleteButton: PropTypes.func,
  showNormalButton: PropTypes.bool,
  showButtons: PropTypes.bool,
  onCancelConfirm: PropTypes.func,
};

DeleteConfirmModal.defaultProps = {
  name: 'Delete',
  showMenuItems: _.noop,
  showNormalButton: false,
  onClickDeleteButton: _.noop,
  confirmButtonName: 'Delete',
  showButtons: true,
  onCancelConfirm: _.noop,
};

export default DeleteConfirmModal;
