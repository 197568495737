import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { getGeocodeBoundingbox  } from 'common/config/templateConfiguration';

import { updateGeoCoder } from 'actions/mapOptionsActions';
import { isClickOutSideFilter } from 'common/components/Filters/FilterDomHelper';

class GeoCoderControl extends Component {
  constructor(props) {
    super(props);

    this.initialize(props);
  }

  initialize = (props) => {
    const { map, dropPin, geoCoder, currentDrilldownTemplateId } = props;
    const options = { accessToken: mapboxgl.accessToken };
    const geocodeBoundingbox = getGeocodeBoundingbox(currentDrilldownTemplateId);

    if (!_.isEmpty(geocodeBoundingbox)) {
      options.bbox = geocodeBoundingbox;
    } else {
      options.mapboxgl = mapboxgl;
    }

    this._geoCoderControl = new MapboxGeocoder(options);
    map.addControl(this._geoCoderControl, 'top-left');

    dropPin.initialize();
    const searchValue = _.get(geoCoder, 'place', '');
    this._geoCoderControl.setInput(searchValue);

    const center = _.get(geoCoder, 'center');
    const zoom = _.get(geoCoder, 'zoom');

    if (center && zoom) {
      const feature = getFeature(geoCoder);

      map.flyTo({ center, zoom });
      dropPin.update(feature);
    }

    this._geoCoderControl.on('result', this.onUpdate);
    this._geoCoderControl.on('clear', this.onClear);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const { geoCoder, onHideSearchInput } = this.props
    const mapSearchElement = document.getElementsByClassName("mapboxgl-ctrl-geocoder mapboxgl-ctrl")
    if (isClickOutSideFilter(e, _.last(mapSearchElement))) {
      if(!_.isUndefined(onHideSearchInput) && _.isEmpty(geoCoder)){
        this.props.onHideSearchInput();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { dropPin, geoCoder, currentDrilldownTemplateId, map } = this.props;
    if (!_.isEqual(prevProps.geoCoder, geoCoder)) {
      const feature = getFeature(geoCoder);

      dropPin.update(feature);
    }

    if (prevProps.currentDrilldownTemplateId !== currentDrilldownTemplateId) {
      if (map && this._geoCoderControl) {
        map.removeControl(this._geoCoderControl);
      }
      this.initialize(this.props);
    }
  }

  componentWillUnmount() {
    const { map } = this.props;
    if (map && this._geoCoderControl) {
      map.removeControl(this._geoCoderControl);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onClear = () => {
    this.props.dispatchUpdateGeoCoder({});
    this.props.onHideSearchInput();
  }

  onUpdate = (e) => {
    const { dropPin } = this.props;
    dropPin.update(e.result);
    const geoCoder = {
      place: _.get(e, 'result.place_name', ''),
      center: _.get(e, 'result.center')
    };
    this.props.dispatchUpdateGeoCoder(geoCoder);
  }

  render() {
    return  null
  }
}

GeoCoderControl.propTypes = {
  map: PropTypes.object,
  dropPin: PropTypes.object,
  dispatchUpdateGeoCoder: PropTypes.func,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  geoCoder: PropTypes.object,
  onHideSearchInput: PropTypes.func
};

function mapStateToProps(state) {
  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    geoCoder: _.get(state, 'visualization.mapOptions.geoCoder')
  };
}

const mapDispatchToProps = {
  dispatchUpdateGeoCoder: updateGeoCoder
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoCoderControl);

function getFeature(geoCoder){
  const feature = {
    geometry: {
      type: "Point",
      coordinates: _.get(geoCoder, 'center')
    }
  };

  return _.isEmpty(geoCoder) ? null : feature;
}
