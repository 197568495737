import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { deleteCard } from 'common/api/collectionApi';
import { onClosePropTypes } from 'common/propTypes';
import { isRestrictedCollection } from '../ManageCollection/collaboratorHelper';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';

class CardMoveConfirmModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  handleCardMove = () => {
    const { dropCard, dropCollection, onCardCopy, onCardDelete } = this.props;

    const { userCardEntry } = dropCard;
    const isBookmarkCard = _.get(userCardEntry, 'isBookmarkCard', false);
    const cardId = isBookmarkCard ? _.get(userCardEntry, 'cardEntry.id') : _.get(userCardEntry, 'id');
    const collectionId = _.get(dropCollection, 'id');

    this.setState({ isLoading: true });
    deleteCard(collectionId, cardId)
      .then((response) => response.json())
      .then(() => {
        this.setState({ isLoading: false });
        onCardDelete(dropCard);
      })
      .catch((error) => {
        console.log('Error on delete card', error);
        this.setState({ isLoading: false });
      });
    onCardCopy(dropCard, dropCollection, true);
  };

  render() {
    const { onClose, onCardCopy, dropCollection, dropCard, currentCollection } = this.props;
    const isRestricted = isRestrictedCollection(currentCollection);
    return (
      <>
        <ForgeDialog
          onHide={onClose}
          aria-labelledby="contained-modal-title-vcenter"
          onDismiss={(e) => onClose(e)}
          open={true}
        >
          <header className="forge-dialog__header" forge-dialog-move-target="">
            <h2 className='forge-dialog__title'> Move or copy? </h2>
          </header>

          <section className="forge-dialog__body ml-1" style={{ width: '500px' }}>
            Would you like to move or copy this tile?
          </section>
          <footer className="forge-dialog__footer">
            <ForgeButton style={{ marginRight: '16px' }}>
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(e) => onClose(e)}
              >
                Cancel
              </button>
            </ForgeButton>
            {
              isRestricted ? null :
                <ForgeButton type="outlined" style={{ marginRight: '16px' }}>
                  <button onClick={this.handleCardMove}
                    aria-label="Confirm"
                    tabIndex={0}
                  >Move </button>
                </ForgeButton>
            }
            <ForgeButton type="raised">
              <button
                aria-label="Confirm"
                tabIndex={0}
                onClick={() => onCardCopy(dropCard, dropCollection)}
              >
                Copy
              </button>
            </ForgeButton>
          </footer>
        </ForgeDialog>
      </>
    );
  }
}

CardMoveConfirmModal.propTypes = {
  dropCard: PropTypes.shape({
    userCardEntry: PropTypes.object,
  }),
  currentCollection: PropTypes.object,
  dropCollection: PropTypes.object,
  onCardCopy: PropTypes.func,
  onCardDelete: PropTypes.func,
  onClose: onClosePropTypes,
};

export default CardMoveConfirmModal;
