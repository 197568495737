import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import MiniMapShapeFilter from 'pages/drilldown/MiniMapShapeFilter';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import MapCanvasTimeFrames from '../MapCanvasTimeFrames';
import TemplateSelectionFilter from './TemplateSelectionFilter';
import { defaultMapCanvasTemplateEntryId } from 'common/config/customerConfiguration';
import { DEFAULT_MAP_STYLE_ENTRY } from 'appConstants';

const MapCanvasSidebar = (props) => {
  const {
    selectedShapeIds,
    selectedTemplateIds,
    onShapeEntryChange,
    selectedShapeDatasetEntry,
    toggleSelectedShapeIds
  } = props;
  const currentDrilldownTemplateId = _.isEmpty(selectedTemplateIds) ?
    defaultMapCanvasTemplateEntryId() : _.first(selectedTemplateIds);

  const [sideBarStates, setSideBarStates] = useState({
    showModal: false,
    hideSideBar: false,
    enableMapEvent: true,
    currentMapStyleEntry: DEFAULT_MAP_STYLE_ENTRY,
    shapeDetails: []
  });
  const { hideSideBar } = sideBarStates;

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('map_resize'));
    }, 200);
  }, [hideSideBar]);


  const onDateFilterChange = (dateRangeOptions, type) => {
    const { previousDateRangeOptions } = props;
    let options = _.cloneDeep(dateRangeOptions);
    if (type === 'currentDateRangeOptions') {
      options = _.omit(options, 'comparisonPeriod');
    } else {
      options = _.omit(options, 'relativeDateFilterEntry');
    }
    setSideBarStates(prevState => ({ ...prevState, enableMapEvent: true }));
    if (type == 'currentDateRangeOptions') {
      const compareYearDateRangeOptions = {
        comparisonType: previousDateRangeOptions['comparisonPeriod'],
        dateRange: dateRangeOptions['dateRange'],
        dateType: dateRangeOptions['dateType'],
        comparisonDateRanges: {}
      }

      let prevDateRangeOptions = _.cloneDeep(previousDateRangeOptions);
      prevDateRangeOptions['dateRange'] = _.first(getComparisonPeriodDateRanges(compareYearDateRangeOptions))
      props.onYearChange(prevDateRangeOptions, 'previousDateRangeOptions');
    }
    props.onYearChange(options, type);
  }

  const onToggleButtonClick = () => {
    setSideBarStates(prevState => ({ ...prevState, hideSideBar: !hideSideBar }));
  }

  const onToggleButtonKeyDown = (e) => {
    if(isEnterButtonPressed(e)) {
      onToggleButtonClick();
    }
  }

  const renderTimeFrameOptions = () => {
    const timeFramesAttributes = _.pick(props,
      'currentDateRangeOptions',
      'previousDateRangeOptions'
    );
    return(
    <MapCanvasTimeFrames {...timeFramesAttributes}
      onComparePopUpToggle={(isClicked) => setSideBarStates({enableMapEvent: !isClicked})}
      onDateFilterChange={onDateFilterChange}/>
    );
  }

  const renderTemplateOptions = () => {
    return (
     <TemplateSelectionFilter selectedTemplateIds={selectedTemplateIds}/>
    );
  }

  const sideBarClass = classNames('map-canvas-sidebar drawer-panel',
    { 'drawer-panel-hide': hideSideBar });

  return (
    <div className={sideBarClass}>
      <div className="toggle-btn"
        tabIndex={0}
        onClick={onToggleButtonClick}
        onKeyDown={onToggleButtonKeyDown}>
        <span className="icons-chevron-left"></span>
      </div>
      <div className='drawer-panel-wrapper'>
        {renderTimeFrameOptions()}
        <hr className='dividers' />
        {renderTemplateOptions()}
        <div className='map-canvas-filter'>
          <MiniMapShapeFilter
            alwaysShowMiniMap={true}
            showRecenterButton={true}
            currentDrilldownTemplateId={currentDrilldownTemplateId}
            currentMapStyleEntry={_.get(sideBarStates, 'currentMapStyleEntry')}
            selectedShapeEntry={selectedShapeDatasetEntry}
            selectedShapeIds={selectedShapeIds}
            dispatchToggleShapeIdsFilter={toggleSelectedShapeIds}
            updateShapeDatasetEntry={onShapeEntryChange}
          />
        </div>
      </div>
    </div>
  )
}

MapCanvasSidebar.propTypes = {
  selectedTracts: PropTypes.array,
  comparisonTracts: PropTypes.array,
  onYearChange: PropTypes.func,
  toggleSelectedShapeIds: PropTypes.func,
  currentTemplateId: PropTypes.string,
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  selectedShapeDatasetEntry: PropTypes.object,
  resetGeographicFilters: PropTypes.func,
  onShapeEntryChange: PropTypes.func,
  selectedShapeIds:PropTypes.array,
  selectedTemplateIds:PropTypes.array,
};

export default MapCanvasSidebar;
