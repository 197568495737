import $ from 'jquery';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import PropTypes from "prop-types";
import {
  getGeocodeBoundingbox,
  getMapCenter,
  getMapStyleEntries,
  getMapzoom,
  isMaxBoundsEnable,
} from 'common/config/templateConfiguration';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { getTemplateMapStyle } from 'modules/Map/helpers/mapHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import MapControls from 'modules/Map/partials/MapControls';
import RecenterButton from 'modules/Map/partials/RecenterButton';
import { setScrollZoom } from 'helpers/mapHelper';
import { SCROLL_EVENT, MAP_MOVE_END, MAP_EVENTS } from "modules/visualization/constants";
import GeoCoderControl from 'modules/Map/controls/GeoCoderControl';
import DropPin from 'modules/Map/controls/DropPin';
import PointAndStackPartial from 'modules/Map/partials/PointsAndStackPartial';
import ShapeLayerPartial from 'modules/Map/partials/ShapeLayerPartial';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import { DEFAULT_MAP_VIEW } from 'appConstants';
import { getShapeTileUrl } from 'common/api/map';
import { defaultMapCanvasTemplateEntryId } from 'common/config/customerConfiguration';

const MAP_SOURCE_DATE_LOADING_INTERVAL_TIME = 200;
const MAP_RESIZE_WAITING_TIME = 100;
const MAP_CENTER_AND_ZOOM_WAITING_TIME = 200;

const MapCanvasMap = (props) => {
  const {
    apiParams,
    selectedTemplateIds,
    selectedShapeDatasetEntry,
    selectedShapeIds,
  } = props;
  const shapeGroupId =  _.get(selectedShapeDatasetEntry, 'shape_dataset_id');
  const currentDrilldownTemplateId = _.isEmpty(selectedTemplateIds) ?
     defaultMapCanvasTemplateEntryId() : _.first(selectedTemplateIds);
  const defaultCenter = getMapCenter()
  const defaultZoom = getMapzoom();
  const defaultMapView = _.get(getMapViews(), DEFAULT_MAP_VIEW);

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const [hasMapInitialized, setHasMapInitialized] = useState(false);

  const mapElement = useRef(null);
  const loaderElement = useRef(null);
  const mapRef = useRef(null);

  const mouseInteractionHandlerRef = useRef(null);
  const currentMapStyleEntry = _.first(getMapStyleEntries());
  const showControl = true;

  const debouncedCenterAndZoomChange = useCallback(() => {
    _.debounce(() => {
      const center = mapRef.current.getCenter();
      const zoom = mapRef.current.getZoom();
      GlobalEvent.emit(MAP_MOVE_END, [center.lng, center.lat], zoom);
      setCenter([center.lng, center.lat]);
      setZoom(zoom);
    }, MAP_CENTER_AND_ZOOM_WAITING_TIME);
  },[]);

  useEffect(() => {
    createMap();
    if (mapRef.current) {
      if (showControl) {
        addControls(mapRef.current);
      }
      addAttributeControl(mapRef.current);
      mapRef.current.once('style.load', () => {
        let loadingChecker;
        if (!mapRef.current) { return; }
        // initHandlers(mapRef.current);
        setMapMaxBounds(mapRef.current);
        mapRef.current.on('moveend', debouncedCenterAndZoomChange);
        mapRef.current.on('dragend', onDragMap);
        mapRef.current.on('zoomend', onZoomEnd);
        mapRef.current.on('sourcedataloading', (mapDataEvent) => {
          const isMapboxGlDrawLayer = _.get(mapDataEvent, 'sourceId') == "mapbox-gl-draw-hot";
          if (loadingChecker || isMapboxGlDrawLayer) {
            return;
          }
          loadingChecker = setInterval(() => {
            const loaded = mapRef.current.areTilesLoaded();
            if (loaded) {
              setHasMapInitialized(true);
              clearInterval(loadingChecker);
              loadingChecker = null;
              $(loaderElement.current).removeClass('loader');
            } else {
              $(loaderElement.current).addClass('loader');
            }
          }, MAP_SOURCE_DATE_LOADING_INTERVAL_TIME);
        });
        setIframeHeight();
      });

      GlobalEvent.on(SCROLL_EVENT, onPageScroll);
      GlobalEvent.on(MAP_MOVE_END, onMapMoveEnd);
      GlobalEvent.on(MAP_EVENTS.ZOOM_IN, onMapZoomIn);
      GlobalEvent.on(MAP_EVENTS.ZOOM_OUT, onMapZoomOut);
      GlobalEvent.on(MAP_EVENTS.RE_CENTER, onMapReCenter);
    }

    window.addEventListener('map_resize', resizeMap);

    return () => {
      setTimeout(() => mapRef.current?.remove());
      mouseInteractionHandlerRef.current?.destroy();
      window.removeEventListener('map_resize', resizeMap);
      GlobalEvent.off(SCROLL_EVENT, onPageScroll);
      GlobalEvent.off(MAP_MOVE_END, onMapMoveEnd);
      GlobalEvent.off(MAP_EVENTS.ZOOM_IN, onMapZoomIn);
      GlobalEvent.off(MAP_EVENTS.ZOOM_OUT, onMapZoomOut);
      GlobalEvent.off(MAP_EVENTS.RE_CENTER, onMapReCenter);
    };
  }, []);

  // const initHandlers = () => {
  //   // TODO mouse interaction handler
  //   const mouseInteractionHandler = new MouseInteractionHandler(mapRef.current, apiParams);
  // }

  const createMap = () => {
    try{
      const mapStyle = getTemplateMapStyle(currentMapStyleEntry);
      const map = new mapboxgl.Map({
        container: mapElement.current,
        style: mapStyle,
        center: getMapCenter(),
        zoom: getMapzoom(),
        preserveDrawingBuffer:true,
        attributionControl: false,
        transformRequest: (tileUrl) => {
          const transformedUrl = tileUrl.replace(
            'api.mapbox.com/fonts/v1/mapbox',
            'api.mapbox.com/fonts/v1/socrata'
          );

          return { url: transformedUrl };
        }
      });

      // onMapLoaded(map);
      mapRef.current = map;
      return map;
    } catch (err){
      console.log(err);
    }
  }

  const setMapMaxBounds = (map) => {
    const geocodeBoundingbox = getGeocodeBoundingbox();
    if (!_.isEmpty(geocodeBoundingbox) && isMaxBoundsEnable()) {
      map.setMaxBounds(geocodeBoundingbox);
    } else {
      map.setMaxBounds(null);
    }
  }

  const addControls = (map) => {
    const navigationControl = new mapboxgl.NavigationControl();
    map.addControl(navigationControl, 'top-right');
  }

  const addAttributeControl = (map) => {
    const attributionControl = new mapboxgl.AttributionControl({compact : true});
    map.addControl(attributionControl);
  }
  const getZoom = (zoom) => {
    return _.clamp(zoom , 0, 24);
  }

  const onMapZoomIn = () => {
    if(mapRef.current) {
      mapRef.current.flyTo({
        center: mapRef.current.getCenter(), zoom: getZoom(mapRef.current.getZoom() + 1)
      });
    }
  }

  const onMapZoomOut = () => {
    if(mapRef.current) {
      mapRef.current.flyTo({
        center: mapRef.current.getCenter(), zoom: getZoom(mapRef.current.getZoom() - 1)
      });
    }
  }

  const onMapReCenter = () => {
    if(mapRef.current) {
      const center = getMapCenter();
      const zoom = getMapzoom();
      mapRef.current.flyTo({ center, zoom });
    }
  }

  const onDragMap = () => {
    GlobalEvent.emit(MAP_EVENTS.DRAG_END);
  }

  const onZoomEnd = () => {
    if(mapRef.current) {
      GlobalEvent.emit(MAP_EVENTS.ZOOM_END, mapRef.current.getZoom());
    }
  }

  const onPageScroll = (type) => {
    setScrollZoom(type, mapRef.current);
  }

  const onMapMoveEnd = (mapCenter, mapZoom) => {
    if(!_.isEqual(mapCenter, center) || mapCenter !== zoom) {
      mapRef.current.jumpTo({ mapCenter, mapZoom });
      setCenter(mapCenter);
      setZoom(mapZoom);
    }
  }

  const handleMapSearchInput = () => {
    setShowSearchInput(!showSearchInput );
  }
  const currentPeriodMapClasses = '';

  const renderMapPartial = () => {

    return(
      <PointAndStackPartial
        currentMapView={defaultMapView}
        map={mapRef.current}
        tileParams={apiParams}
        isCanvasMapVisualization={true}>
      </PointAndStackPartial>
    );
  }

  const renderShapeLayerPartial = () => {

    return(
      <ShapeLayerPartial
      map={mapRef.current}
      apiParams={apiParams}
      shapeTileUrl={getShapeTileUrl}
      currentDrilldownTemplateId={currentDrilldownTemplateId}
      shapeGroupId={shapeGroupId}
      selectedShapeIds={selectedShapeIds}
    ></ShapeLayerPartial>
    );
  }

  const renderMap = () => {
    if(!mapRef.current){ return; }

    return(
      <div className={currentPeriodMapClasses} key="current-period-map">
        {renderShapeLayerPartial()}
        {renderMapPartial()}
      </div>
    )
  }

  const renderGeoCoderControl = () => {
    if(!mapRef.current || !showSearchInput){ return; }

    const dropPin = new DropPin(mapRef.current);
    return(
      <GeoCoderControl
        onHideSearchInput={handleMapSearchInput}
        map={mapRef.current} dropPin={dropPin}
      />
    );
  }

  const renderMapContainer = () => {
    if (!hasMapInitialized) {
      return null;
    }
    return (
      <div className="map-resize-wrapper">
        {renderGeoCoderControl()}
        {renderMap()}

      </div>
    );
  }

  const mapSearchButton = () => {
    if (showSearchInput) { return null; }

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          title="search"
          onClick={handleMapSearchInput}>
          <ForgeIcon className="map-search-icon" name="search" />
          <span>Search</span>
        </button>
      </ForgeButton>
    )
  }

  const resizeMap = _.debounce(() => {
    if(mapRef.current) {
      mapRef.current.resize();
    }
  }, MAP_RESIZE_WAITING_TIME)

  const reCenterButtonAttributes = {
    center: defaultCenter, zoom: defaultZoom,
    map: mapRef.current,
  };

  const mapSearchExpandClass = showSearchInput ? 'map-search-expand' : '';
  // return null;
  return (
    <div className="canvas-map">
      <div className="map-container">
        <div ref={loaderElement}></div>
        <MapControls mapControlClassName={mapSearchExpandClass}>
          <div className="map-controls-lside">
            {mapSearchButton()}
            <RecenterButton {...reCenterButtonAttributes} />
          </div>
        </MapControls>
        <div className="map-instance" ref={el => mapElement.current = el}>
        </div>
        {renderMapContainer()}
      </div>
    </div>
  );
}

MapCanvasMap.propTypes = {
  apiParams: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  selectedTemplateIds: PropTypes.array,
  shapeGroupId: PropTypes.string,
  currentMapView: PropTypes.object,
  selectedShapeDatasetEntry: PropTypes.object,
};

MapCanvasMap.defaultProps = {
  isDrilldownVisualizationMap: false,
  isCurrencyDimensionField: true,
  onMapLoaded: _.noop,
  onLoadLegendData: _.noop,
  onDataLoading: _.noop,
  currentMapView: {type: 'point'},

};

export default MapCanvasMap;
