import React, { useState } from 'react'
import { getMapCanvasTemplateEntries } from 'common/config/customerConfiguration';
import ToggleButton from 'pages/Radar/RadarSideBar/ToggleButton';
import { useDispatch } from 'react-redux';
import { updateSelectedTemplateIds } from 'actions/mapCanvasActions';
import PropTypes from 'prop-types';

function TemplateSelectionFilter(props) {
  const templateEntries = getMapCanvasTemplateEntries();
  const [selectedTemplateIds, setSelectedTemplatedIds] = useState(props.selectedTemplateIds);
  const dispatch = useDispatch();

  const onToggleTemplate = (isChecked, id) => {
    let templatedIdsToUpdate = [];
    if(isChecked) {
      templatedIdsToUpdate = [...selectedTemplateIds, id]
    } else {
      templatedIdsToUpdate = _.filter(selectedTemplateIds, (templateId) => templateId !== id);
    }
    setSelectedTemplatedIds(templatedIdsToUpdate);
    dispatch(updateSelectedTemplateIds(templatedIdsToUpdate));
  };

  const templateToggleButton = (name, id, showCheckBox = true) => {
    const isChecked = _.includes(selectedTemplateIds, id);
    return (
      <div className="toggle-button d-flex align-items-center justify-content-between">
      <div className="forge-typography--body1">{name}</div>
        <ToggleButton
          showCheckBox={showCheckBox}
          id={id}
          isChecked={isChecked}
          onClickCheckBox={(isChecked) => onToggleTemplate(isChecked, id)}
        />
      </div>
    );
  }

  const renderTemplateFilterTitle = () => {
    return (
      <>
        <div className='section-titles forge-typography--overline d-flex align-items-center'>
          <span>Template Selection</span>
        </div>
      </>
    )
  }

  const templateFilterWithToggle = () => {
    return (
      <div className='template-selection-filter'>
        {_.map(templateEntries, (entry, index) => {
          return (
            <div key={index} className='template-selection-filter-item mt-1'>
              {templateToggleButton(entry.name, entry.template_id)}
            </div>
          )
        })}
      </div>
    );
  }

  return (
    <div>
      {renderTemplateFilterTitle()}
      {templateFilterWithToggle()}
    </div>
  )
}

TemplateSelectionFilter.propTypes = {
  selectedTemplateIds: PropTypes.array,
}

export default TemplateSelectionFilter
