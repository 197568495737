import moment from 'moment';
import { toast } from 'react-toastify';
import {
  getPermissionMappingEntries,
  getStrictPermissionColumn,
  getUserPermissionEmailColumn,
  isRowLevelPermissionEnabled,
  getSecurityRoles,
  getDefaultSecurityRole
} from 'common/config/customerConfiguration';
import { USER_SEARCH_TYPES } from 'appConstants';


const ALL_STRING = 'All';
export const getPermissionMappingData = (permissionMapping, permissionDate, email) => {
  if(_.isEmpty(permissionDate)){
    return permissionMapping;
  }
  const userColumn = getUserPermissionEmailColumn();
  const userEntry = _.find(permissionDate, {[userColumn]: email});
  if(_.isEmpty(userEntry)) {
    return permissionMapping;
  }
  let newMapping = {};
  _.each(permissionMapping, (value, column) => {
    newMapping[column] = _.isEmpty(userEntry[column]) ? ALL_STRING : userEntry[column];
  });
  return newMapping;
}

export const mapPermissionDataForUsers = (adminConfigs, permissionDate) => {
  const userConfigs = _.get(adminConfigs, 'appConfigUsers', []);
  if(_.isEmpty(userConfigs) || _.isEmpty(permissionDate)){
    return adminConfigs;
  }
  _.each(userConfigs, (user) => {
    user['permission_mapping'] = getPermissionMappingData(
      user['permission_mapping'],
      permissionDate,
      user['email']
    );
  });
  return {
    ...adminConfigs,
    appConfigUsers: userConfigs
  };
}

export const getDefaultPermissionMappingFields = (currentUserPermission) => {
  const permissionColumn = getStrictPermissionColumn();
  if(!_.isEmpty(permissionColumn)){
    return currentUserPermission['permission_mapping'];
  }
  const permissionEntries = getPermissionMappingEntries();
  let permissionFields = {};
  _.each(permissionEntries, (entry) => {
    if(_.get(entry, 'enable', 'false') == 'true'){
      _.each(_.get(entry, 'mapping_columns', []), (mappingEntry) => {
          permissionFields[mappingEntry['permission_dataset_column']] = ALL_STRING;
      })
    }
  })
  return permissionFields;
}

export const splitUserEmails = (configData, currentUser, isHideCurrentUser = true) => {
  let users = {wildCardUser: [], normalUser: [], hiddenUsers: []};
  const strictPermissionColumn = getStrictPermissionColumn();
  const currentUserPermission = _.find(configData, (user) => {
    return _.isEqual(user['email'],  currentUser['email']);
  });
  const strictPermissionValue = _.get(
    currentUserPermission,
    `permission_mapping.${strictPermissionColumn}`,
    'All'
  );

  _.each(configData, function(user) {
    if(isHideCurrentUser && _.isEqual(user['email'],  currentUser['email'])){
      users['hiddenUsers'].push(user);
    }else if(_.startsWith(user.email, '*')){
      users['wildCardUser'].push(user)
    }else if(!_.isEmpty(strictPermissionColumn) && strictPermissionValue != 'All'){
      const permissionValue = _.get(
        user,  `permission_mapping.${strictPermissionColumn}`, 'All');
      if(!_.isEqual(permissionValue, strictPermissionValue)){
        users['hiddenUsers'].push(user);
      }else{
        users['normalUser'].push(user);
      }
    }else{
      users['normalUser'].push(user);
    }
  });
  return users;
}

export const getPermissionFiltersForTemplates = (permissionData, templateIds, email) => {
  if(_.isEmpty(permissionData)){
    return [];
  }
  const userColumn = getUserPermissionEmailColumn();
  const permissionDataForUser = _.find(permissionData, {[userColumn]: email}) || {};
  const mappingEntries = getPermissionMappingEntries()
  let permissionFields = {};
  const permissionEntries = _.map(templateIds, (templateId) => {
    return mappingEntries[templateId];
  })

  _.each(permissionEntries, (entry) => {
    if(_.get(entry, 'enable', 'false') == 'true'){
      _.each(_.get(entry, 'mapping_columns', []), (mappingEntry) => {
        const datasetColumn = mappingEntry['permission_dataset_column'];
        const columnValue = permissionDataForUser[datasetColumn];
        if(!_.isUndefined(columnValue)){
          permissionFields[datasetColumn] = columnValue.split(';');
        }
      })
    }
  });

  return permissionFields;
}

export const isPermissionEnabledForTemplate = (templateId) => {
  const isEnabledRowLevelPermission = isRowLevelPermissionEnabled();
  const mappingEntries = getPermissionMappingEntries();
  const permissionEntry = mappingEntries[templateId];
  return isEnabledRowLevelPermission && _.get(permissionEntry, 'enable', 'false') == 'true';
}

export const getConfirmationMessage = (field, value, email) => {
  const values = value.split(';');
  let valueString = '';

  if(values.length <= 2){
    valueString = values.join(' and ');
  }else{
    const lastValue = _.last(values);
    const valueStringWithoutLast = _.slice(values, 0, - 1).join(', ');
    valueString = `${valueStringWithoutLast} and ${lastValue}`;
  }

  return  `Are you sure you want to change ${email}’s `+
    `${field} permissions to ${valueString}?`;
}

export const getMatchAdministrators = (currentUser, appConfigUsers) => {
  const userEmail = _.get(currentUser, 'user.email');
  const currentUserConfig = _.find(appConfigUsers, { email: userEmail });
  const strictPermissionColumn = getStrictPermissionColumn();
  const currentUserPermissionMapping = _.get(
    currentUserConfig,
    ['permission_mapping', strictPermissionColumn]
  );
  const isAdminUser = _.get(currentUserConfig, 'isManageUser') == 'true';
  const isEnabledRowLevelPermission = isRowLevelPermissionEnabled();

  const matchedAdministrators = _.chain(appConfigUsers)
    .filter((appConfigUser) => {
      const appUserEmail = _.get(appConfigUser, 'email');
      const isManageUser = _.get(appConfigUser, 'isManageUser');
      const permissionMapping = _.get(appConfigUser, ['permission_mapping', strictPermissionColumn], '');
      if(isEnabledRowLevelPermission && strictPermissionColumn && currentUserPermissionMapping != 'All') {
        return (
          appUserEmail != userEmail && isManageUser == "true" &&
          _.isEqual(currentUserPermissionMapping, permissionMapping)
        );
      } else {
        return (appUserEmail != userEmail && isManageUser == "true");
      }
    })
    .map('email')
    .value();

  return { matchedAdministrators, isAdminUser };
}


export const validateImportUsers = (users,currentUser,usersList) => {

  let usersFromList = _.cloneDeep(users);
  const securityRoles = getSecurityRoles();
  const nickName = _.capitalize(_.get(currentUser, 'nickname', ''));
  const currentUserEmail = _.get(currentUser, 'email', '');
  _.each(_.uniq(usersList), (user) => {
    if(user['email']){
      var currentRole = _.find(securityRoles, (role) => {
        return role.name == user['role']
      });
      if(_.isEmpty(currentRole)){
        currentRole = _.find(securityRoles, (role) => {
          return role.role_id == getDefaultSecurityRole()
        });
      }
      const canManageUser = _.isEqual(_.get(currentRole, 'can_manage_user', ''), 'true');
      const canManageBranding = _.isEqual(_.get(currentRole, 'can_manage_branding', ''), 'true');
      const canManageBulkUsers = _.isEqual(_.get(currentRole, 'can_bulk_user_management', ''), 'true');
      const canManageCollections = _.isEqual(_.get(currentRole, 'can_manage_collections', ''), 'true');
      const canRestrictCollections = _.isEqual(_.get(currentRole, 'can_restrict_collections', ''), 'false');
      const canManageApp = (canManageUser || canManageBranding);

      var current_user_permission_mapping = _.omit(user,['email','role']);
      current_user_permission_mapping = _.forEach(current_user_permission_mapping , (value,key) => {
        current_user_permission_mapping[key] = value ? value : 'All'
      })

      var user_details = _.find(usersFromList, (user_detail) => {
        return user_detail['email'].toLowerCase() == user['email'].toLowerCase()
      });
      if(_.isEmpty(user_details)){
        const newUser = {
          email: user['email'].toLowerCase(),
          isAdmin: _.toString(canManageApp),
          isManageUser: _.toString(canManageUser),
          isManageBranding: _.toString(canManageBranding),
          isBulkUserManagement: _.toString(canManageBulkUsers),
          isManageCollections: _.toString(canManageCollections),
          canRestrictCollections: _.toString(canRestrictCollections),
          send_welcome_email: 'true',
          send_admin_welcome_email: _.toString(canManageUser),
          welcome_email_sent_at: '',
          admin_welcome_email_sent_at: '',
          is_email_sent: 'false',
          user_created_at: moment.utc(moment()).format(),
          user_updated_at: moment.utc(moment()).format(),
          user_created_from: 'administration_page',
          role: _.get(currentRole, 'role_id'),
          user_created_by: _.isEmpty(nickName) ? currentUserEmail : nickName,
          permission_mapping:  current_user_permission_mapping
        };
        usersFromList.push(newUser);
      }else{
        const lowerCasedEmail = user_details['email'].toLowerCase();
        const isUserRoleChanged = !_.isEqual(user_details['role'], _.get(currentRole, 'role_id'));
        const isPermissionChanged = !_.isEqual(
          user_details['permission_mapping'], current_user_permission_mapping);

        user_details['role'] = _.get(currentRole, 'role_id')
        user_details['isAdmin'] = _.toString(canManageApp)
        user_details['isManageUser'] = _.toString(canManageUser)
        user_details['isManageBranding'] = _.toString(canManageBranding)
        user_details['isBulkUserManagement'] = _.toString(canManageBulkUsers);
        user_details['isManageCollections'] = _.toString(canManageCollections);
        user_details['canRestrictCollections'] = _.toString(canRestrictCollections);
        user_details['permission_mapping'] =  current_user_permission_mapping

        if(isPermissionChanged && isUserRoleChanged){
          toast.success('User "'+ lowerCasedEmail +'" already exists, role and permission value changed');
        }
        if(isPermissionChanged && !isUserRoleChanged){
          toast.success('User "'+ lowerCasedEmail +'" already exists, permission value changed');
        }
        if(isUserRoleChanged && !isPermissionChanged){
          toast.success('User "'+ lowerCasedEmail +'" already exists, role value changed');
        }
        if (!isUserRoleChanged && !isPermissionChanged){
          toast.error('User with the email "' + lowerCasedEmail
            + '" already has access to this application.');
        }
      }
    }
  });
  return usersFromList;
}

export const isNewUsersImported = (users,usersList) => {
  let isNewUser = false;
  _.forEach(usersList, (user) => {
    let userInfo = _.find(users, (user_detail) => {
      return user_detail['email'].toLowerCase() == user['email'].toLowerCase()
    });
    if (_.isEmpty(userInfo)){
      isNewUser = true;
      return
    }
  });
  return isNewUser;
}

export const getUserListDataBasedOnSearchValue = (searchText, users, searchTitle) => {
  const uppercaseSearchText = searchText.toUpperCase();
  let filteredUsers = users;
  if(_.isEmpty(uppercaseSearchText)) {
    return filteredUsers;
  }

  if(searchTitle == USER_SEARCH_TYPES.EMAIL) {
    filteredUsers = _.filter(users, (user) =>
      user.email.toUpperCase().indexOf(uppercaseSearchText) >= 0
    );
  } else if(searchTitle == USER_SEARCH_TYPES.ROLES) {
    const securityRoles = getSecurityRoles();
    const groupedByRoleIds = _.groupBy(securityRoles, 'role_id');

    filteredUsers = _.filter(users, (user) => {
      const roleName = _.get(groupedByRoleIds, [user.role, '0', 'name'], '');
      return roleName.toUpperCase().indexOf(uppercaseSearchText) >= 0;
    });
  } else if(searchTitle == USER_SEARCH_TYPES.PERMISSIONS) {
    filteredUsers = _.filter(users, (user) => {
      var permissionMapping = _.get(user, 'permission_mapping', {});
      var permissionMappingValues = _.values(permissionMapping).toString();

      return (permissionMappingValues.toUpperCase().indexOf(uppercaseSearchText) >= 0);
    });
  }

  return filteredUsers;
}
