// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Project Imports
import ComparisonMap from 'modules/Map/ComparisonMap/ComparisonMap';
import {
  isChoroplethMap,
  getMinimumMapResizeHeight,
  calculateMapContainerHeight
} from 'modules/Map/helpers/MapOptionsHelper';
import VisualizationDescription from 'pages/drilldown/visualizations/VisualizationDescription';
import SummaryTable from 'modules/visualization/SnapshotVisualization/SummaryTable';
import {
  getMapCenter,
  getMapzoom,
  showMapUnderLeafPage,
  getLeafPageEntries
} from 'common/config/templateConfiguration';
import { updateComparisonModeOn } from 'actions/commonFiltersActions';
import { updateCenterAndZoom, updateLegends } from 'actions/mapOptionsActions';
import { isCurrencyType } from 'helpers/visualizationHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import LeafPage from '../LeafPage/LeafPage';
import {
  getMapTableHeader
} from 'modules/visualization/SnapshotVisualization/SummaryTable/mapSummaryFormatter';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import { PageScrollHandlerWrapper } from 'common/components/Hoc/PageScrollHandlerWrapper';
import ReactSplitPane from 'pages/Radar/ReactSplitPane';
import { Pane } from 'react-split-pane';
import { DEFAULT_MAP_CONTAINER_HEIGHT } from 'appConstants';
import MapLegends from 'modules/Map/Legends/MapLegends';
import { isMobileOrTablet, scrollLegendElementToTarget } from 'helpers/DomPageHelper';
import OtherPinsBanner from './Map/OtherPinsBanner';

class MapVisualization extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tableData: [],
      rowIdField: '',
      containerHeight: DEFAULT_MAP_CONTAINER_HEIGHT,
      mapContainerHeight: DEFAULT_MAP_CONTAINER_HEIGHT,
      isOpenLegend: true,
      isLeafDescriptionAdded: false,
      nullShapeRecords: []
    };

    this.setMapWrapperRef = this.setMapWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._descriptionRef = React.createRef(null);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    GlobalEvent.on('MAP_LEAFPAGE_DATA_ID', this.setLeafPageTableData);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    GlobalEvent.off('MAP_LEAFPAGE_DATA_ID', this.setLeafPageTableData);
  }

  setMapWrapperRef(node) {
    this.mapWrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.mapWrapperRef && !this.mapWrapperRef.contains(event.target)) {
      this.setLeafPageTableData('');
    }
  }

  componentDidUpdate(prevProps) {
    const { currentDrilldownTemplateId } = this.props;
    if (!_.isEqual(prevProps.currentDrilldownTemplateId, currentDrilldownTemplateId)) {
      this.setLeafPageTableData('');
    }
  }

  setLeafPageTableData = (rowIdField) => {
    const { currentDrilldownTemplateId } = this.props;
    const showMapLeafPage = showMapUnderLeafPage(currentDrilldownTemplateId);
    if (showMapLeafPage) {
      this.setState({ rowIdField: rowIdField })
      if(rowIdField) {
        setTimeout(() => {
          this.setState({ isLeafDescriptionAdded: true })
        }, 400)
      }
    }
  }

  renderErrorMessage() {
    return (
      <div className="map-visualization">
        No map configuration found.
      </div>
    );
  }

  renderMapDescription(boundaryMapName) {
    const {
      currentMapView
    } = this.props;
    return (
      <VisualizationDescription
        boundaryMapName={boundaryMapName}
        currentMapView={currentMapView} />
    );
  }

  onNullShapeDataLoaded = (nullShapeRecords) => {
    this.setState({ nullShapeRecords });
  }

  renderSummaryTable() {
    const { currentMapView, currentDrilldownTemplateId, isEmbed } = this.props
    const { nullShapeRecords } = this.state;
    if (_.get(currentMapView, 'type', '') !== 'choropleth' || isEmbed) {
      return
    }

    let summaryTableOptions = _.pick(this.props,
      'commonFilters',
      'comparison',
      'currentDrilldownTemplateId',
      'currentMapStyleEntry',
      'currentMapView',
      'drilldown',
      'mapOptions',
      'polygonsGeojson',
      'selectedShapeIds',
      'selectedShapesExtent',
      'shapeGroupId',
      'shapeName',
      'currentDrilldownViewEntry'
    );

    const summaryTableData = getMapTableHeader(this.props);

    return (
      <SummaryTable
        nullShapeData={nullShapeRecords}
        tableHeaders={summaryTableData.tableHeaders}
        tableData={summaryTableData.tableData}
        summaryTableOptions={summaryTableOptions}
        currentDrilldownTemplateId={currentDrilldownTemplateId}/>
    );
  }

  onChangeSize = (containerHeight) => {
    const currentMapContainerHeight = containerHeight;
    const minimumMapContainerHeight = getMinimumMapResizeHeight();
    const mapContainerHeight = currentMapContainerHeight > minimumMapContainerHeight ?
      currentMapContainerHeight :
      minimumMapContainerHeight;

    this.setState({ containerHeight, mapContainerHeight }, () => {
      window.dispatchEvent(new Event('map_resize'));
    });
  }

  toggleClickLegendButton = (isOpenLegend) => {
    this.setState({ isOpenLegend: isOpenLegend });
    scrollLegendElementToTarget(isOpenLegend);
  }

  renderMapView = () => {
    const { currentDrilldownDimensionField, currentDrilldownTemplateId } = this.props;
    const { isOpenLegend } = this.state;
    const comparisonMapAttributes = _.pick(this.props,
      'center',
      'commonFilters',
      'comparison',
      'currentDrilldownTemplateId',
      'currentMapStyleEntry',
      'legendItems',
      'currentMapView',
      'dispatchUpdateCenterAndZoom',
      'dispatchUpdateComparisonModeOn',
      'dispatchUpdateLegends',
      'drilldown',
      'onMapLoaded',
      'mapOptions',
      'polygonsGeojson',
      'selectedShapeIds',
      'selectedShapesExtent',
      'shapeGroupId',
      'zoom',
      'isSideBar',
      'isComparisonEnabled'
    );

    const isCurrencyDimensionField = isCurrencyType({
      currentDrilldownTemplateId, currentDrilldownDimensionField
    });

    return (
      <div className="map-section" ref={this.setMapWrapperRef}
        style={{ height: this.state.containerHeight }}>
        <ComparisonMap
          {...comparisonMapAttributes}
          isCurrencyDimensionField={isCurrencyDimensionField}
          containerHeight={this.state.containerHeight}
          isOpenLegend={isOpenLegend}
          toggleClickLegendButton={this.toggleClickLegendButton}
        />
      </div>
    )
  }

  renderMapFooter = () => {
    const { currentMapView, shapeName } = this.props;
    const boundaryMapName = isChoroplethMap(currentMapView) ? ` by ${shapeName}` : '';

    return (
      <div className="visualization-footer" ref={this._descriptionRef}>
        {this.renderMapDescription(boundaryMapName)}
        {this.renderLeafPageVisualization()}
      </div>
    )
  }

  renderOtherPinsBanner () {
    return(
      <OtherPinsBanner {...this.props}
      onNullShapeDataLoaded={this.onNullShapeDataLoaded}
      />
    )
  }

  renderMapVisualization() {
    const { currentMapView, center, zoom } = this.props;
    const { mapContainerHeight, isOpenLegend } = this.state;

    if (_.isEmpty(center) || _.isNil(zoom)) {
      return null;
    }
    const isChoroplethMapView = isChoroplethMap(currentMapView);
    const vizClassNames = classNames('map-visualization', {
      'legend-container': isChoroplethMapView
    });
    const mapContainerMinHeight = calculateMapContainerHeight(mapContainerHeight, this._descriptionRef)
    const initialHeight = `${DEFAULT_MAP_CONTAINER_HEIGHT}px`;
    const maxSize = `${DEFAULT_MAP_CONTAINER_HEIGHT + 100}px`;

    return (
      <>
        {this.renderOtherPinsBanner()}
        <div className={vizClassNames}>
          <div className="visualization-wrapper d-block">
            <div className="map-visualization-wrapper">
              <div className="visualization-lside" style={{ minHeight: mapContainerMinHeight }}>

                {!isMobileOrTablet() && <ReactSplitPane onChangeSize={this.onChangeSize}>
                  <Pane initialSize={initialHeight} maxSize={maxSize} >
                    {this.renderMapView()}
                  </Pane>
                  <Pane>
                    {this.renderMapFooter()}
                  </Pane>
                </ReactSplitPane>}

                {isMobileOrTablet() &&
                  <>
                    {this.renderMapView()}
                    {isChoroplethMapView && isOpenLegend &&
                      <MapLegends legends={this.props.legendItems}
                        isOpenLegend={isOpenLegend}
                        toggleClickLegendButton={this.toggleClickLegendButton}
                        showMobileView={true} />
                    }
                    {this.renderMapFooter()}
                  </>
                }
                {/* <MapOptions /> */}
              </div>
            </div>
            {this.renderSummaryTable()}
          </div>
        </div >
      </>
    );
  }

  renderLeafPageVisualization() {
    const { tableData, rowIdField } = this.state;
    const { currentDrilldownTemplateId } = this.props;
    const showMapLeafPage = showMapUnderLeafPage(currentDrilldownTemplateId)
      && !_.isEmpty(getLeafPageEntries(currentDrilldownTemplateId))

    if (_.isEmpty(rowIdField) || !showMapLeafPage) {
      return null;
    }

    const totalRecordsCount = 0;
    const isMapLeafPage = true;
    return (
      <div className="mt-5">
        <LeafPage
          totalRecordsCount={totalRecordsCount}
          leafPageData={tableData}
          rowIdField={rowIdField}
          isMapLeafPage={isMapLeafPage}
        />
      </div>
    );
  }

  render() {
    const { currentMapStyleEntry } = this.props;

    return _.isNil(currentMapStyleEntry) ?
      this.renderErrorMessage() :
      this.renderMapVisualization();
  }
}

MapVisualization.propTypes = {
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentMapView: PropTypes.object,
  shapeName: PropTypes.string,
  zoom: PropTypes.number,
  center: PropTypes.number,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentDrilldownDimensionField: PropTypes.string,
  currentMapStyleEntry: PropTypes.object,
  isSideBar: PropTypes.bool,
  isComparisonEnabled: PropTypes.bool,
  isEmbed: PropTypes.bool,
  legendItems: PropTypes.array
};

const mapDispatchToProps = {
  dispatchUpdateComparisonModeOn: updateComparisonModeOn,
  dispatchUpdateCenterAndZoom: updateCenterAndZoom,
  dispatchUpdateLegends: updateLegends
};

function mapStateToProps(state) {
  const currentDrilldownTemplateId = _.get(state, 'drilldown.currentDrilldownTemplateId', '');
  const mapCenter = getMapCenter(currentDrilldownTemplateId);
  const currentMapViewType = _.get(state, 'visualization.mapOptions.currentMapView.type');
  const legendItems = _.get(state, ['visualization', 'mapOptions', 'legends', currentMapViewType], []);

  return {
    currentDrilldownTemplateId,
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    center: _.get(state, 'visualization.mapOptions.centerAndZoom.center', mapCenter),
    currentMapStyleEntry: _.get(state, 'visualization.mapOptions.currentMapStyleEntry'),
    currentMapView: _.get(state, 'visualization.mapOptions.currentMapView'),
    drilldown: _.get(state, 'drilldown'),
    shapeGroupId: _.get(state, 'visualization.mapOptions.shape.datasetEntry.shape_dataset_id'),
    mapOptions: _.get(state, 'visualization.mapOptions', {}),
    zoom: _.get(state, 'visualization.mapOptions.centerAndZoom.zoom', getMapzoom(currentDrilldownTemplateId)),
    commonFilters: _.get(state, 'commonFilters', {}),
    polygonsGeojson: _.cloneDeep(_.get(state, 'visualization.mapOptions.filteredGeojson', [])),
    selectedShapeIds: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.selectedShapeIds')),
    selectedShapesExtent: _.cloneDeep(_.get(state, 'visualization.mapOptions.shape.selectedShapesExtent')),
    comparison: _.cloneDeep(_.get(state, 'visualization.mapOptions.comparison')),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    shapeName: _.get(state, 'visualization.mapOptions.shape.datasetEntry.shape_name'),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    isComparisonEnabled: _.get(state, 'commonFilters.comparisonModeOn', false),
    legendItems
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageScrollHandlerWrapper(MapVisualization));
