import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getMapAttributes } from 'modules/Map/helpers/mapHelper';
import { getNullShapeDataUrl } from 'common/api/drilldown';
import { useFetchMapOtherPinsCount } from 'modules/hooks/useFetchMapOtherPinsCount';
import ObservationsBanner from 'pages/drilldown/components/StatusBanner/ObservationsBanner';

function OtherPinsBanner(props) {
  const { onNullShapeDataLoaded } = props;
  const { currentPeriodMapAttributes } = getMapAttributes(props);
  const currentMapView = _.get(props, 'currentMapView.type', '');
  const { apiParams } = currentPeriodMapAttributes;
  const url = getNullShapeDataUrl({...apiParams, mapType: currentMapView});
  const {data: nullShapeRecords} = useFetchMapOtherPinsCount(url);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if(!_.isEmpty(nullShapeRecords)){
      onNullShapeDataLoaded(nullShapeRecords);
      setCount(Number(_.get(nullShapeRecords, 'count', 0)));
    }
  }, [JSON.stringify(nullShapeRecords)]);

  if (count <= 0){ return null;}

  return(
    <ObservationsBanner nullDataCount={count} isLineChart={false}/>
  );
}

OtherPinsBanner.propTypes = {
  onNullShapeDataLoaded: PropTypes.func
}

export default OtherPinsBanner
