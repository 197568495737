import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getMapCenter, getShapeFilterMapzoom } from 'common/config/templateConfiguration';
import ShapeFilterPartial from 'modules/Map/partials/ShapeFilterPartial';
import MouseInteractionHandler from 'modules/Map/handlers/MouseInteractionHandler';
import { getTemplateMapStyle } from 'modules/Map/helpers/mapHelper';
import { SCROLL_EVENT, VIEW_MODE } from "modules/visualization/constants";
import { setScrollZoom, getMapPanConfig } from 'helpers/mapHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import RecenterButton from 'modules/Map/partials/RecenterButton';
import RadarDrawPolygonControl from 'modules/Map/controls/RadarDrawPolygonControl';
import RadarPolygonFilterButton from 'modules/Map/partials/RadarPolygonFilterButton';
import MapControls from 'modules/Map/partials/MapControls';

const MAP_SOURCE_DATE_LOADING_INTERVAL_TIME = 200;

class ShapeFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasMapInitialized: false
    };
    this._isMapDrawStart = false;
  }

  componentDidMount() {
    this.map = this.createMap();
    if(this.map){
      this.map.on('style.load', () => {
        let loadingChecker;
        if (!this.map) { return; }
        this.initHandlers();
        this.setState({ hasMapInitialized: true });
        this.map.on('sourcedataloading', () => {
          if (loadingChecker) {
            return;
          }
          loadingChecker = setInterval(() => {
            if (this.map && this.map.areTilesLoaded()) {
              clearInterval(loadingChecker);
              loadingChecker = null;

              this.props.onMapStylesAreLoaded(this.map);
            }
          }, MAP_SOURCE_DATE_LOADING_INTERVAL_TIME);
        });
        this.map.resize();
      });
      GlobalEvent.on(SCROLL_EVENT, this.onPageScroll);
      this.mapElement.addEventListener('mousemove', this.onMouseMove);
      this.map.on('mousedown', () => {
        this._isMapDrawStart = true;
      });
    }
  }

  componentWillUnmount() {
    const map = this.map;
    // Removing it in the next tick, so that the child controls/components can finish cleaning up.
    // (Some of them might require the map for cleaning up.)
    setTimeout(() => map?.remove());
    if (this._mouseInteractionHandler) { this._mouseInteractionHandler.destroy() }

    if (this.map) {
      this.map = null;
      this.setState({ hasMapInitialized: false });
    }
    window.removeEventListener('resize_map', this.resizeMap);
    GlobalEvent.off(SCROLL_EVENT, this.onPageScroll);
    this.mapElement.removeEventListener('mousemove', this.onMouseMove);
  }

  componentDidUpdate(prevProps) {
    const { currentDrilldownTemplateId, currentMapStyleEntry } = this.props;
    const isMapStyleChanged = currentMapStyleEntry &&
      !_.isEqual(prevProps.currentMapStyleEntry, currentMapStyleEntry);
    const isTemplateChanged =
      (currentDrilldownTemplateId  &&
        !_.isEqual(prevProps.currentDrilldownTemplateId, currentDrilldownTemplateId));

      if ( isMapStyleChanged  || isTemplateChanged ){
        this.onMapStyleChange();
      }

    if (!_.isEqual(prevProps.currentDrilldownTemplateId, currentDrilldownTemplateId)){
      this.updateMapPosition(currentDrilldownTemplateId);
    }

    window.addEventListener('resize_map',  this.resizeMap);
  }

  onPageScroll = (type) => {
    setScrollZoom(type, this.map);
  }

  onMouseMove = (event) => {
    const { isRadarDrawingEnabled } = this.props;

    if(isRadarDrawingEnabled && this._isMapDrawStart) {
      const mapPanConfig = getMapPanConfig(this.mapElement, event);
      if(!_.isEmpty(mapPanConfig)) {
        this.map.panBy(mapPanConfig.offset, mapPanConfig.options);
      }
    }
  }

  onDrawUpdate = () => {
    setTimeout(() => {
      this._isMapDrawStart = false;
    }, 100)
  }

  updateMapPosition(currentDrilldownTemplateId) {
    const center = getMapCenter(currentDrilldownTemplateId);
    const zoom = getShapeFilterMapzoom(currentDrilldownTemplateId);
    if(_.isEmpty(center)){
      return;
    }

    this.map.jumpTo({ center, zoom });
  }

  resizeMap = () => {
    if(this.map) {
      this.map.resize();
    }
  }

  createMap() {
    try{
      const { currentMapStyleEntry, currentDrilldownTemplateId } = this.props;
      const mapStyle = getTemplateMapStyle(currentMapStyleEntry, currentDrilldownTemplateId);
      return  new mapboxgl.Map({
        container: this.mapElement,
        style: mapStyle,
        center: getMapCenter(currentDrilldownTemplateId),
        zoom: getShapeFilterMapzoom(currentDrilldownTemplateId),
        scrollZoom: true,
        boxZoom: true,
        dragRotate: false,
        dragPan: true,
        keyboard: false,
        doubleClickZoom: false,
        trackResize: true,
        maxZoom: 16,
        attributionControl: false,
        preserveDrawingBuffer:true
      });
    }catch(err){
      console.log(err)
    }
  }

  initHandlers() {
    const { apiParams } = this.props;

    this._mouseInteractionHandler = new MouseInteractionHandler(this.map, apiParams);
  }

  onMapStyleChange = () => {
    const { currentMapStyleEntry, currentDrilldownTemplateId } = this.props;
    const mapStyle = getTemplateMapStyle(currentMapStyleEntry, currentDrilldownTemplateId);

    this.map.setStyle(mapStyle, { diff: false });
    this.setState({ hasMapInitialized: false });
    this.map.on('style.load', () => {
      if (!this.map) { return; }
      this.setState({ hasMapInitialized: true });
    });
  }

  renderRecenterButton = () => {
    const { currentDrilldownTemplateId, showRecenterButton } = this.props;

    const reCenterAttributes = {
      map: this.map,
      center: getMapCenter(currentDrilldownTemplateId),
      zoom: getShapeFilterMapzoom(currentDrilldownTemplateId),
      viewMode: VIEW_MODE.SMALL
    }

    return showRecenterButton && (<RecenterButton {...reCenterAttributes} />);
  }

  render() {
    const { hasMapInitialized } = this.state;
    const {
      currentDrilldownTemplateId,
      onMapStylesAreLoaded,
      selectedShapeEntry,
      toggleShapeIdsFilter,
      selectedShapeIds,
      shapeTileApi,
      isDrawFilter,
      isRadarDrawingEnabled
    } = this.props;
    let childContent = null;

    if(hasMapInitialized){
      childContent = (
        <div className="radar-page-shape-filter draw-icon-filter">
          { isDrawFilter &&
            <MapControls>
              <RadarPolygonFilterButton />
              <RadarDrawPolygonControl map={this.map}
                onDrawUpdate={this.onDrawUpdate}
                toggleShapeIdsFilter={toggleShapeIdsFilter}
                selectedShapeIds={selectedShapeIds}>
              </RadarDrawPolygonControl>
            </MapControls>
          }
        <ShapeFilterPartial
          shapeTileApi={shapeTileApi}
          toggleShapeIdsFilter={toggleShapeIdsFilter}
          selectedShapeEntry={selectedShapeEntry}
          selectedShapeIds={selectedShapeIds}
          map={this.map}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          onMapStylesAreLoaded={onMapStylesAreLoaded}
          isRadarDrawingEnabled = {isRadarDrawingEnabled} />
        </div>
      );
    }

    return (
      <div className="shape-filter">
        <div className="map-instance" ref={el => this.mapElement = el} ></div>
        {childContent}
        {this.renderRecenterButton()}
      </div>
    );
  }
}

ShapeFilter.propTypes = {
  currentDrilldownTemplateId: PropTypes.string,
  currentMapStyleEntry: PropTypes.object,
  apiParams: PropTypes.object,
  selectedShapeEntry: PropTypes.object,
  selectedShapeIds: PropTypes.array,
  toggleShapeIdsFilter: PropTypes.func,
  shapeTileApi: PropTypes.func,
  onMapStylesAreLoaded: PropTypes.func,
  showRecenterButton: PropTypes.bool,
  isDrawFilter: PropTypes.bool,
  isRadarDrawingEnabled: PropTypes.bool
};

ShapeFilter.defaultProps = {
  onMapStylesAreLoaded: _.noop,
  showRecenterButton: false
};

export default (ShapeFilter);
