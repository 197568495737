import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { getMargins } from 'modules/PlotlyTooltip/helper';
import {
  isStackTypeChart,
  isParallelOrBulletChart
} from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
import { getFlyoutPosition } from './helper';
import {
  getAbbreviatedStringValue,
  formatValueToCurrency,
  getThousandSeparatedValueWithFraction
} from 'helpers/numberHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { getTickText } from 'helpers/displayNameHelper';
import { SLA_CALCULATION_TYPES, STACK_CHART_ADD_TEXT } from 'appConstants';
import { getSLAWatchCalculationType } from 'common/config/customerConfiguration';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { getMillisecondsAsDateTime } from 'helpers/visualizationHelper';
import { ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import ColorBox from './ColorBox';
import BarChartFlyoutContent from './BarChartFlyoutContent';
import {
  getBetweenYearRange
} from 'modules/visualization/SnapshotVisualization/vizOptionsHelper';
export const getBarChartPopupConfigs = (options) => {
  const { data, secondaryMetricEntry, isSlaWatch, isRadarBarChart, viewEntry } = options;
  const vizOptions = { secondaryMetricEntry };
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const flyoutOptions = _.merge({}, options, { isSecondsFormat: isSecondsFormat })

  let benchMarkData = _.filter(data.points, (point) => {
    return _.get(point, 'data.customdata[0][0]') === 'benchMark';
  });

  let benchMarkTooltipData;
  if (!_.isEmpty(benchMarkData)) {
    const xPointValue = _.get(data, 'xvals[0]');
    _.forEach(benchMarkData, (benchData) => {
      const xBenchValue = isSecondsFormat ? getMillisecondsAsDateTime(benchData.x) : benchData.x;
      if (xPointValue <= xBenchValue) {
        benchData['nearXpoint'] = xBenchValue - xPointValue;
      } else if (xPointValue > xBenchValue) {
        benchData['nearXpoint'] = xPointValue - xBenchValue;
      }
    });

    const benchmark = _.minBy(benchMarkData, function (benchmark) { return benchmark.nearXpoint; });
    benchMarkTooltipData = _.filter(benchMarkData, (point) => {
      return _.get(point, 'data.customdata[0][1]') === _.get(benchmark, 'data.customdata[0][1]');
    });
  }
  if (isSlaWatch) {
    return getSlaWatchPopupConfigs(flyoutOptions);
  } else if (isRadarBarChart) {
    return getRadarBarPopupConfigs(flyoutOptions);
  } else if (!_.isEmpty(benchMarkTooltipData)) {
    return getBenchMarkPopupConfigs(flyoutOptions, benchMarkTooltipData);
  } else if (isStackTypeChart(vizOptions)) {
    return getStackPopupConfigs(flyoutOptions);
  } else if (isParallelOrBulletChart(vizOptions)) {
    return getParallelOrBulletPopupConfigs(flyoutOptions);
  } else {
    return getBarPopupConfigs(flyoutOptions);
  }
};

const getStackPopupConfigs = (options) => {
  const { chartContainer, data, viewMode, viewEntry, templateId } = options;
  const flyoutPosition = getFlyoutPosition(data, viewMode);
  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const title = _.get(point, 'y');
  const splitTitle = _.first(_.split(_.get(point, 'y'), STACK_CHART_ADD_TEXT));
  let xValue = 0;
  const xaxis = point.xaxis;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const barData = _.chain(data.points).
    map(point => _.get(point, 'data.meta')).
    flatten().
    filter({ value: title }).
    sortBy((entry) => {
      return _.lowerCase(_.get(entry, 'view', ''));
    }).
    value();
  // TODO:

  const tbodyContent = _.map(barData, (datum, index) => {
    const defaultColor = _.get(datum, 'color', '#fff');
    const color = _.get(datum, 'color', defaultColor);
    const labelField = isSecondsFormat ? 'actualLabel' : 'label';
    const viewCount = getFormattedValue(_.get(datum, labelField, ''), viewEntry);

    xValue += isSecondsFormat ? xaxis.d2p(datum.label) : xaxis.l2p(datum.label);
    if (!datum.text || datum.value === " ") { return null; }

    return (
      <tr key={index}>
        <td>
          <ColorBox boxColor={color} />
        </td>
        <td>
          <div className="bar-chart-category font-weight-bold">{_.get(datum, 'view', '')}</div>
        </td>
        <td className="align-top">
          <div className="float-right">{viewCount}</div>
        </td>
      </tr>
    );
  });
  const labelSumField = 'actualLabel';
  const subTotalText = getFormattedValue(_.sumBy(barData, labelSumField), viewEntry)
  const categoryContent = getCategoryHeaderContent(point, splitTitle, templateId, subTotalText, options);
  if (_.isEmpty(_.without(tbodyContent, null))) {
    return [];
  }

  const chartElement = _.first(document.getElementsByClassName("plot-container plotly"))
  const XAxisLimit = _.get(chartElement, "offsetWidth", 1500) - 50;
  let xPointValue = xValue + margins.x;
  return [{
    className: 'bar-chart-flyout',
    viewMode,
    position: flyoutPosition,
    anchor: {
      x: xPointValue > XAxisLimit ? XAxisLimit : xPointValue,
      y: point.yaxis.d2p(point.y) + margins.y
    },
    chartContainer,
    html: (
      <div>
        <table className="flyout-table">
          <tbody>
            {categoryContent}
            {_.without(tbodyContent, null)}
          </tbody>
        </table>
      </div>
    )
  }];
}

const getCategoryHeaderContent = (point, title, templateId, subTotalText = null, options) => {
  const { isCurrencyGroupByField, isCurrencyDimensionField } = options;
  const customdata = _.get(point, 'customdata', []);
  const nullValueLabel = getNullValueLabel(templateId);
  let renderSubtotalText;
  const colSpanValue = _.isEmpty(subTotalText) ? "3" : "2";
  const formateTitle = _.first(getTickText([title], 35, true));

  if (!_.isEmpty(subTotalText)) {
    renderSubtotalText = (<span className="float-right font-weight-normal"> {subTotalText}</span>)
  }

  if (_.isEmpty(customdata)) {
    return (
      <>
        <tr key="head-flyout">
          <th colSpan={colSpanValue}>
            <span
              dangerouslySetInnerHTML={{
                __html: formatValueToCurrency(formateTitle, isCurrencyDimensionField)
              }}>
            </span></th>
          {!_.isEmpty(subTotalText) && <th>{renderSubtotalText}</th>}
        </tr>
        <tr>
          <th colSpan={colSpanValue + 1}>
            <ForgeDivider className="mt-1 mb-1" />
          </th>
        </tr>
      </>);
  } else {
    const lastIndex = _.size(customdata) - 1;
    return _.map(customdata, (data, index) => {
      const isLastIndex = lastIndex === index;
      const isCurrencyType = isLastIndex ? isCurrencyDimensionField : isCurrencyGroupByField;
      let titleText = formatValueToCurrency((data || nullValueLabel), isCurrencyType);
      titleText = _.first(getTickText([titleText], 35, true));
      return (<>
        <tr key={index + "head-flyout"}>
          <th colSpan={colSpanValue}>
            <div class="d-flex">
              {isLastIndex &&
                <ForgeIcon className="sub-tooltip-icon" name="subdirectory_arrow_right" /> }
              <span className='mt-1' dangerouslySetInnerHTML={{ __html: titleText }}></span>
            </div>
          </th>
          {!_.isEmpty(subTotalText) &&
            <th>{isLastIndex ? renderSubtotalText : ''}</th>
          }
        </tr>
        {isLastIndex && <tr>
          <th colSpan={colSpanValue + 1}>
            <ForgeDivider className="mt-1 mb-1" />
          </th>
        </tr> }
      </>
      );
    });
  }
}

const getParallelOrBulletPopupConfigs = (options) => {
  const { chartContainer, data, viewMode,
    isCurrencyDimensionField, groupByEntry, isSecondsFormat, isComparisonEnabled, commonFilters } = options;
  const { comparisonDateRanges } = commonFilters;
  const isGroupByNone = groupByEntry['name'] == 'None';
  const flyoutPosition = getFlyoutPosition(data, viewMode);
  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const title = _.get(point, 'y');
  const xValue = isSecondsFormat ? point.xaxis.d2p(point.x) : point.xaxis.l2p(point.x);
  const yearsWithLabelRange = getBetweenYearRange({commonFilters, compareYearRanges: comparisonDateRanges});
  // TODO: extract new function use stack and parallel chart.
  const dataPoints = isComparisonEnabled ?
  [_.get(data.points, '[0]'), ..._.reverse(_.tail(data.points))] : data.points;

  const tbodyContent = _.map(dataPoints, (point, index) => {
    const defaultColor = _.get(point, 'data.marker.color', '#fff');
    const legendText = _.get(point, 'data.legendgroup', '');
    const color = _.get(point, 'marker.color', defaultColor);
    const dateString = _.get(point, 'meta.period', '');
    const yearString = moment(dateString).format('YYYY');
    const yearLabelObject = _.find(yearsWithLabelRange, { year: Number(yearString) });

    const yearLabel = _.isEmpty(yearLabelObject) ?
      moment(dateString).format('YYYY') : yearLabelObject['label'];
    const year = isComparisonEnabled && !_.isEmpty(dateString) ? yearLabel : '';
    if (!point.text || point.y === " ") { return null; }

    return (
      <tr key={index}>
        <td>
          <ColorBox boxColor={color} />
        </td>
        {(isComparisonEnabled && year) && <td><span className='font-weight-bold'>{year}</span></td>}
        {!isComparisonEnabled && <td><span className='font-weight-bold'>{legendText}</span></td>}
        <td><div className="line-chart-category">{getHoverText(point)}</div></td>
      </tr>
    );
  });
  const categoryContent = getCategoryContent(point, title, options);
  if (_.isEmpty(_.without(tbodyContent, null))) {
    return [];
  }

  const isGroupByNoneFlag = (isGroupByNone || _.isEmpty(groupByEntry));
  let yPosition = 0;

  if ((isCurrencyDimensionField && isGroupByNoneFlag) || (isGroupByNoneFlag && !isComparisonEnabled)) {
    yPosition = point.yaxis.d2p(point.pointIndex);
  } else {
    yPosition = point.yaxis.d2p(point.y);
  }

  return [{
    className: 'bar-chart-flyout',
    viewMode,
    position: flyoutPosition,
    anchor: {
      x: xValue + margins.x,
      y: yPosition + margins.y
    },
    chartContainer,
    html: (
      <div>
        {categoryContent}
        <table className="flyout-table">
          <tbody>{_.without(tbodyContent, null)}</tbody>
        </table>
      </div>
    )
  }];
}

const getBarPopupConfigs = (options) => {
  const {
    chartContainer,
    data,
    isDistributionChart,
    viewMode,
    isCurrencyDimensionField,
    groupByEntry,
    isSecondsFormat
  } = options;
  const isGroupByNone = groupByEntry['name'] == 'None';
  const flyoutPosition = getFlyoutPosition(data, viewMode);
  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const title = isDistributionChart ? _.get(point, 'x') : _.get(point, 'y');
  const xValue = (isDistributionChart || isSecondsFormat) ?
    point.xaxis.d2p(point.x) : point.xaxis.l2p(point.x);
  const x = isDistributionChart ? (xValue + margins.x) : (xValue + margins.x);

  const tbodyContent = _.map(data.points, (point, index) => {
    const defaultColor = _.get(point, 'data.marker.color', '#fff');
    const color = _.get(point, 'marker.color', defaultColor);
    if (!point.text || point.y === " ") { return null; }
    const colorContent = isDistributionChart ? <ColorBox boxColor={color} /> : '';

    return (
      <tr key={index}>
        {!_.isEmpty(colorContent) && <td>{colorContent}</td>}
        <td><div className="line-chart-category">{getHoverText(point)}</div></td>
      </tr>
    );
  });
  const categoryContent = getCategoryContent(point, title, options);
  if (_.isEmpty(_.without(tbodyContent, null))) {
    return [];
  }
  const isGroupByNoneFlag = (isGroupByNone || _.isEmpty(groupByEntry));
  let yPosition = 0;

  if ((isCurrencyDimensionField && isGroupByNoneFlag) || isGroupByNoneFlag) {
    yPosition = point.yaxis.d2p(point.pointIndex);
  } else {
    yPosition = point.yaxis.d2p(point.y);
  }

  return [{
    className: 'bar-chart-flyout',
    viewMode,
    position: flyoutPosition,
    anchor: {
      x: x,
      y: yPosition + margins.y
    },
    chartContainer,
    html: (
      <div>
        {categoryContent}
        <table className="flyout-table">
          <tbody>{_.without(tbodyContent, null)}</tbody>
        </table>
      </div>
    )
  }];
}

const getRadarBarPopupConfigs = (options) => {
  const { chartContainer, data, viewMode, isSelectedTracts, isComparisonTracts } = options;
  const flyoutPosition = getFlyoutPosition(data, viewMode);
  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const labelHtml = _.get(point, 'label')

  var div = document.createElement("div");
  div.innerHTML = labelHtml;
  const title = div.innerText;

  const xValue = point.xaxis.d2p(point.x);
  const x = (xValue + margins.x);


  const tbodyContent = _.map(data.points, (point, index) => {

    const templateMetricSettings = point.data.templateMetricSettings;
    let value = Number(point.value).toFixed(templateMetricSettings.precision);
    const singularSuffix = templateMetricSettings.singular_suffix;
    const pluralSuffix = templateMetricSettings.plural_suffix;
    let suffix = (value == 1) ? singularSuffix : pluralSuffix;
    suffix = (templateMetricSettings.showSuffixInFlyout == 'true') ? suffix : '';

    value = getThousandSeparatedValueWithFraction(value);

    if (_.isUndefined(title)) { return null; }
    if (title.trim() == 'Selected Area' && !isSelectedTracts) { return null; }
    if (title.trim() == 'Comparison Area' && !isComparisonTracts) { return null; }
    return (
      <tr key={index}>
        <td>
          <div className='radar-legend-color'
            style={{ backgroundColor: point.data.marker.color }}></div>
        </td>
        <td>
          <div className="line-chart-category">
            {`${templateMetricSettings.prefix}${value} ${suffix}`}
          </div>
        </td>
        <td>
          <div className="line-chart-category">{point.data.name}</div>
        </td>
      </tr>
    );
  });
  const categoryContent = getCategoryContent(point, title, options);
  if (_.isEmpty(_.without(tbodyContent, null))) {
    return [];
  }
  let yPosition = yPosition = point.yaxis.d2p(point.y);
  return [{
    className: 'bar-chart-flyout',
    viewMode,
    position: flyoutPosition,
    anchor: {
      x: x,
      y: yPosition + margins.y
    },
    chartContainer,
    html: (
      <div>
        {categoryContent}
        <table className="flyout-table">
          <tbody>{_.without(tbodyContent, null)}</tbody>
        </table>
      </div>
    )
  }];
}

const getSlaWatchPopupConfigs = (options) => {
  const {
    chartContainer, data
  } = options;

  const flyoutPosition = getFlyoutPosition(data, 'large');
  const margins = getMargins(chartContainer);
  const point = data.points[0];
  const xValue = point.xaxis.l2p(point.x);
  const x = (xValue + margins.x);

  const tbodyContent = _.map(data.points, (point, index) => {
    const { meta } = point;
    if (!point.text || point.y === " ") { return null; }
    if (
      _.isEmpty(meta.flyoutEntries) &&
      getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.CUMULATIVE_PERCENTAGE
    ) {
      return null;
    }

    if (getSLAWatchCalculationType() == SLA_CALCULATION_TYPES.CUMULATIVE_PERCENTAGE) {
      return (_.map(point.meta.flyoutEntries, (entry, index) => {
        return (
          <tr key={index}>
            <td><b>{entry['name']} </b> <span>{entry['value']}</span></td>
          </tr>
        );
      })
      )
    }

    return (
      <tr key={index}>
        <td><div className="line-chart-category">{getHoverText(point)}</div></td>
      </tr>
    );
  });
  const titleContent = getSlaWatchTitle(point);
  if (_.isEmpty(_.without(tbodyContent, null))) {
    return [];
  }
  let yPosition = point.yaxis.d2p(point.y);

  return [{
    viewMode: 'large',
    position: flyoutPosition,
    anchor: {
      x: x,
      y: yPosition + margins.y
    },
    chartContainer,
    html: (
      <div>
        {titleContent}
        <table className="flyout-table">
          <tbody>{_.without(tbodyContent, null)}</tbody>
        </table>
      </div>
    )
  }];
}

const getBenchMarkPopupConfigs = (options, benchMarkData) => {
  const { chartContainer, viewMode, viewEntry } = options;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const margins = getMargins(chartContainer);
  return _.map(benchMarkData, (point, index) => {
    let xValue = getAbbreviatedStringValue(point.x);
    if (isSecondsFormat) {

      const momentDate = new moment(point.x);
      xValue = momentDate.format("HH:mm:ss");
    }
    const benchMarkValue = _.get(point, 'data.customdata[0][1]', '');

    return {
      viewMode,
      position: (index === 0) ? 'left' : 'right',
      anchor: {
        x: point.xaxis.d2p(point.x) + margins.x,
        y: point.yaxis.d2p(point.y) + margins.y
      },
      chartContainer,
      html: (<div><b>{benchMarkValue}</b>: {xValue}</div>)
    };
  });
}

const getCategoryContent = (point, title,
  { templateId, isCurrencyGroupByField, isCurrencyDimensionField }) => {
  const customdata = _.get(point, 'customdata', []);
  const nullValueLabel = getNullValueLabel(templateId);

  if (_.isEmpty(customdata)) {
    return (<div className="bar-chart-y-value text-nowrap">
      <b>{formatValueToCurrency(title, isCurrencyDimensionField)}</b>
      <ForgeDivider className="mb-2 mt-1" />
    </div>
    );
  } else {
    const lastIndex = _.size(customdata) - 1;
    return _.map(customdata, (data, index) => {
      const isLastIndex = lastIndex === index;
      const isCurrencyField = isLastIndex ? isCurrencyDimensionField : isCurrencyGroupByField;

      return (
        <BarChartFlyoutContent index={index} data={data} nullValueLabel={nullValueLabel}
          isCurrencyField={isCurrencyField} isLastIndex={isLastIndex} />);
    });
  }
}

const getSlaWatchTitle = (point) => {
  const customdata = _.get(point, 'customdata', []);

  return (
    <div className="bar-chart-y-value text-nowrap">
      <b>{customdata[0]}</b>: <b>{customdata[1]}</b>
      <ForgeDivider className="mb-2" />
    </div>
  )
}

const getHoverText = (point) => {
  const { isSecondaryBar, hoverText, secondaryHoverText } = _.get(point, 'meta', {});
  return isSecondaryBar ? secondaryHoverText : hoverText;
}
