
import React from 'react';

function CanvasCardBody() {
  return (
    <div className="canvas-card-body d-flex align-items-center justify-content-center h-100">
      <div className='canvas-card-title'>Map Canvas</div>
    </div>);
}

export default CanvasCardBody;
