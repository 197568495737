import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LineChartVisualization from './visualizationRenders/LineChartVisualization';
import TableVisualization from './visualizationRenders/TableVisualization';
import SnapshotRenderer from './visualizationRenders/SnapshotRenderer';
import MapVisualization from './visualizationRenders/MapVisualization';
import DistributionVisualization from './visualizationRenders/DistributionVisualization';

import {
  VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  DRILLDOWN_VIEW_TYPE,
  GROUP_BY_NONE_ENTRY,
  OVERTIME_VISUALIZATION_TYPES,
  CURRENCY_TYPE,
  COMPARE_VIEW_DRILLDOWN_OPTIONS
} from 'appConstants';
import { getSelectedDimensionEntry } from 'helpers/templateHelper';
import { isSkipHierarchyEnabled } from 'common/config/customerConfiguration';
import {
  getNullValueLabel,
  getAvailableDrilldownEntries,
  getNextDrilldownDimensionField
} from 'common/config/templateConfiguration';
import { disableTableVisualization } from 'modules/DetailsTable/TableHelper';
import {
  setDrilldownDimensions,
  updateDrilldownDimensionField,
  updateQuickFilters,
  setCurrentVisualizationType,
  updateDrilldownGroupByEntry
} from 'actions/drilldownActions';
import { updateGroupBychartApproach, updateGroupBychartViewType } from 'actions/snapshotActions';
import { updateShowTableViewOnDrilldown,updateShowLeafPage } from 'actions/tableActions';
import { updateMetricTotals } from 'actions/metricTotalActions';
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';

import GlobalEvent from 'common/components/GlobalEvents';
import { getFilterObject,
  getFilterCommonObject,
  getAvailableVisualizationType,
  getNewFilters,
  getCurrentVizBasedChartType,
  getCurrentSecondaryMetricFields
} from 'helpers/visualizationHelper';
import { getDistributionBucketEntry } from 'common/config/visualizationConfiguration';
import { getGroupDrilldownDimensionField } from 'pages/drilldown/components/QuickFilterBar/helper';
import { OTHER_BUCKET_ID } from 'modules/visualization/constants';
import { getMetricTotalData } from 'common/api/metricTotal';
import { getApiParams } from 'helpers/apiParamsHelper';
import * as commonPropTypes from "common/propTypes";
import TimeOfDayVisualization from "./visualizationRenders/TimeOfDayVisualization";

class Visualization extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.abortFetchController = new AbortController();

    this.state = {
      visualizationData: [],
      isLoading: false,
      currentPage: 0,
      totalRowCount: 0
    }
  }

  static propTypes = {
    currentVisualizationType: PropTypes.string,
    templateEntries: commonPropTypes.templateEntriesPropTypes,
    drilldown: commonPropTypes.drilldownPropTypes,
    drilledDownDimensions: PropTypes.array,
    configurations: PropTypes.object,
    isLeafPage: PropTypes.bool,
    currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
    currentDrilldownDimensionField: PropTypes.string,
    currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
    quickFilters: commonPropTypes.quickFiltersPropTypes,
    currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
    currentSnapshotView: PropTypes.string,
    apiParams: PropTypes.object,
    dispatchDrillDownAndQuickFilters: PropTypes.func,
    dispatchUpdateShowTableViewOnDrilldown: PropTypes.func,
    dispatchUpdateShowLeafPage: PropTypes.func,
    dispatchSetCurrentVisualizationType: PropTypes.func,
    isDrilledDownToNextLevel: PropTypes.bool,
    onMapLoaded: PropTypes.func,
    onChartDataLoaded: PropTypes.func,
    currentSecondaryMetricField: PropTypes.string,
    dispatchUpdateMetricTotals: PropTypes.func,
    commonFilters: PropTypes.object,
    currentChartView: PropTypes.string,
    currentVizBasedChartType: PropTypes.string,
    dispatchUpdateDrilldownGroupEntry: PropTypes.func,
    dispatchUpdateGroupByChartViewType: PropTypes.func,
    isSideBar: PropTypes.bool,
  };

  static defaultProps = {
  };

  componentDidMount() {
    this.fetchMetricTotal();
  }

  componentWillUnmount(){
    this.abortFetchController.abort();
    GlobalEvent.off('DIMENSION_COUNT', this.setTotalRowCount);
  }

  componentDidUpdate(prevProps) {
    const {
      currentVisualizationType,
      drilledDownDimensions,
      currentDrilldownDimensionField,
      currentSecondaryMetricField,
      apiParams,
      currentChartView
    } = this.props;
    const isDrilledDownDimensionsChanged = !_.isEqual(
      drilledDownDimensions, prevProps.drilledDownDimensions);
    const isCurrentVisualizationTypeChanged = !_.isEqual(
      prevProps.currentVisualizationType, currentVisualizationType);
    const isApiParamsChanged = !_.isEqual(prevProps.apiParams, apiParams);

    const isDrilldownDimensionFieldChanged = !_.isEqual(
      currentDrilldownDimensionField, prevProps.currentDrilldownDimensionField);
    const isSecondaryMetricChanged = !_.isEqual(
      prevProps.currentSecondaryMetricField, currentSecondaryMetricField
    );
    const isCurrentChartViewChanged = !_.isEqual(
      prevProps.currentChartView, currentChartView
    );

    if (isCurrentVisualizationTypeChanged ||
        isApiParamsChanged ||
        isSecondaryMetricChanged ||
        isCurrentChartViewChanged) {
      this.fetchMetricTotal();
    }

    if(isDrilledDownDimensionsChanged || isCurrentVisualizationTypeChanged || isApiParamsChanged) {
      this.setState({
        currentPage: 0
      });
      this.getSelectedDimensionCount();
    } else if(isDrilldownDimensionFieldChanged) {
      this.getSelectedDimensionCount();
    }

    if(!_.isEqual(this.props.isSideBar, prevProps.isSideBar)){
      this.resizeWindowHandler();
    }
  }

  fetchMetricTotal = () => {
    const {
      apiParams, currentDrilldownViewEntry, dispatchUpdateMetricTotals, currentVisualizationType,
      currentSecondaryMetricField, commonFilters, currentChartView, currentVizBasedChartType
    } = this.props;
    const currentSecondaryMetricFields = getCurrentSecondaryMetricFields(
      currentDrilldownViewEntry,
      currentChartView,
      currentSecondaryMetricField,
      currentVisualizationType,
      commonFilters,
      currentVizBasedChartType
    );
    const showComparisonMode = _.get(commonFilters, 'comparisonModeOn', false);
    const dateRangeMode = _.get(commonFilters, 'dateType');
    const comparisonDateRanges = showComparisonMode ? getComparisonPeriodDateRanges(commonFilters): [];
    dispatchUpdateMetricTotals([], true);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    getMetricTotalData({
      queryParams: apiParams,
      viewEntry: currentDrilldownViewEntry,
      secondaryMetrics: currentSecondaryMetricFields,
      isComparison: showComparisonMode,
      dateRangeMode: dateRangeMode,
      comparisonDateRanges: comparisonDateRanges
    }, this.abortFetchController).then((response) => {
      const isError = _.get(response, 'isError', false);
      if(!_.isUndefined(response) && !isError) {
        dispatchUpdateMetricTotals(response, false);
      }
    }).catch((error) => {
      if (error.name !== 'AbortError') {
        dispatchUpdateMetricTotals([], false);
        console.log("Error on fetching Metric Total", error);
      }
    });
  }

  // Todo: if we are not using this function in other visualization components,
  // then move this function to BarChartVisualization component.
  onBarChartClick = (dimensionValue, groupByValue, isSeeMoreGroupDimension = false ) => {
    const {
      currentDrilldownTemplateId,
      drilledDownDimensions,
      currentDrilldownDimensionField,
      currentDrilldownGroupByEntry,
      dispatchDrillDownAndQuickFilters,
      currentDrilldownViewEntry,
      currentVisualizationType,
      dispatchSetCurrentVisualizationType,
      currentSnapshotView
    } = this.props;
    let { quickFilters } = _.cloneDeep(this.props);
    quickFilters = _.isEmpty(quickFilters) ? [] : quickFilters;
    const nullValueLabel = getNullValueLabel(currentDrilldownTemplateId);
    let dimensionIndex;
    let newDimensionField;
    const isEmptyValue = (value) => _.includes([nullValueLabel, ''], value);
    dimensionValue = isEmptyValue(dimensionValue) ? null : dimensionValue;
    groupByValue = isEmptyValue(groupByValue) ? null : groupByValue;

    const currentDrilldownGroupByColumn = _.get(currentDrilldownGroupByEntry, 'column', '');
    const currentDrilldownGroupColumnType = _.get(currentDrilldownGroupByEntry, 'renderType');

    const currentDrilldownDimension = getSelectedDimensionEntry(
      currentDrilldownTemplateId, currentDrilldownDimensionField);
    const currentDrilldownColumnType = _.get(currentDrilldownDimension, 'renderType');
    const isCurrencyGroupByField = (currentDrilldownGroupColumnType === CURRENCY_TYPE);
    const isCurrencyDimensionField = (currentDrilldownColumnType === CURRENCY_TYPE);
    if (isCurrencyDimensionField) {
      dimensionValue = _.get(dimensionValue.split('$'), '1', dimensionValue);
    }

    if (isCurrencyGroupByField) {
      groupByValue = _.get(groupByValue.split('$'), '1', groupByValue);
    }
    const isPieChart = currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type;
    const isDimensionRange = _.get(currentDrilldownDimension, 'renderAxis') === 'range' && !isPieChart;
    const isGroupByRange = _.get(currentDrilldownGroupByEntry, 'renderAxis') === 'range';

    const isNumberColumn = (currentDrilldownColumnType === 'number' &&
      (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type ||
      currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type)
    );
    const isNumberGroupColumn = (currentDrilldownGroupColumnType === 'number' &&
      (currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type ||
       currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type)
    );

    const availableDrilldownEntries = getAvailableDrilldownEntries({
      currentDrilldownTemplateId,
      drilledDownDimensions,
      currentDrilldownDimensionField,
      currentDrilldownGroupByColumn
    });

    dimensionIndex = drilledDownDimensions.length;
    if (!_.isEmpty(availableDrilldownEntries)){
      newDimensionField = getNextDrilldownDimensionField(
        currentDrilldownTemplateId, drilledDownDimensions, currentDrilldownViewEntry,
        [currentDrilldownDimensionField], currentDrilldownGroupByEntry)
      // newDimensionField = _.first(availableDrilldownEntries).field;
    } else {
      if(!disableTableVisualization(currentDrilldownTemplateId)){
        dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.TABLE.type);
      } else {
        return;
      }
    }

    if (_.isEmpty(currentDrilldownDimensionField)){
      return;
    }

    if(!isSeeMoreGroupDimension) {
      const drillDownDimensionObject = getFilterObject(
        isNumberColumn && isDimensionRange,
        currentDrilldownDimension['column'],
        currentDrilldownDimensionField,
        dimensionValue,
        dimensionIndex,
        isDimensionRange
      );
      drilledDownDimensions.push(drillDownDimensionObject);
    }

    if(isSeeMoreGroupDimension){
      newDimensionField = currentDrilldownDimensionField;
      const defaultViewType = _.last(COMPARE_VIEW_DRILLDOWN_OPTIONS).name
      this.props.dispatchUpdateDrilldownGroupEntry(GROUP_BY_NONE_ENTRY);
      this.props.dispatchUpdateGroupByChartViewType(defaultViewType);
    }

    if(!_.isUndefined(groupByValue) && !_.isEmpty(currentDrilldownGroupByEntry.field)){
      const groupByFilterObject = getFilterObject(
        isNumberGroupColumn,
        currentDrilldownGroupByEntry['column'],
        _.get(currentDrilldownGroupByEntry, 'field'),
        groupByValue,
        null,
        isGroupByRange
      );

      quickFilters = getNewFilters(quickFilters, groupByFilterObject);
    }

    const renderType = _.get(currentDrilldownDimension, 'renderType');
    const isNumberColumnType = (renderType === 'number' && isPieChart);

    const groupByField = getGroupDrilldownDimensionField(
      currentDrilldownTemplateId, currentDrilldownDimensionField);
    if (!_.isUndefined(dimensionValue) && !_.isEmpty(groupByField)) {
      const dimensionByFilterObject = getFilterObject(
        isNumberColumn || isNumberColumnType,
        currentDrilldownDimension['column'],
        groupByField,
        dimensionValue,
        null,
        isDimensionRange
      );
      quickFilters = getNewFilters(quickFilters, dimensionByFilterObject);
    }
    const bucketEntry = getDistributionBucketEntry(currentDrilldownViewEntry);
    if(_.isEqual(VISUALIZATION_TYPES.DISTRIBUTION.type, currentVisualizationType) && _.isEmpty(bucketEntry)){
      dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.SNAPSHOT.type);
    }
    dispatchDrillDownAndQuickFilters(drilledDownDimensions, newDimensionField, quickFilters);
  }

  onDistributionChartClick = (bucketEntry) => {
    const {
      currentDrilldownTemplateId,
      currentDrilldownDimensionField,
      quickFilters,
      drilledDownDimensions,
      dispatchDrillDownAndQuickFilters,
      currentDrilldownViewEntry,
      dispatchSetCurrentVisualizationType
    } = this.props;
    const fieldName =  _.get(currentDrilldownViewEntry,'field');
    let newQuickFilters = _.reject(quickFilters, ['field', fieldName]);
    const newDrilledDownDimensions = _.reject(drilledDownDimensions, ['field', fieldName]);
    const currentDrilldownDimension = currentDrilldownViewEntry;
    const operator =  _.isEmpty(_.get(bucketEntry,'operator'))? 'between' : _.get(bucketEntry,'operator');
    const bucketFrom = _.get(bucketEntry,'from', 0);
    const bucketTo = _.get(bucketEntry,'to', 0);
    const dimensionIndex = drilledDownDimensions.length;
    let filterObject = getFilterCommonObject('number', operator, currentDrilldownDimension,
                                                     fieldName, bucketFrom, bucketTo, dimensionIndex);
    filterObject['isDistributionFilter'] = true;
    if (_.get(bucketEntry, 'id') === OTHER_BUCKET_ID) {
      filterObject['isOthersBucket'] = true;
    } else {
      filterObject['to_operator'] = _.get(bucketEntry,'to_operator');
    }
    newQuickFilters.push(filterObject);
    newDrilledDownDimensions.push(filterObject);

    if(!disableTableVisualization(currentDrilldownTemplateId)){
      dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.TABLE.type);
    }

    dispatchDrillDownAndQuickFilters(
      newDrilledDownDimensions,
      currentDrilldownDimensionField,
      newQuickFilters
    );
  }

  resizeWindowHandler() {
    window.dispatchEvent(new Event('resize'));
  }

  getSelectedDimensionCount = () => {
    GlobalEvent.on('DIMENSION_COUNT', this.setTotalRowCount);
  }

  setTotalRowCount = (dimensions) => {
    const { currentDrilldownDimensionField } = this.props;
    this.setState({totalRowCount: _.get(dimensions, `count_${currentDrilldownDimensionField}`, 0)});
  }

  renderVisualizationComponent() {
    const { totalRowCount } = this.state;
    const {
      currentVisualizationType,
      isDrilledDownToNextLevel,
      currentDrilldownViewEntry,
      currentDrilldownTemplateId,
      onMapLoaded,
      isSideBar
    } = this.props;
    const availableVizType = getAvailableVisualizationType(
      currentDrilldownTemplateId,
      currentVisualizationType,
      currentDrilldownViewEntry
    );
    const isTableVisualization = VISUALIZATION_TYPES.TABLE.type === availableVizType;
    const isOvertimeVisualization = VISUALIZATION_TYPES.OVERTIME.type === availableVizType;
    const isMapVisualization = VISUALIZATION_TYPES.MAP.type === availableVizType;
    const isDistributionVisualization = VISUALIZATION_TYPES.DISTRIBUTION.type === availableVizType;
    const isTimeOfDayVisualization = VISUALIZATION_TYPES.TIME_OF_DAY.type === availableVizType;
    const canSkipHierarchy = isSkipHierarchyEnabled() && isDrilledDownToNextLevel;

    if(isTableVisualization){
      return  <TableVisualization canSkipHierarchy={canSkipHierarchy} />;
    } else if (isOvertimeVisualization){
      return <LineChartVisualization dimensionRowCount={totalRowCount} />;
    } else if (isMapVisualization){
      return <MapVisualization onMapLoaded={onMapLoaded} isSideBar={isSideBar}  />;
    } else if (isDistributionVisualization){
      return <DistributionVisualization onBarClick={this.onDistributionChartClick}/>;
    } else if(isTimeOfDayVisualization) {
      return <TimeOfDayVisualization />;
    }
    else {
      // Default view is snapshot .
      return <SnapshotRenderer
              onBarClick={this.onBarChartClick}
              canSkipHierarchy={canSkipHierarchy}
              dimensionRowCount={totalRowCount}
              isSideBar={isSideBar} />;
    }
  }

  render() {
    return (
      <div className="visualization">
        {this.renderVisualizationComponent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);
  return {
    currentVisualizationType,
    apiParams: getApiParams(apiParamsOptions, {}),
    currentSecondaryMetricField: _.get(state,
      `visualization.${currentVisualizationType}.currentSecondaryMetricField`),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentVizBasedChartType: getCurrentVizBasedChartType(
      currentVisualizationType, _.get(state, 'visualization', {})),
    currentChartView:  _.get(state,
      'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    drilldown: _.get(state, 'drilldown', {}),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', [])),
    configurations: _.get(state, 'configurations'),
    isLeafPage: _.get(state, 'visualization.table.isLeafPage', false),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField', ''),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', ''),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {}),
    currentSnapshotView: _.get(state, 'visualization.snapshot.currentSnapshotView')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDrillDownAndQuickFilters: (dimensions, dimensionField, quickFilters ) => {
      dispatch(setDrilldownDimensions(dimensions));
      dispatch(updateDrilldownDimensionField(dimensionField));
      dispatch(updateQuickFilters(quickFilters));
    },
    dispatchUpdateShowTableViewOnDrilldown: (isTableView) => {
      dispatch(updateShowTableViewOnDrilldown(isTableView));
    },
    dispatchUpdateShowLeafPage: (isLeafPage) => {
      dispatch(updateShowLeafPage(isLeafPage));
    },
    dispatchSetCurrentVisualizationType: (visualizationType) => {
      dispatch(setCurrentVisualizationType(visualizationType))
    },
    dispatchUpdateDrilldownGroupEntry: (entry) => {
      dispatch(updateDrilldownGroupByEntry(entry));
      if(entry.name === 'None'){
        dispatch(updateGroupBychartApproach(_.get(_.first(DRILLDOWN_VIEW_TYPE),'type')));
      }
    },
    dispatchUpdateMetricTotals: (totals, isLoading) => {
      dispatch(updateMetricTotals(totals, isLoading));
    },
    dispatchUpdateGroupByChartViewType: (groupByViewType) => {
      dispatch(updateGroupBychartViewType(groupByViewType));
    }
  };
}

export default
  connect(mapStateToProps, mapDispatchToProps)(Visualization);
