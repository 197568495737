import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOvertimeChartOptions } from 'helpers/visualizationHelper';
import * as commonPropTypes from "common/propTypes";
import LineChartOptions from './LineChartOptions';
import UndoButton from 'pages/drilldown/visualizations/VisualizationOptions/UndoButton';

import { OVERTIME_VISUALIZATION_TYPES } from 'appConstants';
import MenuDropDownSelector from 'common/components/MenuDropDown/MenuDropDownSelector';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';
import { trackEvent } from 'helpers/eventTracking';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import MenuDropDownPopupSelector from 'common/components/MenuDropDown/MenuDropDownPopupSelector';
import {
  isOvertimeChartOptionsAreEmpty, shouldDisableSecondaryMetrics
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { isMobileOrTablet } from 'helpers/DomPageHelper';
import SecondaryMetricsSelection from
  'pages/drilldown/visualizations/VisualizationOptions/SecondaryMetricsSelection';
class OvertimeChartOptions extends Component {

  onChangeOvertimeChartType = (chart) => {
    const { handleChartViewChange } = this.props;
    handleChartViewChange(_.get(chart, 'detail.value'));
  }

  renderOvertimeChartTypes = () => {
    const { currentChartView, currentDrilldownViewEntry, hideForForecastingView } = this.props;
    const chartTypes = getOvertimeChartOptions(currentDrilldownViewEntry, hideForForecastingView);
    const chartType = _.find(chartTypes, { type: currentChartView });

    if (_.isEmpty(chartType) || chartTypes.length <= 1) {
      return;
    }

    let menuLabelName = '';
    let menuLabelIcon = '';
    const customChartTypes = _.map(chartTypes, (chartType) => {
      if (chartType.type == currentChartView) {
        menuLabelName = chartType.name;
        menuLabelIcon = chartType.icon;
      }

      return {
        value: chartType.type,
        label: chartType.name,
        leadingIconType: 'component',
        leadingIcon: chartType.type == currentChartView ? 'check' : 'forge-empty-icon'
      }
    });

    return (
      <ForgeMenuOptions
        customIconName={menuLabelIcon}
        menuLabelName={menuLabelName}
        menuOptions={customChartTypes}
        onSelectOption={this.onChangeOvertimeChartType} />
    )
  }

  onLegendButtonClick = () => {
    const { toggleClickLegendButton, isOpenLegend } = this.props;
    toggleClickLegendButton(!isOpenLegend);
  }

  renderButtonMobileView = () => {

    return (
      <ForgeButton type="outlined" className="map-forge-btn">
        <button
          className=""
          onKeyDown={() => this.onLegendButtonClick()}
          onClick={() => this.onLegendButtonClick()}>
          <ForgeIcon name="category" />
        </button>
      </ForgeButton>
    );
  }

  renderOvertimeChartLegend = () => {
    const { legend, toggleClickLegendButton, hideForForecastingView } = this.props;

    if(hideForForecastingView){
      return null
    }

    if (isMobileOrTablet()) {
      return this.renderButtonMobileView();
    }

    return (
      <MenuDropDownSelector toggleButtonLabel="Legend"  menuType="Legend"
        className="" leadingIconName="category" toggleClickLegendButton={toggleClickLegendButton}>
        {legend}
      </MenuDropDownSelector>
    );
  }

  renderOvertimeChartOptions = () => {
    const { currentChartView, hideForForecastingView } = this.props;

    if(currentChartView == OVERTIME_VISUALIZATION_TYPES.AREA.type ||
      isOvertimeChartOptionsAreEmpty(this.props) ||
      hideForForecastingView
    ){
      return null;
    }

    return (
      <MenuDropDownPopupSelector  key="overtime_options" id={"overtime_options"}
        toggleButtonLabel="Options"
        trailingIconName="keyboard_arrow_down">
        <LineChartOptions {...this.props} />
      </MenuDropDownPopupSelector>
    );
  }

  renderSecondaryMetricOptions() {
    const {
      currentDrilldownViewEntry,
      currentSecondaryMetricEntry,
      currentChartView,
      handleSecondaryMetricChange
    } = this.props;

    if(shouldDisableSecondaryMetrics(this.props)) {
      return null;
    }

    return(
      <div className='secondary-metrics-container'>
        <SecondaryMetricsSelection
          chartType='overtime'
          currentDrilldownViewEntry={currentDrilldownViewEntry}
          currentSecondaryMetricEntry={currentSecondaryMetricEntry}
          onSecondaryMetricChange={handleSecondaryMetricChange}
          currentChartView={currentChartView}
        />
      </div>
    );
  }

  handleKeyDownShowProjection = (e) => {
    const { onClickProjectionButton, isShowProjection } = this.props;
    if(isEnterButtonPressed(e)) {
      trackEvent('enabled-projection');
      if(!isShowProjection){
        onClickProjectionButton(!isShowProjection);
      }
    }
  }

  onClickProjectionButton = () => {
    const { onClickProjectionButton, isShowProjection } = this.props;
    trackEvent('enabled-projection');
    if(!isShowProjection){
      onClickProjectionButton(!isShowProjection);
    }
  }

  renderProjectionButton() {
    const { showProjectionButton, hideForForecastingView } = this.props;

    if(hideForForecastingView){
      return null
    }

    let projectionButtonContent = null;
    if (showProjectionButton) {
      projectionButtonContent = (
        <ForgeButton type="outlined" className="map-forge-btn">
          <button
            type="button"
            className=""
            onKeyDown={this.handleKeyDownShowProjection}
            onClick={this.onClickProjectionButton}>
            <span className="icons-forecast align-self-center mr-1" />
            <span>Projections</span>
          </button>
        </ForgeButton>
      );
    }

    return projectionButtonContent;
  }

  render() {
    const { isEmbed } = this.props;

    return (
      <div className="snapshot-options">
        <div className="rside-action">
          <UndoButton></UndoButton>
          {!isEmbed && this.renderOvertimeChartTypes()}
          {this.renderProjectionButton()}
          {this.renderSecondaryMetricOptions()}
          {this.renderOvertimeChartOptions()}
          {this.renderOvertimeChartLegend()}
        </div>
      </div>
    )
  }
}

OvertimeChartOptions.propTypes = {
  onAxisGranularityChange: PropTypes.func,
  toggleChartOptions: PropTypes.func,
  dateRange: commonPropTypes.dateRangePropTypes,
  dateRangeMode: PropTypes.string,
  currentSelectedTimeFrame: PropTypes.string,
  currentCompareYearRanges: PropTypes.array,
  templateId: commonPropTypes.templateIdPropTypes,
  axisGranularity: PropTypes.string,
  currentChartView: PropTypes.string,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  isComboChart: PropTypes.bool,
  handleChartViewChange: PropTypes.func,
  isEmbed: PropTypes.bool,
  legend: PropTypes.any,
  isShowProjection: PropTypes.bool,
  showProjectionButton: PropTypes.bool,
  onClickProjectionButton: PropTypes.func,
  toggleClickLegendButton: PropTypes.func,
  benchMarkNames: PropTypes.array,
  onBenchMarkChange: PropTypes.func,
  hideForForecastingView: PropTypes.bool,
  isOpenLegend: PropTypes.bool,
  currentSecondaryMetricEntry: PropTypes.object,
  handleSecondaryMetricChange: PropTypes.func,
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  return {
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false),
    viewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
    drilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    drilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    visualizationType: _.get(state, 'drilldown.currentVisualizationType')
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeChartOptions);
