import _ from 'lodash';
import React, { Component } from 'react';
import { toast } from 'react-toastify';

import LoadingSpinner from 'common/components/LoadingSpinner';
import ShareSection from './ShareSection';
import SubscriptionSettings from 'pages/dashboard/components/Collections/Subscription/SubscriptionSettings';
import { updateSubscriptionParamsforApi } from 'pages/dashboard/components/Collections/collectionApiHelper';
import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import { shareSubscription } from 'common/api/subscriptionApi';
import { trackEvent } from 'helpers/eventTracking';
import {
  subscriptionShareMessage,
  subscriptionShareErrorMessage,
  subscriptionWithCollectionShareMessage
} from 'helpers/toastMessages';
import { userPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import {
  isCollectionViewAccess
} from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
class SubscriptionPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isShareEnabled: false,
      isLoading: false,
      subscriptionOptions: {}
    };
  }

  componentDidUpdate(prevProps){
    const{ currentSubscription } = this.props;
    if(!_.isEqual(currentSubscription.id, _.get(prevProps, 'currentSubscription.id'))){
      this.setState({ subscriptionOptions: _.clone(currentSubscription) });
    }
  }

  handleUpdateSubscription = (newSubscription, isErrorInRecipientsSub) => {
    const { onUpdateSubscription, currentCollection } = this.props;

    const collectionSubscriptions = _.get(currentCollection, 'collection_subscriptions', []);
    const newSubscriptions = _.map(collectionSubscriptions, (subscriptionEntry) => {
      if (_.isEqual(subscriptionEntry.id, newSubscription.id)) {
        return newSubscription;
      } else {
        return subscriptionEntry;
      }
    });
    const newCollection = {
      ...currentCollection,
      collection_subscriptions: newSubscriptions
    };
    onUpdateSubscription(newCollection, newSubscription, isErrorInRecipientsSub);
  }

  handleSubscriptionShare = (options) => {
    const { currentSubscription, currentCollection, currentUser } = this.props;
    const shareEmails = _.get(options, 'shareEmails', []);
    const params = {
      shareEmails: shareEmails,
      collectionParams: {
        ...currentCollection,
        name: _.get(currentCollection, 'name')
      },
      subscriptionParams: currentSubscription
    };

    if (!_.isEmpty(currentUser)) {
      this.setState({ isLoading: true });
      trackEvent('manage_confirm_subscription_share');
      shareSubscription(_.get(currentCollection, 'id'), params)
        .then((response) => {
          if(response.ok){
            response.json().then((parsedResponse) => {
              const successMessage = parsedResponse.is_new_collection_created ?
                subscriptionWithCollectionShareMessage :
                subscriptionShareMessage;
              toast.success(successMessage);
              this.setState({
                isLoading: false,
                isShareEnabled: false
              });
            }).catch((err) => {
              toast.error(subscriptionShareErrorMessage);
              console.error(subscriptionShareErrorMessage, err);
              this.setState({ isLoading: false });
            });
          }else{
            toast.error(subscriptionShareErrorMessage);
          }
        })
    }
  }

  handleSubscriptionMute = () => {
    const { currentSubscription } = this.props;
    const currentMuteState = _.get(currentSubscription, 'send_email', true);
    const newSubscription = {
      ...currentSubscription,
      send_email: !currentMuteState
    };
    if(currentMuteState) {
      trackEvent('manage_initiate_subscription_mute');
    }

    this.handleUpdateSubscription(newSubscription);
  }

  handleShareCancel = () =>{
    this.setState({ isShareEnabled: false });
  }

  handleSubscriptionSettingsChange = (options) => {
    const { currentCollection, currentSubscription } = this.props;
    const newSubscription = {
      ...currentSubscription,
      ...updateSubscriptionParamsforApi(currentCollection.id, options),
      send_email: _.get(currentSubscription, 'send_email')
    }
    this.handleUpdateSubscription(newSubscription, options.isErrorInRecipients);
  }

  onShareClick = () => {
    trackEvent('manage_initiate_subscription_share');
    this.setState({ isShareEnabled: true });
  }

  renderMuteInfo(){
    const { currentSubscription } = this.props;
    const isMuted = !_.get(currentSubscription, 'send_email', true);
    if(!isMuted) {
      return null;
    }
    return(
      <div className="alert alert-info">
        <div className="font-weight-bold"> Subscription muted </div>
        <div>
          Scheduled communications for this subscription have been paused.
          Select Unmute to resume emails.
        </div>
      </div>
    )
  }

  renderSubscriptionOptions(){
    const { currentSubscription, currentUser, currentCollection, templateEntries, cardEntries } = this.props;
    const allowedUserRoles = _.get(currentCollection, 'allowed_roles', []);
    const isDisabled = (
      _.get(currentSubscription, 'is_collaborate_subscription', false) && !isManageCollections()
    )
    return(
      <div>
        <SubscriptionSettings
          isEditMode={true}
          isDisabled={isDisabled}
          currentUser={currentUser}
          currentSubscription={currentSubscription}
          currentCollection={currentCollection}
          templateEntries={templateEntries}
          allowedUserRoles={allowedUserRoles}
          cardEntries={cardEntries}
          onSettingsChange={this.handleSubscriptionSettingsChange} />
      </div>
    )
  }

  renderShareOptions(){
    const { isShareEnabled } = this.state;
    const { currentUser, currentCollection } = this.props;
    const allowedUserRoles = _.get(currentCollection, 'allowed_roles', []);
    if(!isShareEnabled){
      return
    }
    return <ShareSection
      currentUser={currentUser}
      isShareEnabled={isShareEnabled}
      allowedUserRoles={allowedUserRoles}
      onCancelShare={this.handleShareCancel}
      onSendClick={this.handleSubscriptionShare}
      shareType='collection'
    />;
  }

  renderSubscriptionActions(){
    const { onDelete, currentSubscription, currentCollection, isManageCollection } = this.props;
    const isUnMuted = _.get(currentSubscription, 'send_email', true);
    const { isShareEnabled } = this.state;
    const isCollectionViewer = isCollectionViewAccess(currentCollection);
    const isCollaborateSubscription = _.get(currentSubscription, 'is_collaborate_subscription', false);
    const showDelete = isCollaborateSubscription || !isCollectionViewer
    if(isShareEnabled){
      return null;
    }

    return(
      <>
      {!isManageCollection && !isCollaborateSubscription &&
        <>
          <button
          onClick={this.onShareClick}
          className="btn btn-outline-primary">
          Share
          </button>
        </>
      }
        <button
            onClick={this.handleSubscriptionMute}
            className="btn btn-outline-primary">
            {isUnMuted ?  'Mute' : 'Unmute'}
        </button>
        { showDelete &&
          <span>
            <DeleteConfirmModal
              title="Delete Subscription"
              classNames="btn btn-outline-primary"
              onClickDeleteButton={() => trackEvent('manage_initiate_subscription_delete')}
              message="Are you sure you want to delete this subscription? There is no undo."
              onDeleteConfirm={() => {
                trackEvent('manage_confirm_subscription_delete');
                onDelete(currentCollection, currentSubscription)
              }}
            />
          </span>
        }
      </>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {this.renderMuteInfo()}
        <LoadingSpinner isLoading={isLoading} />
        <div className="subscription-head rightside-page-head">
          <h1 className="mr-3">Subscription</h1>
          <div className="actions-btn">
            {this.renderSubscriptionActions()}
            {this.renderShareOptions()}
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-10">
            {this.renderSubscriptionOptions()}
          </div>
        </div>
      </>
    );
  }
}

SubscriptionPage.propTypes = {
  currentCollection: PropTypes.object,
  currentSubscription: PropTypes.object,
  onUpdateSubscription: PropTypes.func,
  onDelete: PropTypes.func,
  currentUser: userPropTypes,
  isManageCollection: PropTypes.bool,
  templateEntries: PropTypes.array,
  cardEntries: PropTypes.array
}


export default SubscriptionPage;
