import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';

class ConfirmationDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true
    };
  }

  componentDidMount() {
    if (this.confirmInput) {
      setTimeout(() => {
        this.confirmInput.focus();
      }, 100);
    }
  }

  handleClickConfirm = (event) => {
    const { onConfirmationCallBack } = this.props;
    this.setState({ showModal: false });
    event.preventDefault();
    event.stopPropagation();
    onConfirmationCallBack(true);
  };

  handleKeyDownConfirm = (e, isShow) => {
    if (isEnterButtonPressed(e)) {
      this.onCloseConfirm(e, isShow);
    }
  }

  handleKeyDownRemove = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleClickConfirm(e);
    }
  }

  onCloseConfirm(event, isShow) {
    const { onConfirmationCallBack } = this.props;
    event && event.stopPropagation();
    this.setState({ showModal: isShow });
    onConfirmationCallBack(false);
  }

  renderConfirmModal() {
    const { showModal } = this.state;
    const { messageTitle, message } = this.props;

    if (!showModal) {
      return
    }

    return (
      <>
        <ForgeDialog
          aria-labelledby="contained-modal-title-vcenter"
          onDismiss={(event) => this.onCloseConfirm(event, false)}
          open={true}
        >
          <header className="forge-dialog__header" forge-dialog-move-target="">
            <h2 className='forge-dialog__title'> {messageTitle} </h2>
          </header>
          <section className="forge-dialog__body ml-1" style={{ width: '500px' }}>
            {message}
          </section>
          <footer className="forge-dialog__footer">
            <ForgeButton type="flat" style={{ marginRight: '16px' }}>
              <button
                aria-label="Cancel"
                tabIndex={0}
                onClick={(event) => this.onCloseConfirm(event, false)}
                onKeyDown={(event) => this.handleKeyDownConfirm(event, false)}>Cancel
              </button>
            </ForgeButton>
            <ForgeButton type="raised">
              <button
                ref={ref => { this.confirmInput = ref; }}
                aria-label="Confirm"
                tabIndex={0}
                onClick={(event) => this.handleClickConfirm(event)}
                onKeyDown={this.handleKeyDownRemove}>Confirm
              </button>
            </ForgeButton>
          </footer>
        </ForgeDialog>
      </>
    )
  }

  render() {

    return (
      <>
        {this.renderConfirmModal()}
      </>
    )
  }
}

ConfirmationDialogue.propTypes = {
  onConfirmationCallBack: PropTypes.func,
  message: PropTypes.string,
  messageTitle: PropTypes.string
}

export default ConfirmationDialogue;
