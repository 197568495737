import React ,{ useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MapCanvasSidebar from './Sidebar/MapCanvasSidebar'
import MapCanvasMap from './MapVizualization/MapCanvasMap'
import {
  updateCurrentDateRangeOptions,
  updatePreviousDateRangeOptions,
  updateSelectedShapeDatasetEntry,
  updateSelectedShapeIds
} from 'actions/mapCanvasActions'
import { getPreviousYearDateRange } from 'pages/Radar/radarHelper'

export const MapCanvas = () => {
  const mapCanvasRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const mapCanvasState = useSelector(state => _.get(state, 'mapCanvas', {}));

  const getApiParams = () => {
    const {
      selectedShapeDatasetEntry,
      selectedTemplateIds,
      selectedShapeIds,
      currentDateRangeOptions,
      previousDateRangeOptions,
    } = mapCanvasState;

    const currentDateRange = _.get(currentDateRangeOptions, 'dateRange', {});
    const previousYearDateRange = getPreviousYearDateRange(previousDateRangeOptions, currentDateRange);
    return {
      ignore_view_entry: true,
      selectedTemplateIds: JSON.stringify(selectedTemplateIds),
      shapeGroupId: _.get(selectedShapeDatasetEntry, 'shape_dataset_id'),
      shapeIds: selectedShapeIds.join(','),
      commonFilters: JSON.stringify({ dateRange: currentDateRange }),
      dateRanges: JSON.stringify({
        current: currentDateRange,
        compare: previousYearDateRange
      }),
    }
  }

  const handleYearChange = (options, type) => {
    if (type == 'currentDateRangeOptions')
      dispatch(updateCurrentDateRangeOptions(options));
    else if (type == 'previousDateRangeOptions')
      dispatch(updatePreviousDateRangeOptions(options));
  }

  const handleShapeEntryChange = (selectedShapeEntry) => {
    dispatch(updateSelectedShapeDatasetEntry(selectedShapeEntry));
  }

  const handleSelectedShapeIdsChanges = (newShapeIds) => {
    dispatch(updateSelectedShapeIds(newShapeIds));
  }

  const renderLeftSideBar = () => {
    const sideBarOptions = _.pick(mapCanvasState,
      'currentDateRangeOptions',
      'previousDateRangeOptions',
      'selectedShapeDatasetEntry',
      'selectedShapeIds',
      'selectedTemplateIds'
    );
    return (
      <MapCanvasSidebar
        {...sideBarOptions}
        onYearChange={handleYearChange}
        onShapeEntryChange={handleShapeEntryChange}
        toggleSelectedShapeIds={handleSelectedShapeIdsChanges}
      />
    )
  }

  const renderCanvasMap = () => {
    return(
      <MapCanvasMap
        apiParams={getApiParams()} {...mapCanvasState}/>
    )
  }

  const onClickBackButton = () => {
    history.push('/overview');
  }

  return (
      <main role="main" className="map-canvas-page">
        <div className='map-canvas-container '>
          {/* Left sidebar */}
          {renderLeftSideBar()}

          {/* Right side  */}
          <div className='map-canvas-right-side' ref={mapCanvasRef}>
            <header className='map-canvas-page-top'>
              <div className='map-canvas-page-head'>
                <button aria-label="Back To Overview" className="btn back-arrow-icon back-btn-light"
                  onClick={onClickBackButton}>
                  <i className="icons-arrow-left2"></i>
                </button>
                <div className='page-title'>Map Explorer</div>
              </div>
            </header>
            <div className='map-canvas-right-side-body'>
              <div className="map-canvas-card map-canvas-map-container">
                {renderCanvasMap()}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
}

MapCanvas.propTypes = {
}

export default MapCanvas
