import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ForgeIcon, ForgeIconButton, ForgeMenu } from '@tylertech/forge-react';

import { isClickInfoSection } from 'helpers/DomPageHelper';
import { getForecastRangeText } from 'pages/dashboard/components/ForecastCard/forecastCardHelper';
import { isEllipsisActive, isEnterButtonPressed } from 'helpers/mouseEventsHelper';

import DeleteConfirmModal from 'pages/dashboard/components/DeleteConfirmModal';
import LoadingSpinner from 'common/components/LoadingSpinner';
import LineChart from 'modules/visualization/LineChart';
import BookmarkCreatedText from '../BookmarkCards/BookmarkCreatedText';

import { getForecastChartAttributes } from 'pages/Forecasting/ForecastHelper';
import { showForecastingBetaTag } from 'common/config/customerConfiguration';
import NewBadge from 'common/components/NewBadge';
const ForecastCard = (props) => {
  const {
    onCardDelete, viewEntry, templateEntry,
    userCardEntry, onCardClick,
    commonFilters, loading, isNewCard = false
  } = props;
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const forecastOptions = _.get(userCardEntry, 'forecastOptions', {});
  const name = _.get(userCardEntry, 'name', '');
  const description = _.get(userCardEntry, 'bookmarkOptions.description', '');
  const dataRangeText = useMemo(() => getForecastRangeText(forecastOptions), [forecastOptions]);
  // const history = useHistory();


  useEffect(() => {
    document.body.classList.add('forecast-card-body');

    return () => {
      document.body.classList.remove('forecast-card-body');
    };
  }, []);

  const handleCardDelete = () => {
    onCardDelete(userCardEntry);
  }

  const onKeyDownRadarCard = (e) => {
    if (isEnterButtonPressed(e)) {
      onClickCard(e);
    }
  }

  const handleClickMenuItems = () => {
    document.body.click();
  }


  const onClickCard = (e) => {
    if (isClickInfoSection(e)) {
      return;
    }
    if (_.isFunction(onCardClick)) {
      onCardClick(userCardEntry, e)
    }
  }

  const renderLineChart = () => {
    const lineChartAttributes = useMemo(() => getForecastChartAttributes({
      forecastOptions, viewEntry,
      templateId: _.get(templateEntry, 'template_id'),
      commonFilters
    }), [forecastOptions, viewEntry, templateEntry, commonFilters]);
    return (<LineChart {...lineChartAttributes} />);
  }

  const renderTooltipInfo = () => {
    if (!showForecastingBetaTag()) {
      return null
    }

    const message = `Introducing our new advanced forecasting experience,
      built specifically for government revenue forecasters!
      Please use the feedback button in the lower right corner to let us know how we can improve it,
      and what else you’d like to be able to forecast.`;

    const tooltip = (<Tooltip id="forecast-beta-tooltip">{message}</Tooltip>);
    const uniqueKey = 'forecast-beta-tooltip-key';

    return (
      <OverlayTrigger key={uniqueKey} placement="right" overlay={tooltip}>
        <span className="badge badge-beta">Beta</span>
      </OverlayTrigger>
    );
  }

  const renderMenus = () => {
    const options = [
      { value: 'delete', label: 'Delete forecast' }
    ];

    return (
      <div className="info-sections align-self-start ml-1">
        <ForgeMenu
          placement='bottom-start'
          options={options}
          on-forge-menu-select={() => setIsDeleteClicked(true)}>
          <ForgeIconButton type="button">
            <button type="button" aria-label="Card menu">
              <ForgeIcon name='more_vert' className="more-icon" /></button>
          </ForgeIconButton>
        </ForgeMenu>

        {/* <Dropdown show={showMenu}>
          <Dropdown.Toggle
            className="expand-btn btn-sm text-muted"
            id={'radar-card-custom' + userCardEntry.id}
            tabIndex={0}
            aria-label="Action"
            onSelect={() => setShowMenu(!showMenu)}
          >
            <i className="icons-ellipsis-v"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onSelect={() => setShowMenu(false)}
              className="card-remove" eventKey="2" as="ForecastDeleteButton">
              <DeleteConfirmModal
                title="Delete forecast"
                message={deleteMessage}
                showMenuItems={handleClickMenuItems}
                onDeleteConfirm={handleCardDelete}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    );
  }

  const renderCardBody = () => {
    const deleteMessage = `Are you sure you would like to delete ${name}? This cannot be undone.`;
    return (
      <div className='card-body d-flex flex-column h-100 forcasting-card forge-popup-host'>
        <div className='metric-head-wrapper'>
          <div className="metric-head d-flex">
            <img alt="ForecastImage" src='../images/data-points-spot.svg' className='forecast-img' />
            <div>
              <div className="metric-title forge-typography--subtitle1-secondary" >
                <div className='d-flex gap-4'>{name} {isNewCard && <NewBadge />}</div>
                {isEllipsisActive && <forge-tooltip
                  position="top"
                  target=".metric-title"
                  text={name}
                />}
              </div>

              <div className="metric-category forge-typography--caption">
                {dataRangeText}{renderTooltipInfo()}
              </div>
            </div>
          </div>
          {renderMenus()}
        </div>
        <div className="mb-auto">
          <div
            className="metric-card-visualization js-plotly-plot mt-2">
            {renderLineChart()}
          </div>
        </div>
        <div className="metric-footer forge-typography--caption">
          <div className="forge-typography--caption">{description}</div>
          <BookmarkCreatedText bookmark={userCardEntry} />
        </div>
        {isDeleteClicked &&
          <DeleteConfirmModal
            title="Delete forecast?"
            message={deleteMessage}
            showButtons={false}
            showMenuItems={handleClickMenuItems}
            onDeleteConfirm={handleCardDelete}
            onCancelConfirm={() => setIsDeleteClicked(false)}
          />}
      </div>
    );
  }

  return (
    <>
      <div className='card metric-card radar-metric-card' role="listitem"
        tabIndex={0}
        onClick={onClickCard}
        onKeyDown={(e) => onKeyDownRadarCard(e)}>
        {loading ?
          <LoadingSpinner isLoading={loading} /> :
          renderCardBody()
        }
      </div>
    </>
  );
};

ForecastCard.defaultProps = {
  isUserCard: false,
  onCardDelete: _.noop,
  userCardEntry: {},
  newLabelCards: [],
  isRadarBookmark: false,
  isNewCard: false
}

ForecastCard.propTypes = {
  isUserCard: PropTypes.bool,
  isRadarBookmark: PropTypes.bool,
  // isMyCollection: PropTypes.bool,
  onCardDelete: PropTypes.func,
  onCardClick: PropTypes.func,
  userCardEntry: PropTypes.object,
  newLabelCards: PropTypes.array,
  reloadBookmarks: PropTypes.func,
  loading: PropTypes.bool,
  commonFilters: PropTypes.object,
  templateEntry: PropTypes.object,
  viewEntry: PropTypes.object,
  isNewCard: PropTypes.bool
}

export default ForecastCard;
