import { useEffect, useState } from 'react';
import { fetchApiData } from 'helpers/apiResponseHelper';

export const useFetchMapOtherPinsCount = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState();

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    fetchApiData(url, { signal: controller.signal }).
      then((response) => {
        setData(response);
      }).
      catch(setError).
      finally(() => setLoading(false))

      return () => {
        controller.abort();
      }
  }, [url])

  return { loading, data, error };
}
