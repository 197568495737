const removeDialogBackdropShadow = (className) => {
    if (!document.body.classList.contains(className)) return;

    const forgeDialog = document.querySelector('forge-dialog');
    const backdrop = forgeDialog?.shadowRoot?.querySelector('forge-backdrop');

    if (backdrop) {
        backdrop.remove();
        console.log('Dialog backdrop shadow removed');
    }
};

export default removeDialogBackdropShadow;
