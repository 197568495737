// Vendor Imports
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Project Imports
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { ForgePopup } from '@tylertech/forge-react';

const DropdownGroup = (props) => {
  const {
    onChange, className, options, label, toggleButtonName,
    optionDisplayField, value, disabled, activeOptionField,
    onDropDownScroll, isLoading
  } = props;
  const targetRef = useRef();
  const activeField = _.isEmpty(activeOptionField) ? optionDisplayField : activeOptionField;
  const [isShow, setShow] = useState(false);
  const dropdownGroupRef = useRef();
  const tabIndex = (disabled ? '-1' : '0')

  const onClickToggleButton = () => {
    setShow(!isShow);
  }

  const onKeyDownToggleButton = (e) => {
    e.stopPropagation();
    if (isEnterButtonPressed(e)) {
      setShow(!isShow);
    }
  }

  const onClickListItem = (option) => {
    onChange(option);
    setShow(!isShow);
  }

  const onKeyDownListItem = (e, option) => {
    if(isEnterButtonPressed(e)) {
      onChange(option, true);
      setShow(!isShow);
    }
  }

  const dropdownToggleButton = () => {
    const matchedOption = _.find(options, (option) => {
      return _.get(option, activeField) === value;
    });
    const name = _.get(matchedOption, optionDisplayField, toggleButtonName);
    const id = label  ? `dropdownMenuButton-${ _.replace(_.lowerCase(label),new RegExp(" ","g"),"-")}`
                      : "dropdownMenuButton"

    return (
      <button className="btn filter-btn"
        disabled={disabled}
        onClick={onClickToggleButton}
        onKeyDown={onKeyDownToggleButton}
        type="button"
        tabIndex='-1'
        ref={targetRef}
        id={id}>
        <span className="text-truncate">
          {name}
        </span>
        <i className="icons-chevron-down"/>
      </button>
    )
  }

  const listItemContent = (option, index) => {
    const dropdownListClassNames = classNames('list-group-item', {
      'active': _.get(option, activeField, option) === value
    });
    
    let  displayName = _.get(option, optionDisplayField, option);
    if(_.isObject(displayName)){
      displayName = _.get(option, 'column') || _.get(option, activeField);
    }
    return (
      <li
        key={`dropdown-index-${index}`}
        className={dropdownListClassNames}
        tabIndex={isShow ? '0' : '-1'}
        onKeyDown={(e) => onKeyDownListItem(e, option)}
        onClick={() => onClickListItem(option)}
        aria-label="">{displayName}
      </li>
    )
  }

  const dropdownListContent = () => {
    const dropdownClassNames = classNames('dropdown-menu w-100', {
      'show': isShow
    });
    const listItemsContent = _.map(options, listItemContent);

    return (
      <ForgePopup
        placement='bottom'
        targetElementRef={targetRef}
        open={isShow}
        aria-labelledby="dropdownMenuButton"
      >
        <div className={dropdownClassNames} >
          <div className="value-list" onScroll={onDropDownScroll}>
            <ul className="list-group">
              {listItemsContent}
              {isLoading ? <li className="position-relative">
                <LoadingSpinner isLoading={isLoading} className="loading" />
              </li> : null}
            </ul>
          </div>
        </div>
      </ForgePopup>
    );
  }

  return (
    <div className={'filter-items' + className}>
      { !_.isEmpty(label) && <label>{label}</label> }
      <div
        className="dropdown w-100"
        ref={dropdownGroupRef}
        tabIndex={tabIndex}
        onKeyDown={onKeyDownToggleButton}>
        {dropdownToggleButton()}
        {dropdownListContent()}
      </div>
    </div>
  );
}

DropdownGroup.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  toggleButtonName: PropTypes.string,
  activeOptionField: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onDropDownScroll: PropTypes.func,
  optionDisplayField: PropTypes.string,
  value: PropTypes.string.isRequired
}

DropdownGroup.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  isLoading: false,
  toggleButtonName: 'Style',
  options: [],
  onDropDownScroll: _.noop,
  onChange: _.noop
}

export default DropdownGroup;
