import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ForgeButton, ForgeDialog } from '@tylertech/forge-react';
import LoadingSpinner from 'common/components/LoadingSpinner';
import useRemoveDialogBackdropShadow from 'hooks/useRemoveDialogBackdropShadow';

function ManageBookmarkUpdate({
  isLoading,
  closeSaveAnywayOrCopyModal,
  onHandleEditBookmark,
  onToggleSaveAsDropdown,
}) {


  const renderCancelButton = () => {
    return (
      <ForgeButton type="flat" style={{ marginRight: '16px' }}>
        <button
          tabIndex={0}
          aria-label="Cancel"
          onClick={() => closeSaveAnywayOrCopyModal()}
        >
          Cancel
        </button>
      </ForgeButton>
    );
  };

  const renderSaveAnywayAndCopyButton = () => {
    return (
      <div>
        <ForgeButton type="outlined" style={{ marginRight: '16px' }}>
          <button
            tabIndex={0}
            aria-label="Save anyway"
            onClick={() => onHandleEditBookmark()} >
            Save anyway
          </button>
        </ForgeButton>
        <ForgeButton type="raised">
          <button
            tabIndex={0}
            aria-label="Create copy"
            onClick={() => onToggleSaveAsDropdown(true)}
          >
            Create copy
          </button>
        </ForgeButton>
      </div>
    );
  };

  useEffect(() => {
    document.body.classList.add('bookmark-save-create-modal')
    useRemoveDialogBackdropShadow('bookmark-save-create-modal')
    return () => {
      document.body.classList.remove('bookmark-save-create-modal')
    }
  }, [])

  const bookMarkCardOptions = {
    backdropClose: false,
    escapeClose: false,
    customPosition: true,
    positionX: 'calc(100vw - 528px)',
    positionY: '111px',
    positionType: 'absolute'
  }

  return (
    <ForgeDialog open={true}
      options={bookMarkCardOptions} >
      <div className="bookmark-save-btn-card" style={{ width: '500px' }}>
        <header className="forge-dialog__header">
          <h2 className="forge-dialog__title">Save or create copy?</h2>
        </header>
        <LoadingSpinner isLoading={isLoading} />
        <section className="forge-dialog__body">
          This saved view is being used in more than one collection. If you save,
          your changes will apply to all places.
        </section>
        <footer className="forge-dialog__footer save-btn-card-footer">
          {renderCancelButton()}
          {renderSaveAnywayAndCopyButton()}
        </footer>
      </div>
    </ForgeDialog>
  );
}

ManageBookmarkUpdate.propTypes = {
  isLoading: PropTypes.bool,
  closeSaveAnywayOrCopyModal: PropTypes.func,
  onHandleEditBookmark: PropTypes.func,
  onToggleSaveAsDropdown: PropTypes.func,
};

export default ManageBookmarkUpdate;
