import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'

import { updateVisualizationReducer } from 'actions/visualizationActions';
import { updateDrilldownTemplateNameAndId } from 'actions/drilldownActions';
import { getCurrentTemplateEntry, getCurrentTemplateEntryById } from 'common/config/templateConfiguration';

import { setDefaultViewActions } from 'actions/defaultViewActions';

const SaveViewSelector = (props) => {
  const  { cardEntry, currentDrilldownViewEntry, isEmbed, analysisLink } = props;
  const dispatch = useDispatch();
  const visualization = _.get(cardEntry, 'visualization', {});
  let templateId = _.get(cardEntry, 'drilldown.currentDrilldownTemplateId');
  let templateName = _.get(cardEntry, 'drilldown.currentDrilldownTemplateName');

  if (_.isNull(templateId)) {
    templateId = _.get(getCurrentTemplateEntry(templateName), 'template_id');
  } else {
    templateName = _.get(getCurrentTemplateEntryById(templateId), 'name');
  }

  const onClickSaveView = () => {
    setDefaultViewActions({ viewEntry: currentDrilldownViewEntry, templateId, dispatch });
    dispatch(updateDrilldownTemplateNameAndId(templateName, templateId));
    dispatch(updateVisualizationReducer(visualization));
  }
  const name = currentDrilldownViewEntry.name;
  return(
    <div className="save-view">
      <i className="icons-bookmark-manager mr-2" />
      <span className="view-metric-link-container forge-typography--caption">
        <strong>Saved view based on</strong>
        {isEmbed ?
          <a href={analysisLink} target="_blank" rel="noopener noreferrer" >
            {` ${name}`}
          </a>:
          <NavLink
            to="/analysis"
            onClick={onClickSaveView}>{` ${name}`}
        </NavLink>}
      </span>
    </div>
  );
}

SaveViewSelector.propTypes = {
  cardEntry: PropTypes.object,
  currentDrilldownViewEntry: PropTypes.object,
  isEmbed: PropTypes.bool,
  analysisLink: PropTypes.string
}

export default SaveViewSelector;
