import React from 'react';
import { ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
import PropTypes from 'prop-types';
import { getLibraryOrderOptions } from './metricLibraryHelper';
function LibraryTitle({ selectedLibrary, onClickBack, setMetricLibraryOrder, metricLibraryOrder }) {
  const { name } = selectedLibrary;

  const bookmarkOrderDropdown = () => {
    return (<ForgeMenuOptions
            menuLabelName={metricLibraryOrder}
            menuOptions={getLibraryOrderOptions(selectedLibrary, metricLibraryOrder)}
            buttonType='flat'
            onSelectOption={onChangeSort} />);
  }

  const onChangeSort = (sortOption) => {
      setMetricLibraryOrder(_.get(sortOption, 'detail.value'));
    }

  return (
    <div className='cursor-pointer'>
      <ForgeDivider />
      <div className='cursor-pointer d-flex align-items-center justify-content-between gap-10 px-2 my-2'>
        <div className='d-flex align-items-center gap-10'>
          <ForgeIcon onClick={() => onClickBack('')} name="arrow_back" />
          {name}
        </div>
          {bookmarkOrderDropdown()}
      </div>
      <ForgeDivider />
    </div>
  )
}

LibraryTitle.propTypes = {
  selectedLibrary: PropTypes.object,
  onClickBack: PropTypes.func,
  setMetricLibraryOrder: PropTypes.func,
  metricLibraryOrder: PropTypes.string
};

export default LibraryTitle;
