import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// import { Modal, Form, Tabs, Tab, Tooltip, OverlayTrigger } from 'react-bootstrap';
import LoadingSpinner from 'common/components/LoadingSpinner';
import BookmarkFiltersView from 'pages/SubscriptionsManager/components/Bookmark/BookmarkFiltersView';
import BookmarkSettings from 'pages/SubscriptionsManager/components/Bookmark/BookmarkSettings';
import BookmarkTitle from 'pages/SubscriptionsManager/components/Bookmark/BookmarkTitle';
import QuickFilterBarHandler from 'pages/drilldown/components/QuickFilterBar/QuickFilterBarHandler';
import { getCustomerDomainId, getAlertEmailDeliveryTime } from 'common/config/customerConfiguration';

import { getVisualizationTotalData } from 'common/api/drilldown';
import { getApiParams } from 'helpers/apiParamsHelper';
import {
  getVizBasedChartTypes,
  getDefaultMetricCondition
} from 'pages/SubscriptionsManager/components/Bookmark/helper';
import { getCurrentVizBasedChartType } from 'helpers/visualizationHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import {
  DEFAULT_DROPDOWN_OPTION,
  EMAIL_STRATEGY_TYPES,
  WEEK_NUMBER_OPTIONS,
  WEEKDAYS_OPTIONS,
  SUBSCRIPTION_FREQUENCY_OPTIONS,
  TIMEZONE_DEFAULT_ALERT,
  VISUALIZATION_TYPES,
  DEFAULT_BOOKMARK_DIMENSION_OPTION,
  SNAPSHOT_VISUALIZATION_TYPES,
  NONE_DIMENSION_FIELD,
  TIME_DIFFERENCE_FOR_ZONE,
  TIMEZONE_OPTIONS
} from 'appConstants';

import { trackEvent } from 'helpers/eventTracking';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import RadarTimeFrames from 'pages/Radar/RadarTimeFrames';
import { getDimensionEntries } from 'common/config/templateConfiguration';
import {
  alertEmailDeliveryTimeZone,
  enableTemplateAndMetricPermission
} from 'common/config/customerConfiguration';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import Recipients from 'pages/SubscriptionsManager/components/Recipients';
import { isPermissionEnabledForTemplate } from 'modules/Administration/permissionHelper';
class BookmarkManageDetails extends Component {
  constructor(props) {
    super(props);
    const emailDeliveryTime = getAlertEmailDeliveryTime();
    const timeZone = _.get(TIME_DIFFERENCE_FOR_ZONE, alertEmailDeliveryTimeZone, '-10:00');
    const defaultTimeZone = _.isEmpty(timeZone) ?
      _.first(TIMEZONE_OPTIONS): TIMEZONE_DEFAULT_ALERT[timeZone];
    const defaultDeliveryTime = moment.utc(emailDeliveryTime,'hA')
      .utcOffset(Number(defaultTimeZone)).format('hA');

    this.state = {
      isTotalLoading: false,
      isUserEmailLoading: false,
      userEmails: [],
      currentDimensionTotal: 0,
      sendEmailUpdates: false,
      emailStrategy: 'daily',
      name: this.props.name,
      description:'',
      drilldown: this.props.drilldown || {},
      mapOptions: this.props.mapOptions,
      visualization: this.props.visualization || {},
      currentVizChartType: this.getVizChartType(),
      commonFilters: this.props.commonFilters || {},
      monitorConditions: [],
      radarOptions: this.props.radarOptions || {},
      advanceSearchOptions: this.props.advanceSearchOptions || {},
      emailOptions: {
        emailSendingTime: props.emailSendingTime || defaultDeliveryTime,
        frequency: props.frequency  || _.get(SUBSCRIPTION_FREQUENCY_OPTIONS, '0.value'),
        frequencyInterval: props.frequencyInterval,
        timeZone: props.timeZone  || defaultTimeZone,
        monthlyWeekNumber: props.monthlyWeekNumber || _.get(WEEK_NUMBER_OPTIONS, '0.value'),
        emailSendingDays: props.emailSendingDays || [_.get(WEEKDAYS_OPTIONS, '1.day')],
      },
      isRelativeDateSelected: false,
      isNoneDateSelected: false,
      saveToMyBookmark: true,
      isManageView: this.props.isManageView,
      currentPage: 'bookmarkSettings',
      dimensionOption: DEFAULT_BOOKMARK_DIMENSION_OPTION,
      recipients: this.props.recipients,
      isErrorInRecipients: false
    };

    this.abortFetchController = new AbortController();
  }
  static contextType = UserFromBellerophonContext;

  getVizChartType = () => {
    const { drilldown, mapOptions, currentVizChartType } = this.props;
    const { currentVisualizationType } = drilldown;
    let newVizChartType;
    if((currentVisualizationType == VISUALIZATION_TYPES.MAP.type)) {
      newVizChartType = _.get(mapOptions, 'currentMapView.type', 'point');
    }else {
      newVizChartType = currentVizChartType;
    }
    return newVizChartType;
  }

  initialLoadEditBookmark = () => {
    const { bookmarkFieldOptions, sendEmailUpdates, emailStrategy, currentDrilldownTemplateId } = this.props;
    const { currentVizChartType, emailOptions } =  this.state;
    const bookmarkEmailOptions = _.isEmpty(bookmarkFieldOptions.emailOptions) ?
      emailOptions : bookmarkFieldOptions.emailOptions;
    if(!_.isEmpty(bookmarkFieldOptions) && Number(bookmarkFieldOptions.id) > 0){
      const isNoneDateSelected = _.get(bookmarkFieldOptions, 'isNoneDateSelected', false);
      const dimensionOption = _.get(
        bookmarkFieldOptions, 'dimensionOption', DEFAULT_BOOKMARK_DIMENSION_OPTION
      );
      const dimensionsEntries = getDimensionEntries(currentDrilldownTemplateId);
      const defaultDimension = dimensionOption === DEFAULT_BOOKMARK_DIMENSION_OPTION ?
        _.get(dimensionsEntries, [0, 'field']) :
        dimensionOption;

      this.setState({
        monitorConditions: bookmarkFieldOptions.monitorConditions || [],
        currentVizChartType: bookmarkFieldOptions.currentVizChartType || currentVizChartType,
        emailOptions: bookmarkEmailOptions,
        sendEmailUpdates,
        emailStrategy,
        isRelativeDateSelected: _.get(bookmarkFieldOptions, 'isRelativeDateSelected', false),
        isNoneDateSelected: isNoneDateSelected,
        isNoneAdditionalDateFilters: _.get(
          bookmarkFieldOptions, 'isNoneAdditionalDateFilters', isNoneDateSelected
        ),
        description: bookmarkFieldOptions.description || '',
        name: this.props.name,
        drilldown: this.props.drilldown || {},
        mapOptions: this.props.mapOptions,
        radarOptions: this.props.radarOptions,
        advanceSearchOptions: this.props.advanceSearchOptions,
        visualization: this.props.visualization || {},
        commonFilters: this.props.commonFilters || {},
        includeImage: _.get(bookmarkFieldOptions,'includeImage', false),
        dimensionOption,
        isManageView: bookmarkFieldOptions.isManageView,
        defaultDimension
      });
    }
  }

  componentDidMount() {
    this.fetchDimensionTotal();
    this.initialLoadEditBookmark();
  }

  componentDidUpdate(prevProps, prevState) {
    const { drilldown, commonFilters } = this.state;
    const { bookmarkFieldOptions } = this.props;

    if(!_.isEqual(drilldown, prevState.drilldown) || !_.isEqual(commonFilters, prevState.commonFilters)) {
      this.fetchDimensionTotal();
    }

    if(!_.isEqual(bookmarkFieldOptions.id, prevProps.bookmarkFieldOptions.id)) {
      this.initialLoadEditBookmark();
    }
  }

  componentWillUnmount(){
    this.abortFetchController.abort();
  }

  fetchDimensionTotal = async() => {
    const { drilldown, mapOptions, commonFilters } = this.state;
    const apiParams = getApiParams({drilldown, mapOptions, commonFilters}, {});

    this.setState({ isTotalLoading: true });
    this.abortFetchController.abort()
    this.abortFetchController = new AbortController()

    const getVisualizationTotalDataApiUrl = getVisualizationTotalData(apiParams);
    fetchApiData(getVisualizationTotalDataApiUrl, this.abortFetchController).
      then((response) => {
        this.setState({
          isTotalLoading: false,
          currentDimensionTotal: _.get(response, [0, 'total'], 0)
        });
      }).catch(() => {
        this.setState({ isTotalLoading: false });
      });
  }

  updateDimensionOfBookmark = () => {
    const { drilldown, currentVizChartType, defaultDimension } = this.state;
    const { currentVisualizationType, currentDrilldownDimensionField } = drilldown;

    if (currentDrilldownDimensionField == NONE_DIMENSION_FIELD &&
      (currentVisualizationType != VISUALIZATION_TYPES.SNAPSHOT.type ||
      currentVizChartType != SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type)
    ) {
      this.setState({ drilldown: {
          ...drilldown,
          currentDrilldownDimensionField: defaultDimension
        }
      }, () => {
        this.handleUpdate();
      });
    } else {
      this.handleUpdate();
    }
  };

  onVisualizationSelect = (visualization) => {
    const { drilldown } = this.state;

    const chartTypes = getVizBasedChartTypes(visualization.type, drilldown['currentDrilldownViewEntry']);
    const defaultChartType = getCurrentVizBasedChartType(visualization.type, {});
    let vizChartEntry = _.find(chartTypes, (entry) => {
      return (entry.type == defaultChartType);
    });
    let currentVizChartType = defaultChartType;
    if(_.isEmpty(vizChartEntry) && !_.isEmpty(chartTypes)){
      currentVizChartType = _.first(chartTypes)['type'];
    }

    this.setState({ drilldown: {
        ...drilldown,
      currentVisualizationType: visualization.type
      },
      currentVizChartType
    },() => {
      this.onBenchmarkOptionChange(DEFAULT_DROPDOWN_OPTION, visualization.type)
    });
  }

  onBenchmarkOptionChange = (selectedEntries, selectedVisualizationType = '' ) => {
    const { visualization, drilldown } = this.state;
    const { currentVisualizationType } = drilldown;
    const visualizationType = _.isEmpty(selectedVisualizationType) ?
      currentVisualizationType : selectedVisualizationType;

    let currentVizOptions = _.get(visualization, visualizationType, {});
    currentVizOptions = {
      ...currentVizOptions,
      currentBenchMarkMetricNames: _.map(selectedEntries, 'name')
    }

    this.setState({ visualization: {
        ...visualization,
        [visualizationType]: currentVizOptions
      }
    }, () => {
      this.updateDimensionOfBookmark();
    });
  }

  onVisChartTypeSelect = (chartEntry) => {
    const { drilldown } = this.state;
    const { currentVisualizationType } = drilldown;
    this.setState({ currentVizChartType: chartEntry.type }, () => {
      this.onBenchmarkOptionChange(DEFAULT_DROPDOWN_OPTION, currentVisualizationType);
    });
  }

  onQuickFiltersChange = (filters) => {
    const { drilldown } = this.state;

    this.setState({ drilldown: {
        ...drilldown,
       quickFilters: filters
      }
    }, () => {
      this.handleUpdate();
   });
  }

  isAnyOneOfTheMonitorIsEnabled = (conditions) => {
    return _.some(conditions, {enable: true});
  }

  handleSettingValueChange = (value, field) =>{
    const { emailOptions } = this.state;
    let formattedValue = value;
    this.setState({ emailOptions : {
        ...emailOptions,
        [field]: formattedValue
      }
    }, () => {
      this.handleUpdate();
    });
  }

  handleWeekdaysOptionsChange = (isChecked, day) => {
    const { emailOptions } = this.state;
    let selectedDays = _.cloneDeep(_.get(emailOptions, 'emailSendingDays'));
    if(!isChecked && selectedDays.length === 1){
      return;
    }
    selectedDays = isChecked ? [...selectedDays, day] : _.without(selectedDays, day)
    this.setState({ emailOptions : {
        ...emailOptions,
        emailSendingDays: selectedDays
      }
    }, () => {
      this.handleUpdate();
    });
  }

  handleIncludeImageChange = (isCheked) => {
    this.setState({includeImage: isCheked}, () => {
      this.handleUpdate();
    })
  }

  getBookmarkParams = () => {
    const { bookmarkFieldOptions, isRadar, isCustomAdvanceSearch } = this.props;
    const {
      drilldown,
      name,
      description,
      mapOptions,
      radarOptions,
      currentDimensionTotal,
      sendEmailUpdates,
      monitorConditions,
      currentVizChartType,
      visualization,
      emailStrategy,
      emailOptions,
      commonFilters,
      isRelativeDateSelected,
      isNoneDateSelected,
      isNoneAdditionalDateFilters,
      dimensionOption,
      includeImage,
      isManageView,
      advanceSearchOptions,
      recipients
    } = this.state;

    const { currentVisualizationType } = drilldown;
    const mapViews = getMapViews();
    const newMapOptions = {
      ...mapOptions,
      currentMapView: _.get(mapViews, currentVizChartType)
    }
    let visChartViewKey;
    if(currentVisualizationType == VISUALIZATION_TYPES.SNAPSHOT.type) {
      visChartViewKey = 'currentSnapshotView';
    } else {
      visChartViewKey = 'currentChartView';
    }
    let newVisualization = _.cloneDeep(visualization);
    if((currentVisualizationType != VISUALIZATION_TYPES.MAP.type)) {
      if(newVisualization[currentVisualizationType]) {
        newVisualization[currentVisualizationType][visChartViewKey] = currentVizChartType;
      }
    }
    if(isRadar || isCustomAdvanceSearch) {
      newVisualization = {};
    } else {
      newVisualization['mapOptions'] = newMapOptions;
    }


    return {
      id:bookmarkFieldOptions.id,
      domain_name: getCustomerDomainId(),
      name,
      sort_order: bookmarkFieldOptions.sortOrder,
      send_alerts: sendEmailUpdates,
      email_strategy: emailStrategy,
      email_options: isRadar|| isCustomAdvanceSearch ? {} : emailOptions,
      is_radar: isRadar,
      is_custom_advance_search: isCustomAdvanceSearch,
      is_manage_view: isManageView,
      drilldown: isRadar || isCustomAdvanceSearch ? {}: drilldown,
      commonFilters,
      radarOptions,
      visualization: newVisualization,
      bookmarkOptions: {
        currentDimensionTotal,
        currentVizChartType,
        monitorConditions,
        description,
        dimensionOption,
        isRelativeDateSelected,
        isNoneDateSelected,
        isNoneAdditionalDateFilters
      },
      advanceSearchOptions,
      include_image: includeImage,
      notification_state: 'open',
      is_collaborate_alert: bookmarkFieldOptions.isCollaborateAlert,
      recipients: recipients
    };
  }

  handleUpdate = () => {
    const { onSave } = this.props;
    const { isErrorInRecipients } = this.state;

    const params = {
      shareEmails: [],
      bookmark: this.getBookmarkParams(),
      include_current_user:true,
      shareFieldInputs: {},
      isErrorInRecipients: isErrorInRecipients
    };

    onSave(params);
  }

  toggleSendEmailUpdates = (isChecked) => {
    this.setState({
      sendEmailUpdates : isChecked
    }, () => {
      this.handleUpdate();
   })

    if(isChecked) {
      trackEvent('monitor-email-signup');
    }
  }

  handleCommonFiltersChange = (commonFilters) => {
    this.setState({
      commonFilters
    }, () => {
      this.handleUpdate();
   });
  }

  handleRelativeDateTypeChange = (isRelativeDateSelected, isNoneDateSelected, isAdditionalFilter = false) => {
    const noneFilterKey = isAdditionalFilter ? 'isNoneAdditionalDateFilters' : 'isNoneDateSelected';

    this.setState({ isRelativeDateSelected, [noneFilterKey]: isNoneDateSelected }, () => {
      this.handleUpdate();
    });
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value
    }, () => {
      this.handleUpdate();
   })
  }

  handleDescriptionChange = (e) => {
    this.setState({
      description: e.target.value
    }, () => {
      this.handleUpdate();
   })
  }

  handleDimensionTypeChange = (dimensionOption) => {
    const dimension = _.get(dimensionOption, 'type', DEFAULT_BOOKMARK_DIMENSION_OPTION);
    this.setState({ dimensionOption: dimension }, () => {
      this.handleUpdate();
    })
  }

  handleMonitorConditionChange = (monitorConditions) => {
    if(!this.isAnyOneOfTheMonitorIsEnabled(monitorConditions)){
      this.setState({ sendEmailUpdates: false });
    }
    this.setState({
      monitorConditions
    }, () => {
      this.handleUpdate();
    })
  }

  handleEmailStrategyChange = (emailStrategyEntry) => {
    let newMonitorConditions = this.state.monitorConditions;
    const viewEntry = _.get(this.state, 'drilldown.currentDrilldownViewEntry', {});
    if(_.isEmpty(newMonitorConditions)){
      newMonitorConditions = [getDefaultMetricCondition(viewEntry)];
    }
    this.setState({
      emailStrategy: emailStrategyEntry.type,
      monitorConditions: newMonitorConditions
    }, () => {
      this.handleUpdate();
   })
  }

  onDateFilterChange = (dateRangeOptions, type) => {
    let options = _.cloneDeep(dateRangeOptions);
    if(type === 'currentDateRangeOptions') {
      options = _.omit(options, 'comparisonPeriod');
    } else {
      options = _.omit(options, 'relativeDateFilterEntry');
    }

    this.setState({ radarOptions: {
      ...this.state.radarOptions,
      [type]: options
    }}, () => { this.handleUpdate(); })
  }

  onEmailUpdate = (emails, isError) => {
    const { recipients } = this.state;

    const updatedRecipients = {
      ...recipients,
      recipients: emails
    };

    this.setState({
      recipients: updatedRecipients,
      isErrorInRecipients: isError ||_.isEmpty(emails)
    }, () => {
      this.handleUpdate();
    })
  }

  isRadarView = () => {
    const { isRadar, radarOptions } = this.props;
    return isRadar && !_.isEmpty(radarOptions);
  }

  renderSpinner() {
    const { isLoading } = this.props;
    const { isTotalLoading } = this.state;

    return (
      <LoadingSpinner isLoading={isLoading || isTotalLoading} />
    );
  }

  renderBookmarkTitle() {
    const { name, description } = this.state;
    const { isDisabled } = this.props;

    return(
      <BookmarkTitle
        isDisabled={isDisabled}
        name = {name}
        description = {description}
        onHandleNameChange = {this.handleNameChange}
        onHandleDescriptionChange = {this.handleDescriptionChange} />
    );
  }

  renderRecipientsSection() {
    const {
      currentUser,
      bookmarkFieldOptions,
      currentBookmarkEntry,
      currentDrilldownTemplateId
    } = this.props;
    const { emailStrategy, recipients } = this.state;

    if (!isManageCollections() ||
      emailStrategy === EMAIL_STRATEGY_TYPES.NEVER ||
      !_.get(bookmarkFieldOptions, 'isCollaborateAlert', false)) {
      return null;
    }
    const currentDrilldownViewEntry = _.get(currentBookmarkEntry, 'drilldown.currentDrilldownViewEntry');
    let allowedUserRoles = [];
    if(enableTemplateAndMetricPermission()){
      allowedUserRoles = _.map(_.get(currentDrilldownViewEntry, 'user_list', []), 'role_id');
    }
    const recipientsEmails = _.get(recipients, 'recipients', []);

    return (
      <Recipients
        editMode={true}
        currentUser={currentUser}
        allowedUserRoles={allowedUserRoles}
        recipientsEmails={recipientsEmails}
        onEmailUpdate={this.onEmailUpdate}
        templateIds={[currentDrilldownTemplateId]}
        isPermissionEnabled={isPermissionEnabledForTemplate(currentDrilldownTemplateId)}
      />
    );
  }

  renderSettingsPage() {
    const { currentUser, currentDrilldownTemplateId, isDisabled } = this.props;

    const { currentPage, currentVizChartType, drilldown,
      monitorConditions, visualization, emailStrategy, emailOptions,
      sendEmailUpdates, currentDimensionTotal, includeImage,
      dimensionOption, defaultDimension } = this.state;
    if (emailStrategy === EMAIL_STRATEGY_TYPES.NEVER) {
      return null;
    }

    return(
      <div className="mb-2">
        <BookmarkSettings
          isDisabled={isDisabled}
          defaultDimension={defaultDimension}
          isManagePage={true}
          currentDrilldownTemplateId = {currentDrilldownTemplateId}
          currentUser = {currentUser}
          currentTab= {currentPage}
          drilldown = {drilldown}
          monitorConditions = {monitorConditions}
          visualization = {visualization}
          sendEmailUpdates = {sendEmailUpdates}
          emailStrategy = {emailStrategy}
          emailOptions ={emailOptions}
          dimensionOption={dimensionOption}
          includeImage={includeImage}
          currentVizChartType = {currentVizChartType}
          currentDimensionTotal = {currentDimensionTotal}
          onToggleSendEmailUpdates = {this.toggleSendEmailUpdates}
          onHandleMonitorConditionChange = {this.handleMonitorConditionChange}
          onHandleDimensionTypeChange={this.handleDimensionTypeChange}
          onHandleEmailStrategyChange = {this.handleEmailStrategyChange}
          onSettingValueChange={this.handleSettingValueChange}
          handleWeekdaysOptionsChange={this.handleWeekdaysOptionsChange}
          onIncludeImageChange = {this.handleIncludeImageChange} />
      </div>
    );
  }

  renderTimeFrame = () => {
    const radarOptions = _.get(this.props, 'radarOptions', {});

    const timeFramesAttributes = _.pick(radarOptions, 'currentDateRangeOptions', 'previousDateRangeOptions');
    timeFramesAttributes['currentTemplateId'] = this.props.currentDrilldownTemplateId;

    return (
      <div className='saved-radar-view'>
        <RadarTimeFrames
          {...timeFramesAttributes}
          onDateFilterChange={this.onDateFilterChange}
        />
      </div>

    );
  }

  onAdvanceSearchQuickFiltersChange = (filters) => {
    const { advanceSearchOptions } = this.state;

    this.setState({ advanceSearchOptions: {
        ...advanceSearchOptions,
        filters: filters
      }
    }, () => {
      this.handleUpdate();
    });
  }

  renderAdvancedSearchFilters = () => {
    const { apiParams, advanceSearchOptions } = this.props;
    // const { advanceSearchOptions } = this.state;
    const { templateId, filters } = advanceSearchOptions;
    const newParams = {
      ...apiParams,
      currentDrilldownTemplateId: templateId,
      ignore_view_entry: true
    }
    return (
      <div className='saved-radar-view'>
        <div className="quick-filter-section row">
            <QuickFilterBarHandler
              disabledFilters={false}
              className="col-md-6"
              quickFilters={filters}
              onFilterChange={this.onAdvanceSearchQuickFiltersChange}
              currentDrilldownTemplateId={templateId}
              apiParams={newParams}
            />
        </div>
      </div>

    );
  }

  renderFiltersPage() {
    const {
      currentDrilldownTemplateId,
      distributionChartOptions,
      apiParams,
      isDisabled,
      isCustomAdvanceSearch
    } = this.props;
    const {
      currentVizChartType, drilldown, visualization, emailStrategy,
      commonFilters, isRelativeDateSelected, isNoneDateSelected, isNoneAdditionalDateFilters
      } = this.state;
    if(this.isRadarView() || isCustomAdvanceSearch) {
      return null;
    }

    return(
      <BookmarkFiltersView
        isDisabled={isDisabled}
        emailStrategy={emailStrategy}
        drilldown = {drilldown}
        apiParams = {apiParams}
        currentVizChartType = {currentVizChartType}
        visualization={visualization}
        currentDrilldownTemplateId = {currentDrilldownTemplateId}
        distributionChartOptions = {distributionChartOptions}
        commonFilters = {commonFilters}
        isRelativeDateSelected={isRelativeDateSelected}
        isNoneDateSelected={isNoneDateSelected}
        isNoneAdditionalDateFilters={isNoneAdditionalDateFilters}
        onHandleCommonFiltersChange={this.handleCommonFiltersChange}
        onHandleRelativeDateTypeChange={this.handleRelativeDateTypeChange}
        onVisualizationSelect = {this.onVisualizationSelect}
        onVisChartTypeSelect = {this.onVisChartTypeSelect}
        onBenchmarkOptionChange = {this.onBenchmarkOptionChange}
        onQuickFiltersChange = {this.onQuickFiltersChange} />
      );
  }

  render() {
    const { bookmarkFieldOptions, isCustomAdvanceSearch } = this.props;
    const uniquId = `BOOK-${bookmarkFieldOptions.id}`;

    return (
      <div key={uniquId}>
        {this.renderSpinner()}
        {this.renderBookmarkTitle()}
        {this.renderRecipientsSection()}
        {this.renderSettingsPage()}
        {this.isRadarView() && this.renderTimeFrame()}
        {this.renderFiltersPage()}
        {isCustomAdvanceSearch && this.renderAdvancedSearchFilters()}
      </div>
    )
  }
}

BookmarkManageDetails.propTypes = {
  currentVizChartTypeEntry: PropTypes.object,
  currentUser: PropTypes.object,
  drilldown: PropTypes.object,
  name: PropTypes.string,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  editMode: PropTypes.bool,
  distributionChartOptions: PropTypes.object,
  apiParams: PropTypes.object,
  bookmarkFieldOptions: PropTypes.object,
  currentDrilldownTemplateId: PropTypes.number,
  mapOptions: PropTypes.object,
  emailSendingTime: PropTypes.string,
  frequency: PropTypes.string,
  frequencyInterval: PropTypes.number,
  timeZone: PropTypes.string,
  monthlyWeekNumber: PropTypes.number,
  emailSendingDays: PropTypes.array,
  currentVizChartType: PropTypes.string,
  sendEmailUpdates: PropTypes.bool,
  emailStrategy: PropTypes.string,
  visualization: PropTypes.object,
  commonFilters: commonPropTypes.commonFiltersPropTypes,
  isRadar: PropTypes.bool,
  radarOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
  isManageView: PropTypes.bool,
  isCustomAdvanceSearch: PropTypes.bool,
  advanceSearchOptions: PropTypes.object,
  recipients: PropTypes.object,
  currentBookmarkEntry: PropTypes.object,
  userFromBellerophon: PropTypes.object,
}

function mapDispatchToProps() {
  return {}
}

function mapStateToProps(state) {
  return {
    currentUser: _.get(state.currentUser, 'user', {})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkManageDetails);
