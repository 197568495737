// Vendor Imports
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Project Imports
import { getCompareYearsDescriptionText } from 'helpers/dateHelper';
import ComparisonDateFilterChip from './ComparisonDateFilterChip';

import { trackEvent } from 'helpers/eventTracking';
import ToggleButton from 'pages/Radar/RadarSideBar/ToggleButton';
import { DATE_COMPARISON_TYPE } from 'appConstants';

const ComparisonModeSelector = (props) => {
  let {
    comparisonType, comparisonDateRanges,
    comparisonModeOn, onCompareDateRangesChange,
    currentDrilldownTemplateId, dateRange, dateType,
  } = props;

  const handleComparisonDateChange = (comparisonType, dataRanges) => {
    props.dispatchUpdateComparisonType(comparisonType, dataRanges, props);
  }

  const onComparisonModeChange = () => {
    props.dispatchUpdateComparisonModeOn(!comparisonModeOn, comparisonType, comparisonDateRanges, props);
    trackEvent('toggled-comparison-mode');
  }
  const renderToggleButton = () => {
    return (
      <>
        <ToggleButton isChecked={comparisonModeOn} onClickCheckBox={onComparisonModeChange} />
      </>
    );
  }
  const descriptionYearTexts = useMemo(() =>
    getCompareYearsDescriptionText(props, currentDrilldownTemplateId),
    [dateRange, dateType, comparisonType, comparisonDateRanges, currentDrilldownTemplateId]
  );

  const comparisonPeriodDescription = _.map(descriptionYearTexts, (yearText) => {
    const showFYText = (dateType == 'yearly' || dateType == 'biennium_fiscal_year') &&
      comparisonType == DATE_COMPARISON_TYPE.SAME_PERIOD;
    return (<div key={yearText}>{showFYText ? 'FY' : ''} {yearText}</div>);
  });
  const compareClassNames = classNames('forge-typography--caption', {
    'date-range-type': dateType == 'custom_range'
  });

  return (
    <div className="time-filter forge-popup-host">
      <div className="toggle-button">
        <div className="forge-typography--caption"> Comparison period </div>
        {renderToggleButton()}
      </div>
      {comparisonModeOn ?
        <ComparisonDateFilterChip
          onCompareDateRangesChange={onCompareDateRangesChange}
          onComparisonDateChange={handleComparisonDateChange}
          {...props} /> : null}
      {comparisonModeOn &&
        <div className={compareClassNames}>
          {comparisonPeriodDescription}
        </div>}
      {!comparisonModeOn &&
        <div className="forge-typography--caption comparison-caption">
          <em>Turn on to compare against another date range</em>
        </div>
      }
    </div>
  );
}

ComparisonModeSelector.propTypes = {
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dateRange: PropTypes.object,
  comparisonDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })),
  dateType: PropTypes.string,
  comparisonModeOn: PropTypes.bool,
  comparisonType: PropTypes.string,
  dispatchUpdateComparisonModeOn: PropTypes.func,
  dispatchUpdateComparisonType: PropTypes.func,
  onCompareDateRangesChange: PropTypes.func,
  defaultDateType: PropTypes.string
}

export default ComparisonModeSelector;
