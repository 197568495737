import React from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import CanvasCardBody from './CanvasCardBody'
import { updateDefaultMapCanvasOptions } from 'actions/mapCanvasActions';

function MapCanvasCard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onClickCard = () => {
    // TODO: use card click handler
    dispatch(updateDefaultMapCanvasOptions());
    history.push('/map-explorer');
  };

  return (
    <div className='card metric-card map-canvas-card' role="listitem"
      onClick={onClickCard}>
      {/* <CanvasCardHeader /> */}
      <CanvasCardBody />
      {/* <CanvasCardFooter /> */}
    </div>
  )
}

MapCanvasCard.propTypes = {
  title: PropTypes.string.isRequired,
  bodyContent: PropTypes.node.isRequired,
  footerContent: PropTypes.node.isRequired,
}

export default MapCanvasCard
