import React from 'react';
import PropTypes from 'prop-types';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ToggleButton = ({ label, onClickLabel, id, isChecked, onClickCheckBox, showCheckBox }) => {

  const onKeyDownInput = (e) => {
    if (isEnterButtonPressed(e)) {
      e.preventDefault();
      e.stopPropagation();
      onClickCheckBox(!isChecked);
    }
  };

  const renderCheckBox = () => {
    return (
      <div 
        tabIndex={0}
        aria-label="ComparisonPeriodCheckbox"
        onKeyDown={(event) => onKeyDownInput(event)}
        className="custom-control custom-sm-switch-control custom-switch ml-auto">
        <input type="checkbox"
          tabIndex={-1} 
          title="Comparison Period Checkbox"
          checked={isChecked}
          className="custom-control-input" id={id}
          onChange={(event) => onClickCheckBox(event.target.checked)}
          />
        <label className="custom-control-label cursor-pointer" htmlFor={id}></label>
      </div>
    )
  }

  return (
    <div className='d-flex align-items-center custom-toggle cursor-pointer'>
      <a aria-label="Comparison period" 
        className="forge-typography--body1 color-theme-primary d-block text-nowrap radar-toggle" 
        role="button" onClick={onClickLabel}>{label}</a>
      {showCheckBox && renderCheckBox()}
    </div>
  );
}

ToggleButton.defaultProps = {
  id: 'toggle-button-id',
  isChecked: false,
  label: '',
  showCheckBox: true,
  onClickCheckBox: _.noop,
  onClickLabel: _.noop,
};

ToggleButton.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  label: PropTypes.string,
  onClickLabel: PropTypes.func,
  onClickCheckBox: PropTypes.func
}

export default ToggleButton;
