import { METRIC_LIBRARY_ORDER, LIBRARY_TYPE } from 'appConstants';
import { getAllMetricEntries } from 'helpers/templateHelper';

export const getUniqueKey = (cardEntry, index) => {
  const { templateEntry, viewEntry } = cardEntry;
  let templateId = _.get(templateEntry, 'template_id');
  const viewId = _.get(viewEntry, 'view_id');

  return `allMetric-${templateId}-${viewId}-${index}`
}

export const getOrderedBookmarks = (metricLibraryOrder, bookmarkEntries) => {
    if(metricLibraryOrder === METRIC_LIBRARY_ORDER.recentlyCreated) {
      return _.orderBy(bookmarkEntries, ['created_at'], ['desc']);
    } else if(metricLibraryOrder === METRIC_LIBRARY_ORDER.alphabetically) {
      return _.orderBy(bookmarkEntries, ['name'], ['asc']);
    } else if(metricLibraryOrder === METRIC_LIBRARY_ORDER.recentlyOpened) {
      return _.orderBy(bookmarkEntries, [entry => entry.opened_at || ''], ['desc']);
    }
}


export const getOrderedAllMetricEntries = (options) => {
  const { templateEntries, allMetricsLogs, metricLibraryOrder, searchMetricName } = options;
    const filteredMetrics = getAllMetricEntries(templateEntries, searchMetricName, allMetricsLogs);

    if(metricLibraryOrder === METRIC_LIBRARY_ORDER.recentlyOpened) {
      return _.orderBy(filteredMetrics, ['viewEntry.opened_at'], ['desc']);
    } else if(metricLibraryOrder === METRIC_LIBRARY_ORDER.alphabetically) {
      return _.orderBy(filteredMetrics, ['viewEntry.name'], ['asc']);
    }
}

export const getLibraryOrderOptions = (selectedLibrary, selectedLibraryOrder) => {
  if (selectedLibrary.type === LIBRARY_TYPE.ALL_METRICS) {
    return [
      sortOption(METRIC_LIBRARY_ORDER.recentlyOpened, selectedLibraryOrder),
      sortOption(METRIC_LIBRARY_ORDER.alphabetically, selectedLibraryOrder)
    ];
  } else {
    return [
      sortOption(METRIC_LIBRARY_ORDER.recentlyOpened, selectedLibraryOrder),
      sortOption(METRIC_LIBRARY_ORDER.recentlyCreated, selectedLibraryOrder),
      sortOption(METRIC_LIBRARY_ORDER.alphabetically, selectedLibraryOrder)
    ];
  }
};

const sortOption = (option, selectedLibraryOrder) => {
  return (
    {
      value: option,
      label: option,
      leadingIconType: 'component',
      leadingIcon: selectedLibraryOrder == option ? 'check' : 'forge-empty-icon'
    }
  )
}