import { ForgeButton, ForgeDialog, ForgeIcon, ForgeTooltip } from '@tylertech/forge-react';
import React from 'react'
import CompFinderIcon from './CompFinderIcon';
import PropTypes from 'prop-types';
import LoadingSpinner from 'common/components/LoadingSpinner';

const ExpandPhotoModal = (props) => {
  const {
    isLoading,
    isPopupExpand,
    setIsPopupExpand,
    title,
    subTitle,
    searchTemplateId,
    searchMethod,
    renderPhotoContent,
    handleExpand,
    handleClose,
    showExpandIcon = true } = props;

  const renderPhotoPropertyContent = () => {
    const expandIconName = isPopupExpand ? 'arrow_collapse' : 'arrow_expand';

    return (
      <div className='property-content d-flex align-items-center justify-content-between gap-5'>
        <div className='d-flex align-items-center justify-content-center gap-4'>
          <CompFinderIcon templateId={searchTemplateId} searchMethod={searchMethod} />
          <div className='property-content-container'>
            <h6>{title}</h6>
            {!_.isEmpty(subTitle) && <p>{subTitle}</p>}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-3'>
          {showExpandIcon &&
            <ForgeButton className='property-content-close 
                            property-content-expand'>
              <button type="button" onClick={handleExpand} >
                <ForgeIcon name={expandIconName} />
              </button>
              <ForgeTooltip
                text='Collapse'
                position={'top'} />
            </ForgeButton>}
          <ForgeButton className='property-content-close'>
            <button type="button" onClick={handleClose}>
              <ForgeIcon name='close' />
            </button>
          </ForgeButton>
        </div>
      </div >
    )
  }

  return (
    <ForgeDialog
      open={isPopupExpand}
      backdropClose='true'
      onDismiss={() => setIsPopupExpand(false)}
    >
      <div className='expanded-popup'>
        <LoadingSpinner isLoading={isLoading} />
        {renderPhotoPropertyContent()}
        <div className='expanded-photo'>
          {renderPhotoContent()}
        </div>
      </div>
    </ForgeDialog>
  )
}

ExpandPhotoModal.propTypes = {
  isPopupExpand: PropTypes.bool.isRequired,
  setIsPopupExpand: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  searchTemplateId: PropTypes.string.isRequired,
  searchMethod: PropTypes.string.isRequired,
  renderPhotoContent: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  showExpandIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ExpandPhotoModal.defaultProps = {
  isLoading: false
}


export default ExpandPhotoModal