import PropTypes from 'prop-types';
import React from 'react';

import DateRangeFilterWithBadge from
  'pages/dashboard/components/Collections/CollectionDateFilters/DateRangeFilterWithBadge';

const MapCanvasTimeFrames = ({
  currentDateRangeOptions,
  currentTemplateId,
  onDateFilterChange
}) => {
  // const{ comparisonPeriod} = previousDateRangeOptions;
  // const currentDateRange = _.get(currentDateRangeOptions, 'dateRange');
  // const compareDateRange = _.get(previousDateRangeOptions, 'dateRange');
  // const dateRangeType = _.get(currentDateRangeOptions, 'dateType');

  // const yearOptions = {
  //   dateRange: currentDateRange,
  //   comparisonDateRanges: [compareDateRange],
  //   dateRangeType,
  //   comparisonType: comparisonPeriod
  // }

  const renderDateRangeFilter = () => {
    return(
      <div>
        <div className='section-titles forge-typography--overline'>Time frame</div>
        <div className='time-frame-container d-flex flex-column'>
          <div className='time-filter'>
            <div className="forge-typography--caption">Date range</div>
            <div className="d-flex">
              <DateRangeFilterWithBadge
                currentDrilldownTemplateId={currentTemplateId}
                key='current-period-date-range'
                {...currentDateRangeOptions}
                onDateFilterChange={(options) => onDateFilterChange(options, 'currentDateRangeOptions')}
              />
            </div>
          </div>
        </div>
      </div>
    )
  };

  const renderComparisonFilter = () => {
    return null;
    // return(
    //   <div className='time-filter forge-popup-host'>
    //     <div className="forge-typography--caption">Comparison period</div>
    //     <div className="d-flex">
    //       <ComparisonDateFilterChip
    //         currentDrilldownTemplateId={currentTemplateId}
    //         dateType={dateRangeType}
    //         isDashboardView={true}
    //         comparisonType={comparisonPeriod}
    //         dateRange={currentDateRange}
    //         comparisonDateRanges={[compareDateRange]}
    //         onRadarCompareDateRangesChange={
    //           (options) => onDateFilterChange(options, 'previousDateRangeOptions')
    //         }
    //         onComparePopUpToggle={onComparePopUpToggle}
    //       />
    //     </div>
    //     <div className={compareClassNames}>
    //       <div key={yearText}>{yearText}</div>
    //     </div>
    //   </div>
    // )
  }

  return(
    <div className='radar-filter'>
      {renderDateRangeFilter()}
      {renderComparisonFilter()}
    </div>
  )
}

MapCanvasTimeFrames.defaultProps = {

};
MapCanvasTimeFrames.propTypes = {
  currentDateRangeOptions: PropTypes.object,
  previousDateRangeOptions: PropTypes.object,
  currentTemplateId: PropTypes.string,
  onDateFilterChange: PropTypes.func,
  onComparePopUpToggle: PropTypes.func
}

export default MapCanvasTimeFrames;
