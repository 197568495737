import moment from 'moment';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import {
  getPeriodType,
  getTailingDropStartDate,
  isYearOnYear
} from 'modules/visualization/LineChart/vizOptionsHelper';
import { getInBetweenYearsForDateRange } from 'helpers/dateHelper';
import { filterCompareYearRanges } from 'common/contentFormatter/helper';
import { getQuarterMonths } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import {
  HISTORICAL_FORECAST,
  LINEAR_FORECAST,
  PROPHET_FORECAST,
  HISTORICAL_TOTAL_DATA_COLOR,
  PROPHET_FORECAST_COLOR,
  HISTORICAL_FORECAST_COLOR,
  EXPONENTIAL_FORECAST_COLOR,
  FORECASTING_TYPE
} from 'appConstants';
import { isValidDateSummaryData } from 'pages/Forecasting/ForecastHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
export const getLineChartSummaryFormatter = (summaryData,
  summaryTableOptions) => {

  let formattedData = _.get(summaryData, 'formatData');
  if (_.size(formattedData) === 1 && _.isEmpty(formattedData[0]['meta'])) {
    return
  }

  const dimensionConfigs = _.get(summaryData, 'dimensionConfigs');
  const tableHeaders = _.get(summaryTableOptions, 'isForecastingChart', false ) ?
    getForecastTableHeaders(summaryTableOptions) :
    getTableHeaders(formattedData, summaryTableOptions, dimensionConfigs);
  const tableData = getSummaryTableRows(formattedData, summaryTableOptions);

  return {
    tableHeaders,
    tableData
  }
}

export const getSummarySubHeader = (projectionType, projectionPercent) => {
  if(projectionType == LINEAR_FORECAST){
    return "Some values are projected based on a Linear model";
  } else if (projectionType == PROPHET_FORECAST){
    return "Some values are projected based on a Prophet model";
  } else if (projectionType == HISTORICAL_FORECAST){
    return `Some values are projected based on a ${projectionPercent}%
      growth rate applied to last year's values`;
  } else {
    return "Some values are projected based on a Exponential model";
  }
}

export const getFormattedNullDateTableData = (
  nullDateRecords, summaryTableData, metricEntry, secondaryMetricEntry
) => {
  const { tableHeaders, tableData } = summaryTableData;
  const nullDateDataHeaders = getNullDateDateTableHeaders(metricEntry, secondaryMetricEntry);
  const headers = _.isEmpty(tableData) ? nullDateDataHeaders : tableHeaders;

  _.each(nullDateRecords, (record) => {
    _.each(headers, (header) => {
      if(header.columnField == 'dimension'){
        record[header.columnField] = record['period'];
      } else if(header.isSecondary){
        const formattedNumberValue = getFormattedNumberValue(record['secondary_total'], secondaryMetricEntry);
        record[header.columnField] = formattedNumberValue;
      } else{
        const formattedNumberValue = getFormattedNumberValue(record['value'], metricEntry);
        record[header.columnField] = formattedNumberValue;
      }
    });
  });

  return {
    tableData: nullDateRecords,
    tableHeaders: headers
  };
}

const getNullDateDateTableHeaders = (metricEntry, secondaryMetricEntry) => {
  const primaryMetricName = getPrimaryMetricName(metricEntry);
  let tableHeaders = [
    { name:"Category", columnField: "dimension" },
    { name: primaryMetricName, columnField: primaryMetricName }
  ];

  if(!_.isEmpty(secondaryMetricEntry)){
    const secondaryMetricName = secondaryMetricEntry.name

    tableHeaders.push({
      name: secondaryMetricName, columnField: secondaryMetricName, isSecondary: true
    });
  }
  return tableHeaders;
}

const getTableHeaders = (formattedData, summaryTableOptions, dimensionConfigs) => {
  const { secondaryMetricEntry, viewEntry, compareYearRanges } = summaryTableOptions;
  const filterFormattedData = _.filter(formattedData, (datum) => {
    if (!_.isEmpty(datum['meta']) && !_.isEmpty(datum['x']) && _.get(datum, 'visible', false) === true) {
      return datum
    }
  });

  const isSecondaryMetric = !_.isEmpty(secondaryMetricEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
  const primaryMetricName = _.get(viewEntry, 'name');

  let tableHeaders = [];

  _.forEach(filterFormattedData, (datum) => {
    const { segmentType } = _.get(datum, 'meta');
    const isSecondary = _.get(datum, 'meta.value', '') === "secondary";
    let titleName = !_.isEmpty(_.get(datum, 'meta.traceId', '')) ?
      _.get(datum, 'meta.traceId', '') : _.get(datum, 'meta.dimension', '');
    const nameWithoutSecondary = titleName;
    if (isSecondaryMetric) {
      const metricName = isSecondary ? secondaryMetricName : primaryMetricName;
      titleName = titleName + ' ' + metricName
    }

    let color = _.get(datum, 'line.color');
    color = _.isEmpty(color) ? _.get(datum, 'marker.color') : color;

    const indexOfHeader = _.findIndex(tableHeaders, function (tableHeader) {
      return _.get(tableHeader, 'name') === titleName;
    });
    const indexDimension = _.findIndex(dimensionConfigs, function (dimensionConfig) {
      return _.get(dimensionConfig, 'traceId') === nameWithoutSecondary;
    });
    let comparisonName = titleName;
    if (segmentType === 'comparison') {
      comparisonName = titleName.replace("Compare ", "");
    }

    if (indexOfHeader === -1) {
      const header = {
        name: comparisonName || titleName,
        color: color,
        dash: _.get(datum, 'line.dash'),
        columnField: titleName,
        isSecondary: isSecondary,
        sort: indexDimension
      }
      tableHeaders.push(header);
    }
  });
  const comparisonYears = getYearsCompareRange(summaryTableOptions);
  tableHeaders = _.size(comparisonYears) > 0 ? tableHeaders :
    _.sortBy(tableHeaders, function (header) { return header.sort; });
  if(compareYearRanges.length > 1){
    tableHeaders = _.orderBy(tableHeaders, 'name', 'desc');
  }
  tableHeaders.unshift({ name: "Category", columnField: "dimension" })

  return tableHeaders;
}

const getForecastTableHeaders = (summaryTableOptions) => {
  const forecastModelOptions = _.get(summaryTableOptions, 'forecastModelOptions')
  let tableHeaders = [{ name: "Date", columnField: "dimension" }];
  let sort = 0;
  const totalLine = {
    name: "Historical data",
    color: HISTORICAL_TOTAL_DATA_COLOR,
    columnField: 'Total',
    isSecondary: false,
    sort: sort
  }
  tableHeaders.push(totalLine);

  _.forEach(forecastModelOptions, (modelDatum) => {
    let line = {
      name: modelDatum.name,
      sort: sort +1,
      columnField: modelDatum.name,
      isSecondary: false,
    }
    if (modelDatum.type == FORECASTING_TYPE.PROPHET) {
      line['color'] = PROPHET_FORECAST_COLOR;
    }
    if (modelDatum.type == FORECASTING_TYPE.HISTORICAL_AVG) {
      line['color'] = HISTORICAL_FORECAST_COLOR;
    }
    if (modelDatum.type == FORECASTING_TYPE.SIMPLE_EXPONENTIAL) {
      line['color'] = EXPONENTIAL_FORECAST_COLOR;
    }

    tableHeaders.push(line);
  });
  return tableHeaders;
}

const getSummaryTableRows = (formattedData, summaryTableOptions) => {
  const { viewEntry, secondaryMetricEntry, projectionEnabled, axisGranularity, dateRange,
    isComparisonEnabled, isForecastingChart, renderTimeFrame } = summaryTableOptions;
  const tailingDropStartDate = getTailingDropStartDate(summaryTableOptions);
  let tailingDropQuarterPeriod;
  const filterFormattedData = _.filter(formattedData, (datum) => {
    if(!_.isEmpty(datum['x'])){
      return datum
    }
  });

  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondaryMetric = !_.isEmpty(secondaryMetricEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
  const primaryMetricName = _.get(viewEntry, 'name');
  const periodType = getPeriodType(summaryTableOptions);
  const isQuarterPeriod =  _.get(summaryTableOptions, 'axisGranularity', '') == 'quarter';
  if(isQuarterPeriod){
    const periodMonth = moment(tailingDropStartDate).month();
    const quarterMonths = getQuarterMonths();
    // Adding one year as quarter will be 3 months as it will be next year will be the trailing start date.
    const quarterYear = periodMonth < 10 ? moment(tailingDropStartDate).year() :
      moment(tailingDropStartDate).year() + 1 ;
    tailingDropQuarterPeriod = `${quarterMonths[periodMonth]} ${quarterYear}`;
  }
  let customFormatData = [], badgeName;

  _.forEach(filterFormattedData, (datum) => {

    const periodWeekLabels = _.get(datum, 'weekPeriodLabel', []);
    const periodLabels = ((periodType == 'week' || isQuarterPeriod) && !_.isEmpty(periodWeekLabels)) ?
      periodWeekLabels : _.get(datum, 'x', []);

    const periodValues = _.get(datum, 'y', []);
    const isProjection = _.get(datum, 'meta.isProjection');
    const periodCustomValues = _.get(datum, 'customData', []);
    const isSecondary = _.get(datum, 'meta.value', '') === "secondary";

    let dimensionField = !_.isEmpty(_.get(datum, 'meta.traceId', '')) ?
      _.get(datum, 'meta.traceId', '') : _.get(datum, 'meta.dimension', '');

    if (isSecondaryMetric) {
      const metricName = isSecondary ? secondaryMetricName : primaryMetricName;
      dimensionField = dimensionField + ' ' + metricName
    }

    const rawDimensionField = `raw_${dimensionField}`;
    _.forEach(periodLabels, (period, index) => {

      const validForecastDate =  isForecastingChart && isValidDateSummaryData(period, summaryTableOptions);
      if((isProjection && index < 1) || validForecastDate){
        return;
      }

      const formatText = isYearOnYear({ renderTimeFrame }) ?
        getPeriodFormatTypeForComparisionMode(summaryTableOptions) :
        getPeriodFormatType(summaryTableOptions);
      const customPeriod = _.get(periodCustomValues[index],'period');
      const labelDate =  moment(period).format(formatText);
      const newLabelDate = labelDate === "Invalid date" ?
        moment(customPeriod).format(formatText) :
        labelDate;
      const isComparisonOnYearGranularity = isYearOnYear({ renderTimeFrame }) && periodType == 'year';
      const tailingDropStartDateLabel = moment(tailingDropStartDate).format(formatText);
      const yearsDifference = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'years');
      // For day wise summary table doesn't show multiple year data.
      const isMultiFiscalYear = axisGranularity === 'day' && yearsDifference >= 1 && isComparisonEnabled;
      let periodLabel = (labelDate === "Invalid date") ? period : labelDate;
      periodLabel = isMultiFiscalYear && typeof(period) != 'object' ? period : periodLabel;
      periodLabel = isComparisonOnYearGranularity ? _.toString(period) : periodLabel;

      let periodIndex = 1;
      let rawPeriodLabel = moment(customPeriod).format("DD/MM/YYYY");
      badgeName='';
      const isSameYearForInComplete = moment(tailingDropStartDate).format('YYYY') ==
        moment(customPeriod).format('YYYY');

      if(isProjection){
        periodLabel = isForecastingChart ? periodLabel : `${periodLabel} - Projected`;
        periodIndex = 3;
        badgeName = "Projected";
      } else if(isQuarterPeriod && tailingDropQuarterPeriod == periodLabel && projectionEnabled){
        periodLabel =  isForecastingChart ? periodLabel: `${periodLabel} - Incomplete`;
        periodIndex = 2;
        badgeName = "Incomplete";
      } else if(tailingDropStartDateLabel == newLabelDate && projectionEnabled && isSameYearForInComplete) {
        periodLabel =  isForecastingChart ? periodLabel : `${periodLabel} - Incomplete`;
        periodIndex = 2;
        badgeName = "Incomplete";
      }

      let isValidPeriod = true;
      if(labelDate !== "Invalid date" && !isForecastingChart){
        isValidPeriod = !_.isNull(periodValues[index]);
      } else if(isQuarterPeriod && labelDate == "Invalid date" && !isForecastingChart){
        isValidPeriod = !_.isNull(periodValues[index]) ;
      }

      if(isQuarterPeriod && !isProjection){
        isValidPeriod = moment(customPeriod) <= tailingDropStartDate;
      }

      const entry = isSecondary ? secondaryMetricEntry : viewEntry;
      const isDisplayTimeFormat = isSecondary ? isSecondsFormatSecondary : isSecondsFormat;

      let periodValue = 0;
      let rawPeriodValue = 0;
      let value = '';
      if (isDisplayTimeFormat) {
        const datePeriod = isSecondary ? 'secondary_total' : 'value';
        value = _.get(periodCustomValues[index], datePeriod);
        periodValue = getFormattedNumberValue(value, entry);
        rawPeriodValue = value;
      } else {
        value = periodValues[index];
        periodValue = getFormattedNumberValue(periodValues[index], entry);
        rawPeriodValue = value;
      }

      periodValue = _.isNaN(periodValues[index]) ? '-' : periodValue;
      rawPeriodValue =  _.isNaN(periodValues[index]) ? '-' : rawPeriodValue;
      const indexOfData = _.findIndex(customFormatData, function (tableData) {
        return _.get(tableData, 'dimensionMapping') === periodLabel;
      });
      if (indexOfData === -1) {
        if (isValidPeriod) {
          const lineData = {
            isGroup: false,
            dimensionMapping: periodLabel,
            dimension: periodLabel,
            raw_dimension: rawPeriodLabel,
            dimensionTotal: '',
            [dimensionField]: periodValue,
            [rawDimensionField]: rawPeriodValue,
            periodIndex,
            badgeName: badgeName
          }

          if(_.isNil(value) && !isForecastingChart) {
            return;
          } else {
            customFormatData.push(lineData);
          }
        }
      } else {
        customFormatData[indexOfData][dimensionField] = periodValue;
        customFormatData[indexOfData][rawDimensionField] = rawPeriodValue;
      }
    });

  });

  // const sortedData = _.sortBy(customFormatData, 'periodIndex');
  return customFormatData;
}

const getYearsCompareRange = (summaryTableOptions) => {
  const { compareYearRanges, dateRange } = summaryTableOptions;

  const filteredCompareYearRanges = filterCompareYearRanges(compareYearRanges, dateRange);
  const comparisonDateRange = _.size(filteredCompareYearRanges) == 1 ? _.first(compareYearRanges) : {}
  const inBetweenYearsForcomparisonDateRange = getInBetweenYearsForDateRange(comparisonDateRange);
  const comparisonYears = _.map(inBetweenYearsForcomparisonDateRange, (year, index) => {
    const isLast = ((inBetweenYearsForcomparisonDateRange.length - 1) === index);
    return { year, segmentType: 'comparison', isLast };
  });

  return comparisonYears;
}

const getPeriodFormatType = (summaryTableOptions) => {
  const periodType = getPeriodType(summaryTableOptions)
  switch (periodType) {
    case 'year':
      return 'yy';
    case 'month':
      return 'MMM yy';
    case 'week':
      return '';
    case 'day':
      return 'MMM DD, YYYY';
    default:
      return undefined;
  }
}

const getPeriodFormatTypeForComparisionMode = (summaryTableOptions) => {
  const periodType = getPeriodType(summaryTableOptions)
  switch (periodType) {
    case 'month':
      return 'MMM';
    case 'week':
      return '';
    case 'day':
      return 'MMM DD';
    default:
      return undefined;
  }
}
