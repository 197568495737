import { getDefaultShapeDatasetEntry } from "common/config/templateConfiguration";

export const getMapCanvasStateFromUrl = (initialState, locationState) => {
  const currentCanvasDateRange = _.get(locationState, 'currentCanvasDateRange');
  const currentMapCanvasDateType = _.get(locationState, 'currentCanvasDateType');
  const relativeDateFilterEntry = _.get(
    locationState, 'mapCanvas.currentDateRangeOptions.relativeDateFilterEntry', {}
  )
  const currentRelativeDateFilterEntry = _.isEmpty(relativeDateFilterEntry) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(locationState, 'mapCanvas.currentDateRangeOptions.relativeDateFilterEntry'), {})
    );

  const previousDateRange = _.get(locationState, 'previousCanvasDateRange');
  const previousDateType = _.get(locationState, 'previousCanvasDateType');
  const previousDateComparisonPeriod = _.isEmpty(_.get(locationState,
    'previousCanvasDateComparisonPeriod', {})) ? '' :
    _.get(initialState, 'mapCanvas.previousDateRangeOptions.comparisonPeriod', {});
  const shapeDatasetEntryId = _.get(locationState, 'shapeDatasetEntryId');
  const selectedShapeDatasetEntry =  getDefaultShapeDatasetEntry('', shapeDatasetEntryId);

  return {
    currentDateRangeOptions: {
      dateRange: currentCanvasDateRange,
      dateType: currentMapCanvasDateType,
      relativeDateFilterEntry: currentRelativeDateFilterEntry
    },
    previousDateRangeOptions: {
      dateRange: previousDateRange,
      dateType: previousDateType,
      comparisonPeriod: previousDateComparisonPeriod
    },
    selectedShapeIds: JSON.parse(_.get(locationState, 'selectedShapeIds', '[]')),
    selectedTemplateIds: JSON.parse(_.get(locationState, 'selectedTemplateIds', '[]')),
    selectedShapeDatasetEntry
  }
}

export const getMapCanvasStateToUrl = (state) => {
  const currentCanvasDateRange = _.get(state, 'mapCanvas.currentDateRangeOptions.dateRange');
  const currentCanvasDateType = _.get(state, 'mapCanvas.currentDateRangeOptions.dateType');
  const relativeDateFilterEntry = _.get(
    state, 'mapCanvas.currentDateRangeOptions.relativeDateFilterEntry', {}
  )
  const currentRelativeDateFilterEntry = _.isEmpty(relativeDateFilterEntry) ? "" :
    encodeURIComponent(
      JSON.stringify(_.get(state, 'mapCanvas.currentDateRangeOptions.relativeDateFilterEntry'), {})
    );

  const previousCanvasDateRange = _.get(state, 'mapCanvas.previousDateRangeOptions.dateRange');
  const previousCanvasDateType = _.get(state, 'mapCanvas.previousDateRangeOptions.dateType');
  const previousCanvasDateComparisonPeriod = _.isEmpty(_.get(state,
    'mapCanvas.previousDateRangeOptions.comparisonPeriod', {})) ? '' :
    _.get(state, 'mapCanvas.previousDateRangeOptions.comparisonPeriod', {});

  const selectedShapeDatasetId = _.get(state, 'mapCanvas.selectedShapeDatasetEntry.id');

  const selectedShapeIds = _.isEmpty(_.get(state, 'mapCanvas.selectedShapeIds', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'mapCanvas.selectedShapeIds')));
  const selectedTemplateIds = _.isEmpty(_.get(state, 'mapCanvas.selectedTemplateIds', [])) ? ''
    : encodeURIComponent(JSON.stringify(_.get(state, 'mapCanvas.selectedTemplateIds')));

  return {
    shapeDatasetEntry: _.get(state, 'mapCanvas.shapeDatasetEntry', {}),
    currentCanvasDateRange,
    currentCanvasDateType,
    currentRelativeDateFilterEntry,
    previousCanvasDateRange,
    previousCanvasDateType,
    previousCanvasDateComparisonPeriod,
    selectedShapeDatasetId,
    selectedShapeIds,
    selectedTemplateIds
  }
}
