// Vendor imports.
import _ from 'lodash';

// Project Imports
import { getFormattedValue } from 'helpers/chartDataHelper';
import {
  isStackTypeChart,
  isBenchMarkEmpty,
  formatDimensionText,
  getViewLabel,
  getWrapTickText
} from '../vizOptionsHelper';
import { getStackedBarChartColors } from 'helpers/colorHelper';
import {
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  MORE_THAN_FIFTY_TEXT,
  BENCHMARK_TEXT
} from 'appConstants';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getSortByType, sortGroupByData } from '../barChartHelper';
import { getGroupByEntityField } from 'helpers/snapshotRendererHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime, getMinSecondsAsNumeric } from 'helpers/visualizationHelper';

// const groupTitleStyle = 'fill:#4253b1; font-size:14px; font-weight: bold;'
const groupTitleStyle = `fill:#212121; 
    font-size:13px;
    font-weight: bold;
    letter-spacing:2px; 
    font-family: 'Roboto'`;

// TODO: Need to changes the input and output format in the below comment.
/*-------------------------------------------------------------------------------
 Input:
---------------------------------------------------------------------------------
[
  {
    "dimension":"Exceptional Clearance",
    "group_by_field":"Pinellas Park Police Department",
    "count":"15",
    "min_count":"Battery",
    "max_count":"Theft (Misdemeanor)",
    "secondary_count":"1475",
    "view":"Closed"
  },
  {
    "dimension":"Protective Custody",
    "group_by_field":"Pinellas Park Police Department",
    "count":"1",
    "min_count":"Emotional Crisis",
    "max_count":"Emotional Crisis",
    "secondary_count":"1475",
    "view":"Open"
  },
  {
    "dimension":"Protective Custody",
    "group_by_field":"Pinellas Park Police Department",
    "count":"883",
    "min_count":"Animal Complaint",
    "max_count":"Trespass Warning Issued",
    "secondary_count":"1475",
    "view":"Closed"
  },
  ....
]
---------------------------------------------------------------------------------
Output:
---------------------------------------------------------------------------------
[
  [
    {
      label: '100',
      secondaryLabel: '200',
      value: 'Pinellas - Education',
      text: '$100',
      secondaryText: '$200',
      customData: ['Pinellas', 'Education'],
      ticktext: 'Education',
      color: '#fff'
    },
   ....
  ],
  ....
]*/

export function formatCompareViewData(apiDataEntries, vizOptions) {
  return isStackTypeChart(vizOptions) ?
    formatCompareViewStackBarData(apiDataEntries, vizOptions) :
    formatCompareViewNormalBarData(apiDataEntries, vizOptions);
}

function formatCompareViewNormalBarData(apiDataEntries, vizOptions) {
  const {
    benchMarkEntries, groupByViewType, viewEntry, secondaryMetricEntry,
    templateId, isCurrencyGroupByField, onFormattedDataLoad, groupByEntry, groupType } = vizOptions;
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const sortByEntry = getSortByType(vizOptions, true);

  const options = {
    viewEntry, secondaryMetricEntry, templateId,
    compareViewType, sortByEntry, isCurrencyGroupByField,
    groupByEntry, groupType
  };

  let emptyBucket = {};
  let formattedData = apiEntriesFormatter(apiDataEntries, options);

  onFormattedDataLoad(_.flatten(formattedData));

  if (!isBenchMarkEmpty(benchMarkEntries) && !_.isEmpty(formattedData)) {
    const bucketOption = {
      dimension: '',
      isBenchMark: true,
      templateId,
      isGroup: false,
      options,
      period:BENCHMARK_TEXT
    }
    const benchMarkBucket = getEmptyBucket(bucketOption);
    return [[benchMarkBucket], ...formattedData];
  }

  return [...formattedData, [emptyBucket]];
}

function formatCompareViewStackBarData(apiDataEntries, vizOptions) {
  const {
    benchMarkEntries, groupByViewType, viewEntry, secondaryMetricEntry,
    templateId, isCurrencyGroupByField, onFormattedDataLoad,
    groupByEntry, groupType
  } = vizOptions;
  const nullValueLabel = getNullValueLabel(templateId);
  const stackColors = getStackedBarChartColors(apiDataEntries, nullValueLabel);
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const sortByEntry = getSortByType(vizOptions, true);
  const options = {
    stackColors,
    viewEntry,
    nullValueLabel,
    isStackedChart: true,
    secondaryMetricEntry,
    templateId,
    compareViewType,
    isCurrencyGroupByField,
    sortByEntry,
    groupByEntry,
    groupType
  };
  const formattedData = apiEntriesFormatter(apiDataEntries, options);

  onFormattedDataLoad(_.flatten(formattedData));

  if (!isBenchMarkEmpty(benchMarkEntries) && !_.isEmpty(formattedData)) {
    const bucketOption = {
      dimension: ' ',
      isBenchMark: true,
      templateId,
      isGroup: false,
      options
    }
    const benchMarkBucket = getEmptyBucket(bucketOption);
    formattedData[formattedData.length - 1].push(benchMarkBucket);
  }

  return formattedData;
}

const apiEntriesFormatter = (apiDataEntries, options) => {
  const { sortByEntry } = options;

  let formattedData = _.chain(apiDataEntries).
    groupBy((entry) => getGroupByEntityField(_.get(entry, 'group_by_field'))).
    map((dimensions, category) => formatDimensionData(dimensions, category, options)).
    value() || [];
  formattedData = sortGroupByData(formattedData, sortByEntry);
  formattedData = _.map(formattedData, 'dimensionEntries');
  return  formattedData;
  // return isStackedChart ? formattedData : _.reverse(formattedData);
}

export function getEmptyBucket(bucketOption) {
  const { dimension, isBenchMark, templateId, isGroup, options, period } = bucketOption;
  const { viewEntry, secondaryMetricEntry } = options

  const dimensionValue = _.isNull(dimension) ? '' : formatDimensionText(dimension, false, templateId);
  const formattedDimensionText = getWrapTickText(dimensionValue, templateId)
  const ticktext = isBenchMark || _.isNull(dimension) ? '' :
    (`<span style="${groupTitleStyle}">${formattedDimensionText}      </span>`);

  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const countLabel = isSecondsFormat ? getMinSecondsAsNumeric() : 0;
  const secondaryCountLabel = isSecondsFormatSecondary ? getMinSecondsAsNumeric() : 0;

  return {
    label: countLabel,
    color: isBenchMark ? 0 : '#fff',
    secondaryLabel: secondaryCountLabel,
    actualLabel: 0,
    actualSecondaryLabel: 0,
    value: isBenchMark && !_.isNull(dimension) ? ' ' : dimensionValue,
    text: null,
    secondaryText: null,
    customData: isBenchMark || _.isNull(dimension) ? [] : [{ isTitlePlaceholder: true }],
    ticktext,
    isGroup,
    period: period
  }
}

export function getSeeMoreEmptyDimensionBucket(dimension, templateId, category, options, period='') {
  const {viewEntry, secondaryMetricEntry } = options;
  const dimensionValue = _.isNull(dimension) ? '' : formatDimensionText(dimension, false, templateId);
  const value = `${dimensionValue}-${templateId}`;
  const ticktext = "";
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const countLabel = isSecondsFormat ? getMinSecondsAsNumeric() : 0;
  const secondaryCountLabel = isSecondsFormatSecondary ? getMinSecondsAsNumeric() : 0;

  return {
    label: countLabel,
    color: '#fff',
    secondaryLabel: secondaryCountLabel,
    actualLabel: 0,
    actualSecondaryLabel: 0,
    value: value,
    text: `  <span style="fill:#4253b1"><i>${MORE_THAN_FIFTY_TEXT}</i></span>`,
    secondaryText: null,
    customData: [category],
    ticktext,
    isGroup: true,
    isSeeMoreDimension: true,
    period: period
  }
}

const formatDimensionData = (dimensions, category, options) => {
  const { templateId, isStackedChart, isCurrencyGroupByField } = options;
  const dimensionEntries = _.map(dimensions, (entry) => {
    return getFormattedBarChartEntry(entry, options);
  });

  let groupByDimension = getGroupByEntityField(category);

  const limitedDimensionEntries = _.chain(dimensionEntries).
    orderBy('label', 'desc').
    value();

  if (isCurrencyGroupByField) {
    groupByDimension = formatValueToCurrency(groupByDimension, true);
  }
  const bucketOption = {
    dimension: groupByDimension,
    isBenchMark: false,
    templateId,
    isGroup: true,
    options,
    period: groupByDimension
  }
  const emptyBucket = getEmptyBucket(bucketOption);
  const isMoreThan50dimension = _.get(dimensions, "[0].is_remaining_dimension", false);

  let seeMoreResultBucket = {};
  if (isMoreThan50dimension) {
    const period = _.get(dimensions, "[0].period");
    seeMoreResultBucket = getSeeMoreEmptyDimensionBucket(
      groupByDimension, templateId, category, options, period
    );
  }

  let formattedDimensionEntries = []
  if (isStackedChart) {
    if (_.isEmpty(seeMoreResultBucket)) {
      formattedDimensionEntries = [emptyBucket, ...limitedDimensionEntries];
    } else {
      formattedDimensionEntries = [emptyBucket, ...limitedDimensionEntries, seeMoreResultBucket];
    }

  } else {
    if (_.isEmpty(seeMoreResultBucket)) {
      formattedDimensionEntries = [emptyBucket, ...limitedDimensionEntries];
      // formattedDimensionEntries = [...limitedDimensionEntries, emptyBucket];
    } else {
      formattedDimensionEntries = [emptyBucket, ...limitedDimensionEntries, seeMoreResultBucket];
    }
  }
  return {
    category,
    count: _.sumBy(dimensions, (dimensionEntry) => Number(_.get(dimensionEntry, 'count', 0))),
    dimensionEntries: formattedDimensionEntries
  };
}

const getFormattedBarChartEntry = (apiDataEntry, options) => {
  const { count, secondary_count, view, dimension, group_by_field, period } = apiDataEntry;
  const {
    isStackedChart,
    viewEntry,
    nullValueLabel,
    stackColors,
    secondaryMetricEntry,
    templateId,
    viewMode
  } = options;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const viewLabel = getViewLabel(view, nullValueLabel);
  const groupByDimension = getGroupByEntityField(group_by_field);
  const dimensionValue = getGroupByEntityField(dimension);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const dataUnit = timeDurationDataUnit(viewEntry);
  const secondaryDataUnit = timeDurationDataUnit(secondaryMetricEntry);

  const countLabel = isSecondsFormat ? getSecondsAsNumericTime(count, dataUnit) : count;
  const secondaryCountLabel = isSecondsFormatSecondary ?
     getSecondsAsNumericTime(secondary_count, secondaryDataUnit) : secondary_count;

  let formattedBarChartEntry = {
    label: countLabel || 0,
    color: (_.isEmpty(stackColors) ? 0 : _.get(stackColors, viewLabel, 0)),
    secondaryLabel: secondaryCountLabel || 0,
    actualLabel: Number(count) || 0,
    actualSecondaryLabel: Number(secondary_count) || 0,
    value: `${groupByDimension}-${dimensionValue}`,
    text: getFormattedValue(count, viewEntry, false, isSmallView),
    secondaryText: getFormattedValue(secondary_count, secondaryMetricEntry,
      false, isSmallView),
    hoverText: getFormattedValue(count, viewEntry),
    secondaryHoverText: getFormattedValue(secondary_count, secondaryMetricEntry),
    customData: [groupByDimension, dimensionValue],
    ticktext: getWrapTickText(dimensionValue, templateId),
    groupByField: groupByDimension,
    period: period
  }

  if (isStackedChart) {
    return {
      ...formattedBarChartEntry,
      view: viewLabel,
      formattedViewCount: getFormattedValue(count, viewEntry)
    }
  }

  return formattedBarChartEntry;
};
