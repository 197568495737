import _ from 'lodash';
import * as commonPropTypes from "common/propTypes";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ForgeButton, ForgeCard, ForgeCircularProgress } from '@tylertech/forge-react';

import { withRouter } from 'react-router-dom';
import BookmarkTitle from './BookmarkTitle';
import LoadingSpinner from 'common/components/LoadingSpinner';

import { getCurrentVizBasedChartType } from 'helpers/visualizationHelper';
import {
  isViewChanged,
  getBookmarkDefaultParams,
  getAdditionalDateRangeFilters,
  getBookmarkType,
  isChangeDateRange,
  isIncludeBookmarkDateRange
} from '../bookmarkHelper';
import { trackEvent } from 'helpers/eventTracking';
import { updateCurrentBookmark } from 'actions/bookmarkActions';

import { createBookmark, getLinkBookmarkCollections, updateBookmark } from 'common/api/bookmarksApi';
import { viewSavedMessage, viewSavedErrorMessage } from 'helpers/toastMessages';
import {
  BOOKMARK_COLLECTION,
  COLLECTION_ID,
  EMAIL_STRATEGY_OPTIONS,
  SAVE_VIEW_INFO_TEXT,
  SEARCH_BAR_VIEW
} from 'appConstants';
import CheckBox from 'common/components/CheckBox';
import { getDateRangeTextBasedOnType } from 'helpers/dateHelper';
import CustomTooltip from 'common/components/CustomTooltip';
import { setCurrentCollectionId } from 'actions/dashboardActions';
import { showDateFilter } from 'common/config/viewConfiguration';
import { isViewerRole } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';
import BookmarkEditDateRangeInclude from './BookmarkEditDateRangeInclude';
import { createCard } from 'common/api/collectionApi';
import ManageBookmarkUpdate from './ManageBookmarkUpdate';

class BookmarkSaveButton extends Component {
  constructor(props, context) {
    super(props, context);
    const { currentBookmarkId, currentBookmarkName, commonFilters } = props;
    const { comparisonModeOn } = commonFilters;
    this.state = {
      showSaveAsDropDown: false,
      showEditDateDropDown: false,
      showSaveAnywayOrCopyModal: false,
      name: currentBookmarkName + ' copy',
      description: "",
      isLoading: false,
      isSaveState: !_.isEmpty(currentBookmarkId),
      includeDateRange: comparisonModeOn ? true : false,
      includeAdditionalDateRange: false,
      myViewCollectionId: 0
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    const { isSaveState, includeDateRange } = this.state;
    const {
      commonFilters,
      currentBookmarkId,
      currentBookmark
    } = this.props;
    const { comparisonModeOn } = commonFilters;
    let newSaveState = false;
    if (!_.isEmpty(currentBookmarkId) && !_.isEmpty(currentBookmark)) {
      newSaveState = false;
      if (isViewChanged(this.props, currentBookmark)) {
        newSaveState = true;
      }
    }
    if (!_.isEqual(newSaveState, isSaveState)) {
      this.setState({ isSaveState: newSaveState });
    }
    if (comparisonModeOn && !includeDateRange) {
      this.setState({ includeDateRange: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getBookmarkName() {
    const { currentBookmarkName, plotlyChart, currentDrilldownViewEntry } = this.props;
    let name = currentBookmarkName;
    if (_.isEmpty(currentBookmarkName)) {
      const chart = plotlyChart();
      if (chart.querySelector('.visualization-title .viz-title')) {
        name = chart.querySelector('.visualization-title .viz-title').innerText;
      } else {
        name = _.get(currentDrilldownViewEntry, 'name');
      }
    }
    return name;
  }

  handleClickOutside = (e) => {
    const { showSaveAsDropDown, showEditDateDropDown, showSaveAnywayOrCopyModal } = this.state;

    const btnListContainer = document.querySelector('.bookmark-save-btn-card');
    const isOutsideButton = btnListContainer && !btnListContainer?.contains(e.target);

    if (this.saveButtonRef && !this.saveButtonRef.contains(e.target) && isOutsideButton) {
      if (showSaveAsDropDown) {
        this.setState({ showSaveAsDropDown: false, });
      }

      if (showEditDateDropDown) {
        this.setState({ showEditDateDropDown: false, });
      }

      if(showSaveAnywayOrCopyModal){
        this.setState({ showSaveAnywayOrCopyModal: false });
      }
    }
  }
  updateLastUrlData = () => {
    window.lastUrls = [];
    window.isUndoEnabled = false;
  }

  navigateToMyView = () => {
    const { myViewCollectionId } = this.state;
    const { dispatchSetCurrentCollectionId } = this.props;
    dispatchSetCurrentCollectionId(myViewCollectionId)
    this.props.history.push({ pathname: "/overview" });
  }

  CustomToastWithLink = () => (
    <div className="saved-view-toast">Success! Your view has been saved. Go to&nbsp;
      <a className="toast-nav-link" onClick={() => this.navigateToMyView()} >My Views</a>.
    </div>
  );

  createCollectionCard = (bookmarkId) => {
    const { selectedView, isManageCollection } = this.props;
    const params = {
      card_id: bookmarkId,
      bookmark_id: bookmarkId,
      isNewCard: false,
      isManageView: isManageCollection,
      is_exploration_card: false,
      is_new: true,
    };
    createCard(selectedView, params)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.error('Error while saving view. Please try again.', response.error);
        } else {
          console.log('View saved successfully.');
        }
      });
  }

  handleSaveAsClick = () => {
    const { name, description, includeDateRange, includeAdditionalDateRange } = this.state;
    const {
      currentVisualizationType, dispatchUpdateBookmark, userTargetEntryId, selectedView
    } = this.props;
    const withoutCollectionView = [SEARCH_BAR_VIEW, COLLECTION_ID.ALL_METRICS, BOOKMARK_COLLECTION.name];
    const isCollectionView = !_.includes(withoutCollectionView, selectedView);

    const params = {
      shareEmails: [],
      bookmark: getBookmarkDefaultParams(this.props, {
        name, description,
        is_new: !isCollectionView,
        hideGlobalDateRange: !includeDateRange,
        hideAdditionalDateRange: !includeAdditionalDateRange
      }),
      shareFieldInputs: {},
      include_current_user: true,
      userTargetEntryId,
      includeMyViewCollectionId: true
    };

    this.setState({ isLoading: true });
    createBookmark(params).
      then(response => response.json()).
      then((response) => {
        const bookmark = _.get(_.values(response), '[0]', {});
        if (isCollectionView) {
          this.createCollectionCard(_.get(bookmark, 'id'));
        }
        const myViewCollectionId = _.get(_.values(response), '[1]', 0);
        this.updateLastUrlData(); // reset lastUrls to avoid redirecting to last url
        dispatchUpdateBookmark(bookmark);
        this.setState({
          isLoading: false,
          showSaveAsDropDown: false,
          includeDateRange: false,
          includeAdditionalDateRange: false,
          showSaveAnywayOrCopyModal: false,
          myViewCollectionId: myViewCollectionId
        });

        if (response.error) {
          toast.error(viewSavedErrorMessage);
        } else {
          toast.success(this.CustomToastWithLink());
        }
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        toast.error(`Error while creating view. ${err}`);
        console.error(err);   // eslint-disable-line no-console
      }).
      then(() => {
        trackEvent('saved_view_created', {
          visualizationType: currentVisualizationType,
          ..._.pick(params, 'send_alerts', 'email_strategy')
        });
      });
  }

  saveBeforeCheckBookmark = () => {
    const { currentBookmark } = this.props;
    if (isChangeDateRange(this.props, currentBookmark) || !isIncludeBookmarkDateRange(currentBookmark)) {
      this.setState({ showEditDateDropDown: true });
      return true;
    }
    return false;
  }

  fetchLinkBookmarkCollections = async (bookmarkId) => {
    const params = {
      bookmark_id: bookmarkId
    };
    this.setState({ isLoading: true });
  
    try {
      const response = await getLinkBookmarkCollections(params);
      const data = await response.json();
      this.setState({ isLoading: false });
      return data;
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Error fetching bookmark collections:', error);
      throw error;  
    }
  }

  handleEditBookmark = async (isUpdateDateRange = true) => {
    const {
      currentBookmarkId,
      currentBookmark,
      onUpdateBookmark,
      currentVizBasedChartType
    } = this.props;
    const { includeDateRange, includeAdditionalDateRange, showEditDateDropDown } = this.state;

    if (!this.state.showSaveAnywayOrCopyModal) {
      const bookmarkCollections = await this.fetchLinkBookmarkCollections(currentBookmarkId);
      if (!_.isEmpty(bookmarkCollections) && _.size(bookmarkCollections) > 1) {
        this.setState({ showSaveAnywayOrCopyModal: _.size(bookmarkCollections) > 1});
        return;
      }
    }

    if (!showEditDateDropDown) {
      if (this.saveBeforeCheckBookmark()) {
        return;
      }
    }

    if (!_.isEmpty(currentBookmarkId)) {
      this.setState({ isLoading: true, showEditDateDropDown: false });
      const params = {
        ...getBookmarkDefaultParams(this.props, {
          name: this.getBookmarkName(),
          hideGlobalDateRange: !includeDateRange,
          hideAdditionalDateRange: !includeAdditionalDateRange
        }),
        email_options: _.get(currentBookmark, 'email_options', {}),
        email_strategy: _.get(currentBookmark, 'email_strategy', EMAIL_STRATEGY_OPTIONS[0].type),
        bookmarkOptions: {
          ..._.get(currentBookmark, 'bookmarkOptions', {}),
          isNoneDateSelected: includeDateRange ?
            !includeDateRange : _.get(currentBookmark, 'bookmarkOptions.isNoneDateSelected'),
          currentVizChartType: currentVizBasedChartType
        }
      }

      if (!isUpdateDateRange && includeDateRange) {
        const bookmarkCommonFilters = _.get(currentBookmark, 'commonFilters', {});
        params['commonFilters'] = bookmarkCommonFilters;
      }

      trackEvent('update_view');
      updateBookmark(currentBookmarkId, params)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ isLoading: false, showSaveAnywayOrCopyModal: false });
          this.updateLastUrlData(); // reset lastUrls to avoid redirecting to last url
          onUpdateBookmark(response);
          toast.success(viewSavedMessage);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toast.error(`Error while editing view. ${err}`);
          console.error(err); // eslint-disable-line no-console
        });
    }
  }

  toggleSaveAsDropdown = (showSaveAsDropDown) => {
    this.setState({ name: this.getBookmarkName() + ' copy', showSaveAsDropDown });
  }

  closeSaveAnywayOrCopyModal = () => {
    this.setState({ showSaveAnywayOrCopyModal: false });
  }

  handleInputFieldChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  }

  handleSaveDropDownButtonClick = () => {
    const { isSaveState } = this.state;
    if (isSaveState) {
      this.handleEditBookmark();
    } else {
      this.setState({ name: this.getBookmarkName() + ' copy', showSaveAsDropDown: true });
    }
  }

  handleCheckboxChange = (isChecked, key) => {
    this.setState({ [key]: isChecked });
  }

  getDateFilterText = (type, id) => {
    const { commonFilters } = this.props;
    const text = getDateRangeTextBasedOnType(commonFilters, type, id);

    return `Include time frame filter of ${text}`;
  }

  renderDateRangeCheckBox = (value, type, id = 0) => {
    const { drilldown, commonFilters, currentDrilldownViewEntry } = this.props;
    const { currentDrilldownTemplateId } = drilldown;
    const { comparisonModeOn } = commonFilters;
    const showDateRangeCheckbox = showDateFilter(currentDrilldownTemplateId, currentDrilldownViewEntry);

    return showDateRangeCheckbox && (
      <div className='d-flex align-items-center'>
        <CheckBox
          id={`date-range-checkbox-${type}`}
          index={`date-range-checkbox-${type}`}
          isChecked={value}
          isDisabled={comparisonModeOn}
          name={this.getDateFilterText(type, id)}
          onChange={(isChecked) => this.handleCheckboxChange(isChecked, type)}
        />
        <div className='pl-1 pb-2'>
          <CustomTooltip label={SAVE_VIEW_INFO_TEXT} placement='left'>
            <i className="icons-info-circle-icon"></i>
          </CustomTooltip>
        </div>
      </div>
    );
  }
  renderAdditionalDateRangeFilters = () => {
    const { includeAdditionalDateRange } = this.state;
    const additionalDateRangeFilters = getAdditionalDateRangeFilters(this.props);
    if (_.isEmpty(additionalDateRangeFilters)) {
      return null;
    }

    return _.map(additionalDateRangeFilters, (config) => {
      const { id } = config;
      return this.renderDateRangeCheckBox(includeAdditionalDateRange, 'includeAdditionalDateRange', id)
    });
  }

  handleEditDateRange = (isUpdateDateRange) => {
    const { currentBookmark } = this.props;
    const includeDate = isIncludeBookmarkDateRange(currentBookmark) || isUpdateDateRange;
    this.setState({ includeDateRange: includeDate }, () => {
      this.handleEditBookmark(isUpdateDateRange);
    })
  }

  toggleEditDropdown = (showEdit) => {
    this.setState({ showEditDateDropDown: showEdit });
  }

  renderBookmarkEditIncludeDateRange() {
    const { showEditDateDropDown } = this.state;

    if (!showEditDateDropDown) {
      return null;
    }

    const bookmarkProps = { ...this.props };

    return (
      <BookmarkEditDateRangeInclude
        onToggleEditDropdown={this.toggleEditDropdown}
        onHandleEditDateRange={this.handleEditDateRange}
        showEditDateDropDown={showEditDateDropDown}
        bookmarkProps={bookmarkProps} />
    )
  }

  renderBookmarkDropDown() {
    const { showSaveAsDropDown, name, description, includeDateRange } = this.state;

    if (!showSaveAsDropDown) {
      return null;
    }

    return (
      <ForgeCard className="bookmark-save-btn-card">
        {this.renderSpinner()}
        <BookmarkTitle
          name={name}
          description={description}
          onInputChange={this.handleInputFieldChange} />
        {this.renderDateRangeCheckBox(includeDateRange, 'includeDateRange')}
        {this.renderAdditionalDateRangeFilters()}
        <div className="dropdown-footer">
          <ForgeButton type="flat">
            <button
              tabIndex={0}
              aria-label="cancel"
              onClick={() => this.toggleSaveAsDropdown(false)}>
              Cancel
            </button>
          </ForgeButton>
          <ForgeButton type="raised" className="">
            <button
              disabled={_.isEmpty(name)}
              tabIndex={0}
              aria-label="Save"
              onClick={() => this.handleSaveAsClick(false)}>
              Save
            </button>
          </ForgeButton>
        </div>
      </ForgeCard>
    )
  }

  renderButtonLoading = () => {
    return (<ForgeCircularProgress className="mt-1"
        open="true"
        determinate="false"
        progress="0.5"
        style={{
          '--forge-circular-progress-size': `15px`
        }} />)
  }

  renderSaveDropDown() {
    const { isSaveState, isLoading, showSaveAnywayOrCopyModal } = this.state;
    const { currentBookmarkId, manageCollection } = this.props;
    if (!isSaveState || _.isEmpty(currentBookmarkId) || isViewerRole(manageCollection)) {
      return null;
    }
    return (
      <Dropdown as={ButtonGroup}>
        <ForgeButton type="raised">
          <button
            disabled={isLoading || showSaveAnywayOrCopyModal}
            onClick={this.handleEditBookmark}>
            {isLoading && this.renderButtonLoading()}
            Save
          </button>
        </ForgeButton>
        <Dropdown.Toggle split disabled={isLoading || showSaveAnywayOrCopyModal}
          variant="primary" className="save-dropdown-icon" aria-label="Save Options" />
        <Dropdown.Menu>
          <Dropdown.Item eventKey="2" variant="outline-primary"
            onClick={() => {
              trackEvent('initiate_save_as');
              this.toggleSaveAsDropdown(true);
            }}
          >
            Save as
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  renderSaveAnywayModal() {
    const { showSaveAnywayOrCopyModal, showEditDateDropDown } = this.state;
    if (!showSaveAnywayOrCopyModal || showEditDateDropDown) {
      return null;
    }

    return (
      <ManageBookmarkUpdate isLoading={this.state.isLoading} 
          closeSaveAnywayOrCopyModal={this.closeSaveAnywayOrCopyModal} 
          onHandleEditBookmark={this.handleEditBookmark} 
          onToggleSaveAsDropdown={this.toggleSaveAsDropdown} />
    )
  }

  renderSaveAsButton() {
    const { showSaveAsDropDown, isSaveState } = this.state;
    const { manageCollection } = this.props;
    if (isSaveState && !isViewerRole(manageCollection)) {
      return null;
    }
    return (
      <ForgeButton type="outlined">
        <button
          className="view-save-btn"
          onClick={() => {
            trackEvent('initiate_save_as');
            this.toggleSaveAsDropdown(!showSaveAsDropDown)
          }
          }
        >
          Save as
        </button>
      </ForgeButton>
    )
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  render() {
    return (
      <div className="d-flex bookmark-save-options" ref={(ref) => this.saveButtonRef = ref}>
        {this.renderSaveAsButton()}
        {this.renderSaveDropDown()}
        {this.renderSaveAnywayModal() }
        {this.renderBookmarkDropDown()}
        {this.renderBookmarkEditIncludeDateRange()}
      </div>
    )
  }
}

BookmarkSaveButton.propTypes = {
  drilldown: PropTypes.object,
  mapOptions: PropTypes.object,
  commonFilters: PropTypes.object,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentBookmark: PropTypes.object,
  visualization: PropTypes.object,
  currentBookmarkId: PropTypes.string,
  currentVizBasedChartType: PropTypes.string,
  currentBookmarkName: PropTypes.string,
  currentVisualizationType: PropTypes.string,
  dispatchUpdateBookmark: PropTypes.func,
  onUpdateBookmark: PropTypes.func,
  plotlyChart: PropTypes.func,
  userTargetEntryId: PropTypes.string,
  dispatchSetCurrentCollectionId: PropTypes.func,
  history: PropTypes.object,
  manageCollection: PropTypes.object,
  selectedView: PropTypes.string,
  isManageCollection: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetCurrentCollectionId: (collectionId) => {
      dispatch(setCurrentCollectionId(collectionId))
    },
    dispatchUpdateBookmark: (bookmark) => {
      const { id, name } = bookmark;
      const bookmarkType = getBookmarkType(bookmark);
      dispatch(updateCurrentBookmark(id, name, bookmarkType));
    }
  }
}

function mapStateToProps(state) {
  const visualization = _.get(state, 'visualization', {});
  const visualizationType = _.get(state, 'drilldown.currentVisualizationType');
  const viewEntry = _.get(state, 'drilldown.currentDrilldownViewEntry', {});
  return {
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', null),
    currentBookmarkName: _.get(state, 'bookmark.currentBookmarkName'),
    currentDrilldownViewEntry: viewEntry,
    currentVizBasedChartType: getCurrentVizBasedChartType(visualizationType, visualization),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    mapOptions: _.get(state, 'visualization.mapOptions'),
    drilldown: _.get(state, 'drilldown', {}),
    visualization,
    currentUser: _.get(state.currentUser, 'user', {}),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentVisualizationType: visualizationType,
    isManageCollection: _.get(state, 'dashboard.isManageCollection', false),
    selectedView: _.get(state, 'dashboard.selectedView', ''),
    manageCollection: _.get(state, 'dashboard.manageCollection', {})
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookmarkSaveButton)
);