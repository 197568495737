import { buildQueryString } from 'helpers/HttpHelper';

export const getMapCanvasPointsTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/map_canvas_within_tile.json?x={x}&y={y}&z={z}&' +
    buildQueryString(params);
};

export const getMapCanvasShapeTileUrl = (params) => {
  return `${window.location.protocol}//${window.location.host}`+
    '/api/shapes/within_tile.json?x={x}&y={y}&z={z}&' +
    buildQueryString(params);
};
