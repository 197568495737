// Vendor Imports
import _ from 'lodash';

// Project Imports
import {
  isCompareView,
  isGroupByStackBar,
  isParallelOrBulletChart,
  getChartType,
  isStackTypeChart,
  getBenchMarkValues
} from './vizOptionsHelper';
import { getTickText } from 'helpers/displayNameHelper';
import { VIEW_MODE, BAR_CHART_AREA_HEIGHT } from 'modules/visualization/constants';
import { COMPARE_VIEW_DRILLDOWN_OPTIONS } from 'appConstants';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';

const LABEL_TEXT_COUNT = 15;

export const getTickValsAndTickText = (formattedData, vizOptions) => {
  const { viewMode, isCurrencyDimensionField } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  let tickvals = [];
  let ticktext = [];
  const isGroupByStackBarChart = isGroupByStackBar(vizOptions);

  _.each(formattedData, (trace) => {
    tickvals = tickvals.concat(_.map(trace, 'value'));
    if (isGroupByStackBarChart) {
      ticktext = ticktext.concat(_.map(trace, 'ticktext'));
    } else {
      if (isSmallView) {
        ticktext = ticktext.concat(getTickText(_.map(trace, 'ticktext'), LABEL_TEXT_COUNT));
      } else {
        ticktext = ticktext.concat(_.map(trace, 'ticktext'));
      }
    }
  });

  if (isCurrencyDimensionField) {
    ticktext = _.map(ticktext, (tickItem) => {
      return formatValueToCurrency(tickItem, true);
    });
  }

  return { tickvals, ticktext };
}

export const getTickXLabelsAndMaxMin = (formattedData, fieldLabel, vizOptions) => {
  const { benchMarkEntries } = vizOptions;
  let tickXLabels = [];
  _.each(formattedData, (trace) => {
    tickXLabels = tickXLabels.concat(_.map(trace, fieldLabel));
  });

  if (!_.isEmpty(benchMarkEntries) && !_.isEmpty(_.compact(tickXLabels))) {
    _.each(benchMarkEntries, (benchmark) => {
      const benchMarkValues = getBenchMarkValues(benchmark);
      _.each(benchMarkValues, (value) => {
        const benchValue = getSecondsAsNumericTime(value);
        tickXLabels.push(benchValue);
      });
    });
  }

  const maxXLabel = _.max(tickXLabels);
  const minXLabel = 0;
  return { tickXLabels, minXLabel, maxXLabel };
}

export const getXLabelsMaxMin = (formattedData, fieldLabel, vizOptions) => {
  const { benchMarkEntries } = vizOptions;
  let tickXLabels = [];
  _.each(formattedData, (trace) => {
    const values = _.map(trace, (datum) => { return Number(datum[fieldLabel]) });
    tickXLabels = tickXLabels.concat(values);
  });

  if (!_.isEmpty(benchMarkEntries) && !_.isEmpty(_.compact(tickXLabels))) {
    _.each(benchMarkEntries, (benchmark) => {
      const benchMarkValues = getBenchMarkValues(benchmark);
      _.each(benchMarkValues, (value) => {
        const benchValue = Number(value);
        tickXLabels.push(benchValue);
      });
    });
  }

  let maxXLabel = _.max(tickXLabels);
  let minXLabel = _.min(tickXLabels);
  if (minXLabel < 0) {
  const leastValues = _.filter(tickXLabels, (datum) => { return datum > 0 });
  const leastDiffValue = _.sum(leastValues) /_.size(leastValues) ;
    minXLabel = Number(minXLabel) + (leastDiffValue * -1 );
  }
  if (minXLabel < 0) {
    const addValue = Number(maxXLabel) > 0 ? Number(maxXLabel) / 2 : Number(maxXLabel);
    maxXLabel = Number(maxXLabel) + addValue;
  }
  return { tickXLabels, minXLabel, maxXLabel };
}

export const getBarMode = (vizOptions) => {
  const { groupByEntry, secondaryMetricEntry, isComparisonEnabled } = vizOptions;
  const isGroupByEntry = _.get(groupByEntry, 'name') !== 'None';

  if (isComparisonEnabled) {
    return 'group';
  }

  if (!isGroupByEntry && !_.isEmpty(secondaryMetricEntry)) {
    return _.get(secondaryMetricEntry, 'render_type');
  } else if (isGroupByEntry && isParallelOrBulletChart({ secondaryMetricEntry })) {
    return 'group';
  } else {
    return 'stack';
  }
}

// TODO: need refactor this.
export const getChartHeight = (apiDataEntries, vizOptions, formattedData) => {
  const { groupByEntry, viewMode, groupType, groupByViewType, isEmbed, isComparisonEnabled } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL) && !isEmbed;
  const benchMarkCount = _.size(_.keys(_.get(vizOptions, 'benchMarkEntries')));
  const compareViewType = _.find(COMPARE_VIEW_DRILLDOWN_OPTIONS, { name: groupByViewType });
  const rowCount = _.get(compareViewType, 'rowCount', 0);
  let noOfBars;

  if (_.get(groupByEntry, 'name') === 'None') {
    const dimensions = _.chain(apiDataEntries).map('dimension').uniq().value();
    noOfBars = getBarCount(_.size(dimensions), benchMarkCount, rowCount);
    noOfBars = isComparisonEnabled ? _.size(_.flatten(formattedData)) : noOfBars;
  } else if (!_.isEmpty(groupByEntry)) {
    let barsCount = 0;
    if (isCompareView(groupType)) {
      barsCount = isStackTypeChart(vizOptions) ?
        getNoOfStackBars(formattedData, vizOptions) :
        getCompareViewChartBarsCount(formattedData, 0);
    } else {
      barsCount = _.size(apiDataEntries);
    }

    noOfBars = (benchMarkCount > 0) ? barsCount + 2 : barsCount;
  } else {
    noOfBars = getNoOfBars(apiDataEntries, vizOptions);
  }
  return getBarChartHeight(noOfBars, isSmallView, vizOptions, benchMarkCount);
}

export const getBarChartHeight = (barsCount, isSmallView = false, vizOptions = {}, benchMarkCount = 0) => {
  if (isSmallView) {
    const chartHeight = _.get(BAR_CHART_AREA_HEIGHT, barsCount, BAR_CHART_AREA_HEIGHT[0]);
    return benchMarkCount > 0 ? chartHeight + 40 : chartHeight;
  }

  const { isComparisonEnabled } = vizOptions;
  const chartType = getChartType(vizOptions)
  let heightVal = 42; // 48
  if (chartType === 'parallel') {
    heightVal = 35;
  } else if (isComparisonEnabled) {
    heightVal = 25;
  }

  return (barsCount * heightVal) + 60;
};

const getBarCount = (barCount, benchMarkCount, rowCount) => {
  let noOfBars = (rowCount > 0 && rowCount < barCount) ? rowCount : barCount;
  if (benchMarkCount > 0) {
    noOfBars = noOfBars + 2;
  }
  return noOfBars;
}

const getCompareViewChartBarsCount = (formattedData, rowCount) => {
  let barsCount = _.sum(_.map(formattedData, function (entries) {
    if (rowCount === COMPARE_VIEW_DRILLDOWN_OPTIONS[0].rowCount) {
      return Math.min(_.size(entries), rowCount) + 1;
    } else {
      return _.size(entries) + 1;
    }
  }));

  return barsCount;
}

const getNoOfBars = (apiDataEntries, vizOptions) => {
  let noOfEntries;
  const benchMarkCount = _.size(_.keys(_.get(vizOptions, 'benchMarkEntries')));
  if (isStackTypeChart(vizOptions)) {
    noOfEntries = _.chain(apiDataEntries).groupBy('dimension').keys().size().value();
  } else {
    noOfEntries = _.size(apiDataEntries);
  }
  return (benchMarkCount > 0) ? (noOfEntries + 2) : noOfEntries;
}

const getNoOfStackBars = (formattedData, vizOptions) => {
  const benchMarkCount = _.size(_.keys(_.get(vizOptions, 'benchMarkEntries')));
  const { tickvals } = getTickValsAndTickText(formattedData, vizOptions);
  const noOfEntries = _.size(_.uniq(tickvals)); vizOptions

  return (benchMarkCount > 0) ? (noOfEntries + 2) : noOfEntries;
}
