import {
  UPDATE_CURRENT_DATA_RANGE_OPTIONS,
  UPDATE_PREVIOUS_DATA_RANGE_OPTIONS,
  UPDATE_SELECTED_SHAPE_DATASET_ENTRY,
  UPDATE_SELECTED_SHAPE_IDS,
  UPDATE_SELECTED_TEMPLATE_IDS,
  UPDATE_DEFAULT_MAP_CANVAS_OPTIONS
} from 'actions/mapCanvasActions';
import {
  configuredDefaultDateType,
  appLevelShapeDatasetEntries,
  getMapCanvasTemplateEntries
} from 'common/config/customerConfiguration';
import { relativeDateFilterEntryDefaultState } from 'helpers/commonFiltersHelper';
import { DATE_COMPARISON_TYPE_OPTIONS } from 'appConstants';

export const defaultMapCanvasOptions = () => {
  return{
    currentDateRangeOptions: {
      dateRange: {startDate: '2021-01-01', endDate: '2021-12-31'},
      dateType: "custom_range",
      relativeDateFilterEntry: relativeDateFilterEntryDefaultState
    },
    previousDateRangeOptions: {
      dateRange: { startDate: '2020-01-01', endDate: '2020-12-31' },

      dateType: configuredDefaultDateType || 'custom_range',
      comparisonPeriod: DATE_COMPARISON_TYPE_OPTIONS[1].value
    },
    selectedShapeDatasetEntry: appLevelShapeDatasetEntries[0],
    selectedTemplateIds: _.map(getMapCanvasTemplateEntries(), 'template_id'),
    selectedShapeIds: []
  }
}

export default function(state = defaultMapCanvasOptions(), action) {
  switch (action.type) {
    case UPDATE_CURRENT_DATA_RANGE_OPTIONS:
      return {
        ...state,
        currentDateRangeOptions:_.get(action, 'dateRangeOptions', {})
      }
    case UPDATE_PREVIOUS_DATA_RANGE_OPTIONS:
      return {
        ...state,
        previousDateRangeOptions:_.get(action, 'dateRangeOptions', {})
      }
    case UPDATE_SELECTED_SHAPE_DATASET_ENTRY:
      return {
        ...state,
        selectedShapeDatasetEntry:_.get(action, 'selectedShapeEntry', {})
      }

    case UPDATE_SELECTED_SHAPE_IDS:
      return {
        ...state,
        selectedShapeIds: _.get(action, 'shapeIds', [])
      }
    case UPDATE_SELECTED_TEMPLATE_IDS:
      return {
        ...state,
        selectedTemplateIds: _.get(action, 'templateIds', [])
      }
    case UPDATE_DEFAULT_MAP_CANVAS_OPTIONS:
      return {
        ...state,
        ...defaultMapCanvasOptions()
      }
    default:
      return state;
  }
}
